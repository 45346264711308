import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SectionComponent} from "../../elements/section/section.component";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {SearchAirportsService} from "../../../services/search.airports.service";
import {Airport, AirportInformationDto, AirportServices, AirportServicesTier} from "../../../model/airport";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {TextElement} from "../../../model/textElement";
import {ButtonComponent} from "../../elements/button/button.component";
import {TypeOfSService, TypeOfSServiceToTextElement} from "../../../model/booking";
import {IconComponent} from "../../elements/icon/icon.component";
import {IconsName} from "../../../model/icons";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BookingService} from "../../../services/booking.service";
import {LanguageService} from "../../../services/language.service";
import {MapComponent} from "../../elements/map/map.component";
import {AirportService} from "../../../services/airport.service";
import {Names} from "../../../model/names";

@Component({
  selector: 'app-airport',
  standalone: true,
  imports: [CommonModule, SectionComponent, TextElementComponent, ButtonComponent, IconComponent, RouterLink, MapComponent],
  templateUrl: './airport.component.html',
  animations: [
    trigger('openClose', [
      state('closed', style({
        maxHeight: '244px',
      }),),
      state('open', style({
        maxHeight: '5000px',
      })),
      transition('open => closed', [
        animate('1.2s ease-in-out'),
      ]),
      transition('closed => open', [
        animate('1.2s ease-in-out'),
      ]),
    ]),
    trigger('hideText', [
      state('hidden', style({
        display: 'none',
      })),
      state('appeared', style({
        display: 'block',
      })),
      transition('hidden => appeared', [
        animate('1.5s ease-in-out'),
      ]),
      transition('appeared => hidden', [
        animate('1.5s ease-in-out'),
      ]),
    ]),
  ],
  styleUrl: './airport.component.scss'
})
export class AirportComponent implements OnInit {

  constructor(
      private route: ActivatedRoute,
      protected searchAirportsService: SearchAirportsService,
      protected airportService: AirportService,
      public bookingService: BookingService,
      protected languageService: LanguageService) {};
  displayMap: boolean = false;
  code: string = "";
  airport: Airport | undefined;
  airportServices: AirportServices[] = [];
  mainInfo: any;
  data: { label: string, value: any }[] | null = null;
  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: " ",
      },
      {
        languageId: 1,
        text: " ",
      },
    ],
    [
      {
        languageId: 0,
        text: "All airports",
      },
      {
        languageId: 1,
        text: "Все аэропорты",
      },
    ],
    [
      {
        languageId: 0,
        text: "Meet & Assist, Fast Track - Domestic flights",
      },
      {
        languageId: 1,
        text: "Встреча и помощь, \"Fast Track\" – внутренние рейсы",
      },
    ],
    [
      {
        languageId: 0,
        text: "Meet & Greet",
      },
      {
        languageId: 1,
        text: "Встреча и сопровождение",
      },
    ],
    [
      {
        languageId: 0,
        text: "Our airport service is designed to meet the unique\n" +
            "challenges of modern international travel, offering you\n" +
            "the utmost comfort and luxury for your future trips",
      },
      {
        languageId: 1,
        text: "Наши услуги в аэропорту созданы для удовлетворения уникальных\n" +
            "вызовы современных международных путешествий, предлагая вам\n" +
            "максимальный комфорт и роскошь для ваших будущих поездок",
      },
    ],
    [
      {
        languageId: 0,
        text: "Fast track",
      },
      {
        languageId: 1,
        text: "Услуга \"Fast track\"",
      },
    ],
    [
      {
        languageId: 0,
        text: "Arrival and Departure stress-free documentation and\n" +
            "speedy run through passport control, security check, and\n" +
            "luggage processing",
      },
      {
        languageId: 1,
        text: "Документация о прибытии и отъезде без стресса и\n" +
            "быстрое прохождение паспортного контроля, проверки безопасности и\n" +
            "обработка багажа",
      },
    ],
    [
      {
        languageId: 0,
        text: "Business lounge (departure only) - 55,00 p/p Porter services - 25,00 EUR upto 2 bags p/p Infants 0-2 yo - free-of-charge",
      },
      {
        languageId: 1,
        text: "Бизнес-зал (только вылет) - 55,00 чел. Услуги носильщика - 25,00 евро до 2 сумок чел. Младенцы 0-2 лет - бесплатно",
      },
    ],
    [
      {
        languageId: 0,
        text: "Children 2 - 12 yo - 50% discount Urgent orders < 2 business days + 15%",
      },
      {
        languageId: 1,
        text: "Дети 2 - 12 лет - скидка 50% Срочные заказы < 2 рабочих дней + 15%",
      },
    ],
    [
      {
        languageId: 0,
        text: "Cancellation: up to 72 hours — free; 72 - 48 hours — 50%; less than 48 hours - 100%",
      },
      {
        languageId: 1,
        text: "Отмена: до 72 часов — бесплатно; 72 – 48 часов – 50%; менее 48 часов - 100%",
      },
    ],
    [
      {
        languageId: 0,
        text: "1st passenger",
      },
      {
        languageId: 1,
        text: "1-й пассажир",
      },
    ],
    [
      {
        languageId: 0,
        text: "Each subsequent passenger",
      },
      {
        languageId: 1,
        text: "Каждый последующий пассажир",
      },
    ],
    [
      {
        languageId: 0,
        text: "Book now",
      },
      {
        languageId: 1,
        text: "Заказать",
      },
    ],
    [
      {
        languageId: 0,
        text: "Meet & Assist, Fast Track - International & CIS flight",
      },
      {
        languageId: 1,
        text: "Встреча и помощь, \"Fast Track\" – международные рейсы и рейсы в страны СНГ",
      },
    ],
    [
      {
        languageId: 0,
        text: "Full VIP - Domestic flights",
      },
      {
        languageId: 1,
        text: "Полный VIP - Внутренние рейсы",
      },
    ],
    [
      {
        languageId: 0,
        text: "VIP Terminal & Lounge",
      },
      {
        languageId: 1,
        text: "VIP-терминал и зал ожидания",
      },
    ],
    [
      {
        languageId: 0,
        text: "Clients who choose our private lounge access experience\n" +
            "the ultimate luxury and indulgence. We strive to provide a\n" +
            "premium VIP treatment, ensuring that our guests feel like\n" +
            "royalty",
      },
      {
        languageId: 1,
        text: "Клиенты, которые выбирают наш доступ в частный зал ожидания\n" +
            "высшая роскошь и наслаждение. Мы стремимся предоставить\n" +
            "VIP-обслуживание премиум-класса, гарантирующее, что наши гости чувствуют себя комфортно\n" +
            "роялти",
      },
    ],
    [
      {
        languageId: 0,
        text: "Show more",
      },
      {
        languageId: 1,
        text: "Показать",
      },
    ],
    [
      {
        languageId: 0,
        text: "Close",
      },
      {
        languageId: 1,
        text: "Скрыть",
      },
    ],
    [
      {
        languageId: 0,
        text: "Location",
      },
      {
        languageId: 1,
        text: "Расположение",
      },
    ],
    [
      {
        languageId: 0,
        text: "Code IATA",
      },
      {
        languageId: 1,
        text: "Код IATA",
      },
    ],
    [
      {
        languageId: 0,
        text: "Code ICAO",
      },
      {
        languageId: 1,
        text: "Код ICAO",
      },
    ],
    [
      {
        languageId: 0,
        text: "Latitude and longitude",
      },
      {
        languageId: 1,
        text: "Широта и долгота",
      },
    ],
    [
      {
        languageId: 0,
        text: "Timezone",
      },
      {
        languageId: 1,
        text: "Часовой пояс",
      },
    ],
    [
      {
        languageId: 0,
        text: "Web-site",
      },
      {
        languageId: 1,
        text: "Веб-сайт",
      },
    ],
    [
      {
        languageId: 0,
        text: "Phone",
      },
      {
        languageId: 1,
        text: "Телефон",
      },
    ],
    [
      {
        languageId: 0,
        text: "Facebook",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Instagram",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Linkedin",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Twitter",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
  ];
  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get('code') ?? "";
    this.searchAirportsService.getAirport(this.code).subscribe(airport => {
      this.airport = airport;
      this.airportService.getAirportServices(this.airport.id).subscribe(info => {
        this.mainInfo = Object.keys(info.informations ?? []).length > 0 ? info.informations : { en: info.information ?? "" };
        info.services.forEach(s => {
          this.airportServices.push(new AirportServices({
              title: Object.keys(s.service?.names ?? []).length > 0 ? s.service?.names : { en: s.service?.name ?? "" },
              tiers: [
                  {
                    type: TypeOfSService.Arrival,
                    costForOne: s.arrivalPriceFirst,
                    costForSubsequent: s.arrivalPriceSubsequent,
                    description: [
                      {
                        title: this.textElements[3],
                        text: this.textElements[4]
                      },
                      {
                        title: this.textElements[5],
                        text: this.textElements[6]
                      },
                    ],
                  },
                  {
                    type: TypeOfSService.Departure,
                    costForOne: s.departurePriceFirst,
                    costForSubsequent: s.departurePriceSubsequent,
                    description: [
                      {
                        title: this.textElements[3],
                        text: this.textElements[4]
                      },
                      {
                        title: this.textElements[5],
                        text: this.textElements[6]
                      },
                    ],
                  },
                  {
                    type: TypeOfSService.Transit,
                    costForOne: s.transitPriceFirst,
                    costForSubsequent: s.transitPriceSubsequent,
                    description: [
                      {
                        title: this.textElements[3],
                        text: this.textElements[4]
                      },
                      {
                        title: this.textElements[5],
                        text: this.textElements[6]
                      },
                    ],
                  },
              ],
          }))
        });
      });
      if (!!this.airport) {
        this.textElements[0].forEach(v => {
          v.text = this.airport?.name ? this.airport.name : "";
        });
      }
      setTimeout(() => {
        this.displayMap = true;
      }, 200);
    });

  };

  getIcon(item: TypeOfSService) {
    switch (item) {
      case TypeOfSService.Transit:
        return IconsName.Transit;
      case TypeOfSService.Arrival:
        return IconsName.Arrival;
      case TypeOfSService.Departure:
        return IconsName.Departure;
      default:
        return IconsName.Default;
    }
  }

  getIconClass(item: TypeOfSService){
    switch (item) {
      case TypeOfSService.Arrival:
        return "icon-arrival";
      case TypeOfSService.Departure:
        return "icon-departure";
      case TypeOfSService.Transit:
        return "";
      default:
        return "";
    }
  }

  // mockAirportServices: AirportServices[] = [
  //   {
  //     title: this.textElements[2],
  //     tiers: [
  //       {
  //         type: TypeOfSService.Arrival,
  //         costForOne: 500,
  //         costForSubsequent: 500,
  //         description: [
  //           {
  //             title: this.textElements[3],
  //             text: this.textElements[4]
  //           },
  //           {
  //             title: this.textElements[5],
  //             text: this.textElements[6]
  //           },
  //         ],
  //       },
  //       {
  //         type: TypeOfSService.Departure,
  //         costForOne: 500,
  //         costForSubsequent: 500,
  //         description: [
  //           {
  //             title: this.textElements[3],
  //             text: this.textElements[4]
  //           },
  //           {
  //             title: this.textElements[5],
  //             text: this.textElements[6]
  //           },
  //         ],
  //       },
  //       {
  //         type: TypeOfSService.Transit,
  //         costForOne: 950,
  //         costForSubsequent: 950,
  //         description: [
  //           {
  //             title: this.textElements[3],
  //             text: this.textElements[4]
  //           },
  //           {
  //             title: this.textElements[5],
  //             text: this.textElements[6]
  //           },
  //         ],
  //       },
  //     ],
  //     termsAndConditions: [this.textElements[7], this.textElements[8], this.textElements[9]],
  //     open: false,
  //   },
  //   {
  //     title: this.textElements[13],
  //     tiers: [
  //       {
  //         type: TypeOfSService.Arrival,
  //         costForOne: 670,
  //         costForSubsequent: 660,
  //         description: [
  //           {
  //             title: this.textElements[3],
  //             text: this.textElements[4]
  //           },
  //           {
  //             title: this.textElements[5],
  //             text: this.textElements[6]
  //           },
  //         ],
  //       },
  //       {
  //         type: TypeOfSService.Departure,
  //         costForOne: 670,
  //         costForSubsequent: 660,
  //         description: [
  //           {
  //             title: this.textElements[3],
  //             text: this.textElements[4]
  //           },
  //           {
  //             title: this.textElements[5],
  //             text: this.textElements[6]
  //           },
  //         ],
  //       },
  //       {
  //         type: TypeOfSService.Transit,
  //         costForOne: 740,
  //         costForSubsequent: 750,
  //         description: [
  //           {
  //             title: this.textElements[3],
  //             text: this.textElements[4]
  //           },
  //           {
  //             title: this.textElements[5],
  //             text: this.textElements[6]
  //           },
  //         ],
  //       },
  //     ],
  //     termsAndConditions: [this.textElements[7], this.textElements[8], this.textElements[9]],
  //     open: false,
  //   },
  //   {
  //     title: this.textElements[14],
  //     tiers: [
  //       {
  //         type: TypeOfSService.Arrival,
  //         costForOne: 750,
  //         costForSubsequent: 740,
  //         description: [
  //           {
  //             title: this.textElements[15],
  //             text: this.textElements[16]
  //           },
  //         ],
  //       },
  //       {
  //         type: TypeOfSService.Departure,
  //         costForOne: 750,
  //         costForSubsequent: 740,
  //         description: [
  //           {
  //             title: this.textElements[15],
  //             text: this.textElements[16]
  //           },
  //         ],
  //       },
  //       {
  //         type: TypeOfSService.Transit,
  //         costForOne: 1400,
  //         costForSubsequent: 1370,
  //         description: [
  //           {
  //             title: this.textElements[15],
  //             text: this.textElements[16]
  //           },
  //         ],
  //       },
  //     ],
  //     termsAndConditions: [this.textElements[7], this.textElements[8], this.textElements[9]],
  //     open: false,
  //   },
  // ];

  mockTermsAndConditions: TextElement[][] = [
    this.textElements[7], this.textElements[8], this.textElements[9]
  ];

protected readonly TypeOfSServiceToTextElement = TypeOfSServiceToTextElement;
  protected readonly IconsName = IconsName;
  protected readonly TypeOfSService = TypeOfSService;
}
