import {AfterViewInit, Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {SectionComponent} from "../../elements/section/section.component";
import {WelcomeComponent} from "../../blocks/welcome/welcome.component";
import {OurServicesComponent} from "../../blocks/our-services/our-services.component";
import {AirportMeetAndGreetComponent} from "../../blocks/airport-meet-and-greet/airport-meet-and-greet.component";
import {OurBenefitComponent} from "../../blocks/our-benefit/our-benefit.component";
import {StatisticsComponent} from "../../blocks/statistics/statistics.component";
import {OurLuxuryCharterComponent} from "../../blocks/our-luxury-charter/our-luxury-charter.component";
import {TestimonialsComponent} from "../../blocks/testimonials/testimonials.component";
import {OurPackagesComponent} from "../../blocks/our-packages/our-packages.component";
import {RequirementsComponent} from "../../blocks/requirements/requirements.component";
import {OurBlogComponent} from "../../blocks/our-blog/our-blog.component";
import {ActivatedRoute} from "@angular/router";
import {SearchAirportsService} from "../../../services/search.airports.service";
import {BookingService} from "../../../services/booking.service";
import {RunningNumberService} from "../../../services/running.number.service";
import {statistics} from "../../../model/statistics";

@Component({
  selector: 'app-main',
  standalone: true,
    imports: [
        CommonModule,
        TextElementComponent,
        SectionComponent,
        WelcomeComponent,
        OurServicesComponent,
        AirportMeetAndGreetComponent,
        OurBenefitComponent,
        StatisticsComponent,
        OurLuxuryCharterComponent,
        TestimonialsComponent,
        OurPackagesComponent,
        RequirementsComponent,
        OurBlogComponent,
    ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
    constructor(
        private runningNumberService: RunningNumberService) {};

    @ViewChild('triggerStatistics') triggerStatistics: ElementRef;
    heightScreen = window.innerHeight;
    heightBlock = 200;
    interval = 50;
    time = 3000;
    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (!this.runningNumberService.getIsTriggered()) {
            const scroll =  window.scrollY;
            const elPos = this.triggerStatistics.nativeElement.offsetTop;
            if(scroll > elPos - this.heightScreen + this.heightBlock) {
                this.runningNumberService.setIsTriggered(true);
                this.runningNumberService.setNumbers(statistics.map(value => { return 0 }));
                let timerId = setInterval(() => {
                    this.runningNumberService.getNextStepValues(this.time / this.interval);
                }, this.interval);
                setTimeout(() => { clearInterval(timerId)}, this.time);
            }
        }

    }
}
