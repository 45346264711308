<p-dialog header="Header"
          [(visible)]="visible"
          (onHide)="onHide()"
          [header]="title"
          [modal]="true"
          [draggable]="false"
          [resizable]="false"
          [closable]="true"
          [dismissableMask]="true"
          [class]="withOutContent ? 'content-off' : ''"
          [style]="{ width: width }"
>
    <ng-content/>
</p-dialog>
