import {Injectable} from '@angular/core';
import {Login} from "../model/login";

const LOGIN_INFO_KEY = 'login-info';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    logout(): void {
        window.sessionStorage.clear();
    }

    public saveLoginInfo(loginInfo: Login): void {
        window.sessionStorage.removeItem(LOGIN_INFO_KEY);
        window.sessionStorage.setItem(LOGIN_INFO_KEY, JSON.stringify(loginInfo));
    }

    public getToken(): string | undefined {
        return this.getLoginInfo()?.token;
    }

    public getLoginInfo(): Login | undefined {
        const loginInfo = window.sessionStorage.getItem(LOGIN_INFO_KEY);
        if (loginInfo) {
            return JSON.parse(loginInfo);
        }
        return undefined;
    }

}
