import { Component } from '@angular/core';
import {SectionComponent} from "../../elements/section/section.component";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    SectionComponent,
    TextElementComponent
  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {
  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Legal information",
      },
      {
        languageId: 1,
        text: "Юридическая информация",
      },
    ],
    [
      {
        languageId: 0,
        text: "JSC «Первоклассные решения» is the owner of the website and service",
      },
      {
        languageId: 1,
        text: "АО «Первоклассные решения» является владельцем сайта и сервиса ",
      },
    ],
    [
      {
        languageId: 0,
        text: "meetplusgreet.com",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: ". We provide VIP service at airports around the world from direct suppliers and major partners.",
      },
      {
        languageId: 1,
        text: ". Мы предоставляем VIP сервис в аэропортах мира от прямых поставщиков и крупных партнеров.",
      },
    ],
    [
      {
        languageId: 0,
        text: "TIN 9707025574",
      },
      {
        languageId: 1,
        text: "ИНН 9707025574",
      },
    ],
    [
      {
        languageId: 0,
        text: "RRC 9707025574",
      },
      {
        languageId: 1,
        text: "КПП 770701001",
      },
    ],
    [
      {
        languageId: 0,
        text: "Address: 127055, Moscow, 1st Tikhvinsky blud, no. 5-7, premises. 1p",
      },
      {
        languageId: 1,
        text: "Адрес: 127055, город Москва, 1-й Тихвинский туп, д. 5-7, помещ. 1п",
      },
    ],
    [
      {
        languageId: 0,
        text: "Политика в отношении конфиденциальности и обработки персональных данных посетителей сайта",
      },
      {
        languageId: 1,
        text: "Политика в отношении конфиденциальности и обработки персональных данных посетителей сайта",
      },
    ],
  ];
}
