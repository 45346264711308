<div class="switcher-lang-container">
  <div *ngFor="let language of languages"  class="switcher-lang-el">
    <div
      [class]="isActiveLanguage(language) ? 'active' : ''"
      (click)="select(language)"
    >
      <app-text-element [inputText]="language.name" [upperCase]="true"/>
    </div>
  </div>
</div>
