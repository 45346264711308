import { Component } from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {ModalMobileService} from "../../../services/modal-mobile.service";
import {NgForOf, NgIf} from "@angular/common";
import {IconComponent} from "../icon/icon.component";
import {IconsName} from "../../../model/icons";
import {TextElementComponent} from "../text-element/text-element.component";
import { Router, RouterLink} from "@angular/router";
import {menu, Menu} from "../../../model/menu";
import {SwitcherComponent} from "../switcher/switcher.component";

@Component({
  selector: 'app-modal-menu',
  standalone: true,
    imports: [
        ModalComponent,
        NgIf,
        IconComponent,
        NgForOf,
        TextElementComponent,
        RouterLink,
        SwitcherComponent
    ],
  templateUrl: './modal-menu.component.html',
  styleUrl: './modal-menu.component.scss'
})
export class ModalMenuComponent {

  constructor(public modalMobileService: ModalMobileService,
              private router: Router) {}

  menu: Menu[] = menu;
  routeToPage(item: Menu){
    this.modalMobileService.setModalMobile(false);
    this.router.navigate([item.link]).then(() => {});
  }

  protected readonly IconsName = IconsName;

}
