import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContactWelcomeComponent} from "../../blocks/contact-welcome/contact-welcome.component";
import {SectionComponent} from "../../elements/section/section.component";
import {WelcomeComponent} from "../../blocks/welcome/welcome.component";
import {ContactInfoComponent} from "../../blocks/contact-info/contact-info.component";
import {GetInTouchComponent} from "../../blocks/get-in-touch/get-in-touch.component";
import {ContactInfoBlockComponent} from "../../blocks/contact-info-block/contact-info-block.component";
import {FaqComponent} from "../../blocks/faq/faq.component";

@Component({
  selector: 'app-contact',
  standalone: true,
    imports: [CommonModule, ContactWelcomeComponent, SectionComponent, WelcomeComponent, ContactInfoComponent, GetInTouchComponent, ContactInfoBlockComponent, FaqComponent],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {

}
