<div class="container-airport-search">
    <div class="search">
        <div class="search-input-container">
            <input
                    class="search-input"
                    [placeholder]="languageService.getStringFromTextElement(textElements[0] || [])"
                    [(ngModel)]="search"
                    (keyup)="searchFoo($event)"
            >
            <app-icon
              class="cross"
              *ngIf="search.length > 2"
              (click)="clearList()"
              [icon]="IconsName.Cross"
              [width]="30"
              [height]="30"
              [color]="'#272727'"
            />
        </div>
        <div *ngIf="loading" class="container-loading">
            <p-progressSpinner strokeWidth="3"/>
        </div>
        <div class="airport-list" *ngIf="airports.length > 0 && !loading">
            <div class="airport" *ngFor="let airport of airports" [routerLink]="'/airport/' + airport.iata_code">
                <div class="container-country">
                    <div class="country">
                        <div class="icon-flag-container">
                            <app-country-flag class="icon-flag" [countryCode]="airport.country_code" [width]="36"/>
                        </div>
                        <p>{{ languageService.getNameByTheLang(airport.countryObject?.names) }}</p>
                    </div>
                </div>
                <div class="container-title">
                    <div class="names">
                        <div class="city">
                            <h2>{{ languageService.getNameByTheLang(airport.cityObject?.names)}}</h2>
                        </div>
                        <div class="name">
                            <p>{{ languageService.getNameByTheLang(airport?.names) }}</p>
                        </div>
                    </div>
                    <div class="code">
                        <h5>{{ airport.iata_code }}</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-text" *ngIf="search.length < 3 && !notFoundFlag">
            <h1 class="largeText colorYellow"><app-text-element [inputText]="textElements[1]"/></h1>
            <h2 class="colorWhite"><app-text-element [inputText]="textElements[2]"/></h2>
        </div>
        <div *ngIf="notFoundFlag && !loading" class="container-text">
            <h2 class="colorWhite"><app-text-element [inputText]="textElements[3]"/></h2>
        </div>

    </div>
</div>
