import {Component, OnInit} from '@angular/core';
import {BookingService} from "../../../services/booking.service";
import {DialogModule} from "primeng/dialog";
import {ModalComponent} from "../modal/modal.component";
import {Booking, TypeOfSService} from "../../../model/booking";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {TextElement} from "../../../model/textElement";
import {LanguageService} from "../../../services/language.service";
import {TextElementComponent} from "../text-element/text-element.component";
import {ButtonComponent} from "../button/button.component";
import {CalendarModule} from "primeng/calendar";
import {AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import {Airport, ServicesOfAirport} from "../../../model/airport";
import {SearchAirportsService} from "../../../services/search.airports.service";
import {DropdownModule} from "primeng/dropdown";
import {NgIf} from "@angular/common";
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputMaskModule} from "primeng/inputmask";
import {AirportService} from "../../../services/airport.service";
import {MyMessageService} from "../../../services/my-message.service";

@Component({
  selector: 'app-booking-form',
  standalone: true,
  imports: [
    DialogModule,
    ModalComponent,
    FormsModule,
    InputTextModule,
    TextElementComponent,
    ButtonComponent,
    CalendarModule,
    AutoCompleteModule,
    DropdownModule,
    NgIf,
    InputTextareaModule,
    InputMaskModule
  ],
  templateUrl: './booking-form.component.html',
  styleUrl: './booking-form.component.scss'
})
export class BookingFormComponent {
  constructor(public bookingService: BookingService,
              public languageService: LanguageService,
              public searchAirportsService: SearchAirportsService,
              public airportsService: AirportService,
              public myMessageService: MyMessageService){}

  errorControlFlag: boolean = false;
  successControlFlag: boolean = false;
  suggestionsAirports: Airport[] = [];
  requiredField: string[] = ["firstName", "email", "phone"];
  airportServicesOptions: {id: number, name: string}[] = [];

  booking: Booking = new Booking({
    name: "",
    surname: "",
    phone: "",
    email: "",
    date: "",
    serviceId: null,
    serviceType: null,
    personsCount: null,
    flightNumber: "",
    airportId: null,
    note: ""
  });

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "BOOKING",
      },
      {
        languageId: 1,
        text: "БРОНИРОВАНИЕ",
      },
    ],
    [
      {
        languageId: 0,
        text: "First name",
      },
      {
        languageId: 1,
        text: "Имя",
      },
    ],
    [
      {
        languageId: 0,
        text: "Last name",
      },
      {
        languageId: 1,
        text: "Фамилия",
      },
    ],
    [
      {
        languageId: 0,
        text: "E-mail",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Phone",
      },
      {
        languageId: 1,
        text: "Телефон",
      },
    ],
    [
      {
        languageId: 0,
        text: "Date of travel",
      },
      {
        languageId: 1,
        text: "Дата поездки",
      },
    ],
    [
      {
        languageId: 0,
        text: "Type of service",
      },
      {
        languageId: 1,
        text: "Выберите услугу",
      },
    ],
    [
      {
        languageId: 0,
        text: "Number of passengers",
      },
      {
        languageId: 1,
        text: "Количество пассажиров",
      },
    ],
    [
      {
        languageId: 0,
        text: "Arrival flight",
      },
      {
        languageId: 1,
        text: "Номер рейса",
      },
    ],
    [
      {
        languageId: 0,
        text: "Enter city or airport",
      },
      {
        languageId: 1,
        text: "Введите город или аэропорт",
      },
    ],
    [
      {
        languageId: 0,
        text: "Notes and remarks",
      },
      {
        languageId: 1,
        text: "Примечание",
      },
    ],
    [
      {
        languageId: 0,
        text: "Send",
      },
      {
        languageId: 1,
        text: "Отправить",
      },
    ],
    [
      {
        languageId: 0,
        text: "Arrival",
      },
      {
        languageId: 1,
        text: "Прибытие",
      },
    ],
    [
      {
        languageId: 0,
        text: "Departure",
      },
      {
        languageId: 1,
        text: "Отправление",
      },
    ],
    [
      {
        languageId: 0,
        text: "Transit",
      },
      {
        languageId: 1,
        text: "Транзит",
      },
    ],
    [
      {
        languageId: 0,
        text: "The field is required",
      },
      {
        languageId: 1,
        text: "Обязательное поле",
      },
    ],
    [
      {
        languageId: 0,
        text: "One or more fields have an error. Please check and try again",
      },
      {
        languageId: 1,
        text: "В одном или нескольких полях есть ошибка. Пожалуйста, проверьте и попробуйте снова!",
      },
    ],
    [
      {
        languageId: 0,
        text: "Thank you for your message. It has been sent. We will get in touch with you shortly",
      },
      {
        languageId: 1,
        text: "Спасибо за ваше сообщение. Оно было отправлено. Мы свяжемся с вами в ближайшее время",
      },
    ],
    [
      {
        languageId: 0,
        text: "Service",
      },
      {
        languageId: 1,
        text: "Услуга",
      },
    ],
    [
      {
        languageId: 0,
        text: "Error, something went wrong",
      },
      {
        languageId: 1,
        text: "Ошибка, что-то пошло не так",
      },
    ],
  ];

  serviceType: {name: TextElement[], type: TypeOfSService}[] = [
    {
      type: TypeOfSService.Arrival,
      name: this.textElements[12],
    },
    {
      type: TypeOfSService.Departure,
      name: this.textElements[13],
    },
    {
      type: TypeOfSService.Transit,
      name: this.textElements[14],
    },
  ];

  selectedServiceType: {name: TextElement[], type: TypeOfSService} | null = null;

  selectOptionNumber: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  selectedService: {id: number, name: string} | null = null;
  selectedAirport: Airport | null = null;

  async searchSuggestions(event: AutoCompleteCompleteEvent) {
    this.searchAirportsService.getAirportList(event.query).subscribe({
      next: (value: Airport[]) => {
        this.suggestionsAirports = value;
        this.suggestionsAirports.forEach(v => {
          v.name = this.languageService.getNameByTheLang(v.names);
        });
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  findOption(type: TypeOfSService | null) {
    return this.serviceType.find(value => value.type == type)?.name ?? [];
  }

  watchSelectedServiceType() {
    this.booking.serviceType = this.selectedServiceType?.type || null;
  }
  watchSelectedService() {
    this.booking.serviceId = this.selectedService?.id || null;
  }


  getServicesList(id: number | null) {
    if (id != null) {
      this.airportsService.getAirportServices(id).subscribe(airportServicesDto => {
        const servicesOfAirport:(ServicesOfAirport | null)[] = [] = airportServicesDto.services.map(v => {
            return v.service;
        });
        this.airportServicesOptions = [];
        servicesOfAirport.forEach(value => {
          if (value?.id) {
            this.airportServicesOptions.push({
              id: value.id,
              name: this.languageService.getNameByTheLang(value.names) ?? value.name
            });
          }
        });
      });
    }
  }



  watchSelectedAirport() {
    this.booking.airportId = this.selectedAirport?.id ?? null;
    this.selectedService = null;
    this.selectedServiceType = null;
    this.getServicesList(this.booking.airportId);
  }

  clearSelectedAirport(){
    this.selectedAirport = null;
    this.watchSelectedAirport()
  }


  errorControl(value: string) {
    const field = this.booking[value as keyof Booking];
    return !field && this.errorControlFlag;
  }

  errorsControl() {
    let e: boolean = true;
    Object.keys(this.booking).forEach(key => {
      const filled = !!this.booking[key as keyof Booking];
      const required = !!this.requiredField.find(v => v == key);
      if (!filled && required){
        e = false;
      }
    });
    return e;
  }

  reset() {
    this.booking = new Booking({
      name: "",
      surname: "",
      phone: "",
      email: "",
      date: "",
      serviceId: null,
      serviceType: null,
      personsCount: null,
      flightNumber: "",
      airportId: null,
      note: ""
    });
    this.selectedAirport = null;
    this.selectedServiceType = null;
    this.errorControlFlag = false;
    this.successControlFlag  = false;
    this.suggestionsAirports= [];
    this.airportServicesOptions = [];
    this.selectedService = null;
  };

  submit() {
    if (this.errorsControl()) {
      this.successControlFlag = true;
      this.bookingService.sendBookingForm(this.booking).subscribe({
        next: () => {
          this.bookingService.setBookingForm(false);
          this.reset();
          this.myMessageService.addMessage(this.textElements[17], "success");
        },
        error: (error) => {
          this.myMessageService.addMessage(this.textElements[19], "error");
          this.reset();
          this.bookingService.setBookingForm(false);
          console.log(error);
        }
      });
    } else {
      this.errorControlFlag = true;
    }
  }
}

