import { Component } from '@angular/core';
import {ModalMobileService} from "../../../services/modal-mobile.service";
import {MyMessageService} from "../../../services/my-message.service";
import {IconComponent} from "../icon/icon.component";
import {IconsName} from "../../../model/icons";
import {NgIf} from "@angular/common";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../text-element/text-element.component";

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    IconComponent,
    NgIf,
    TextElementComponent
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  constructor(public myMessageService: MyMessageService){}


  close(){
    this.myMessageService.setState(false);
  }


  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "AIRPORT MEET & GREET",
      },
      {
        languageId: 1,
        text: "AIRPORT MEET & GREET",
      },
    ],
  ];

  protected readonly IconsName = IconsName;
}
