import {Component, OnInit} from '@angular/core';
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {TextElement} from "../../../model/textElement";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {Login} from "../../../model/login";
import {BookingService} from "../../../services/booking.service";
import {LanguageService} from "../../../services/language.service";
import {SearchAirportsService} from "../../../services/search.airports.service";
import {AirportService} from "../../../services/airport.service";
import {ButtonComponent} from "../../elements/button/button.component";
import {Booking} from "../../../model/booking";
import {LoginService} from "../../../services/login.service";
import {Router} from "@angular/router";
import {TokenStorageService} from "../../../services/token.storage.service";
import {PasswordModule} from "primeng/password";
import {AutoFocusModule} from "primeng/autofocus";
import {MyMessageService} from "../../../services/my-message.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    TextElementComponent,
    FormsModule,
    NgIf,
    InputTextModule,
    ButtonComponent,
    PasswordModule,
    AutoFocusModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit{

  constructor(public languageService: LanguageService,
              private loginService: LoginService,
              private router: Router,
              private tokenStorageService: TokenStorageService,
              public myMessageService: MyMessageService){}

  ngOnInit() {

  }

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Authorization",
      },
      {
        languageId: 1,
        text: "Авторизация",
      },
    ],
    [
      {
        languageId: 0,
        text: "login",
      },
      {
        languageId: 1,
        text: "Логин",
      },
    ],
    [
      {
        languageId: 0,
        text: "Password",
      },
      {
        languageId: 1,
        text: "Пароль",
      },
    ],
    [
      {
        languageId: 0,
        text: "log in",
      },
      {
        languageId: 1,
        text: "войти",
      },
    ],
    [
      {
        languageId: 0,
        text: "The field is required",
      },
      {
        languageId: 1,
        text: "Обязательное поле",
      },
    ],
    [
      {
        languageId: 0,
        text: "One or more fields have an error. Please check and try again",
      },
      {
        languageId: 1,
        text: "В одном или нескольких полях есть ошибка. Пожалуйста, проверьте и попробуйте снова!",
      },
    ],
    [
      {
        languageId: 0,
        text: "Login or password have been typed incorrectly",
      },
      {
        languageId: 1,
        text: "Неправильно введён логин или пароль",
      },
    ],
  ];

  requiredField: string[] = ["login", "password"];
  form: Login = new Login({login: "", password: "", token: ""});
  errorControlFlag: boolean = false;

  errorControl(value: string) {
    const field = this.form[value as keyof Login];
    return !field && this.errorControlFlag;
  }

  errorsControl() {
    let e: boolean = true;
    Object.keys(this.form).forEach(key => {
      const filled = !!this.form[key as keyof Login];
      const required = !!this.requiredField.find(v => v == key);
      if (!filled && required){
        e = false;
      }
    });
    return e;
  }


  submit(){
    if (this.errorsControl()) {
      this.loginService.login(this.form.login, this.form.password).subscribe({
        next: (loginInfo) => {
          this.tokenStorageService.saveLoginInfo(loginInfo);
          this.loginService.update();
          this.router.navigate(["admin"]).then(() => {});
        },
        error: (error) => {
          let textError: TextElement[] = [];
          if (error.status == 403) {
            textError = [
              {
                languageId: 0,
                text: "Incorrect login or password",
              },
              {
                languageId: 1,
                text: "Некорректный логин или пароль",
              },
            ];
          } else {
            textError = [
              {
                languageId: 0,
                text: error.message,
              },
            ];
          }
          this.myMessageService.addMessage(textError, "error", 10000);
        }
      });
    } else {
      this.errorControlFlag = true;
    }
  }

}
