import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-background-block',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './background-block.component.html',
  styleUrl: './background-block.component.scss'
})
export class BackgroundBlockComponent {
  @Input() url: string  = "";
  @Input() opacity: number = 0.01;
  @Input() gradient: string  = "";
  @Input() borderRadius: number  = 10;

  getImgBack() {
    if (this.url) {
      let style: string = `background-image: url('${this.url}'); --e-bg-lazyload: url(\'${this.url}\');`;
      return style;
    } else {
      return '';
    }
  };

  getOpacity() {
    if (this.gradient) {
      return this.gradient;
    } else if (this.opacity) {
      return `opacity: ` + this.opacity
    } else {
      return "";
    }
  };
}
