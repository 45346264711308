import { Routes } from '@angular/router';
import { MainComponent } from "./components/pages/main/main.component";
import {ContactComponent} from "./components/pages/contact/contact.component";
import {AirportsComponent} from "./components/pages/airports/airports.component";
import {AirportComponent} from "./components/pages/airport/airport.component";
import {LoginComponent} from "./components/pages/login/login.component";
import {AuthGuardService} from "./model/auth-guard.service";
import {AdminComponent} from "./components/pages/admin/admin.component";
import {AboutUsComponent} from "./components/pages/about-us/about-us.component";
import {ErrorPageComponent} from "./components/pages/error-page/error-page.component";

export const routes: Routes = [
  { path: '', component: MainComponent, pathMatch: 'full' },
  { path: 'contact', component: ContactComponent},
  { path: 'airports', component: AirportsComponent},
  { path: 'airport/:code', component: AirportComponent},
  { path: 'about', component: AboutUsComponent},
  { path: 'login',  component: LoginComponent},
  { path: 'admin', canActivate: [AuthGuardService], component: AdminComponent},
  {path: '**',  canActivate: [AuthGuardService], component: ErrorPageComponent}
];
