import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BackgroundBlockComponent} from "../../elements/background-block/background-block.component";
import {SocialMediaComponent} from "../../elements/social-media/social-media.component";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";

@Component({
  selector: 'app-contact-welcome',
  standalone: true,
  imports: [CommonModule, BackgroundBlockComponent, SocialMediaComponent, TextElementComponent],
  templateUrl: './contact-welcome.component.html',
  styleUrl: './contact-welcome.component.scss'
})
export class ContactWelcomeComponent {
  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Contact Us",
      },
      {
        languageId: 1,
        text: "Контакты",
      },
    ],
    [
      {
        languageId: 0,
        text: "Home / Contact Us",
      },
      {
        languageId: 1,
        text: "Главная / Контакты",
      },
    ],
  ];
}
