import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {Icon, Icons} from "../../../model/icons";

@Component({
  selector: 'app-svg',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg.component.svg',
  styleUrl: './svg.component.scss'
})
export class SvgComponent {
  @Input() icon: Icon = Icons[0];
  @Input() color: string = "";
  @Input() strokeWidth: number = 0;
}
