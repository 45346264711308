import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContactWelcomeComponent} from "../../blocks/contact-welcome/contact-welcome.component";
import {SectionComponent} from "../../elements/section/section.component";
import {AirportsSearchComponent} from "../../blocks/airports-search/airports-search.component";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {TextElement} from "../../../model/textElement";
import {BackgroundBlockComponent} from "../../elements/background-block/background-block.component";

@Component({
  selector: 'app-airports',
  standalone: true,
  imports: [CommonModule, ContactWelcomeComponent, SectionComponent, AirportsSearchComponent, TextElementComponent, BackgroundBlockComponent],
  templateUrl: './airports.component.html',
  styleUrl: './airports.component.scss'
})
export class AirportsComponent {
  textElements: TextElement[][] = [

  ];
}
