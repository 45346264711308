import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {BookingService} from "./booking.service";

@Injectable({
  providedIn: 'root'
})
export class ModalMobileService {
  constructor() {
  }

  public modalMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getModalMobile() {
    return this.modalMobile.value;
  }

  setModalMobile(value: boolean) {
    this.modalMobile.next(value);
    const body = document.getElementById("body");
    if (body) {
      if (value) {
        body.className = "p-overflow-hidden";
      } else {
        body.className = "";
      }
    }
  }


}
