<app-section [sectionClass]="'section-first-screen'">
  <app-welcome/>
</app-section>
<app-section [sectionClass]="'section-white section-auto-mobile'">
  <app-our-services/>
</app-section>
<app-section [sectionClass]="'section-auto-mobile'">
  <app-airport-meet-and-greet/>
</app-section>
<app-section [sectionClass]="'section-white section-auto-mobile'">
  <app-our-benifit/>
</app-section>
<div #triggerStatistics class="triggerStatistics"></div>
<app-statistics/>
<app-section [sectionClass]="'section-white section-auto-mobile'">
  <app-our-luxury-charter/>
</app-section>
<app-section [sectionContainer]="false">
  <app-testimonials/>
</app-section>
<app-section [sectionClass]="'section-white section-auto-mobile'" >
  <app-our-packages/>
</app-section>
<app-section [sectionContainer]="false" [sectionClass]="'section-60'">
  <app-requirements/>
</app-section>
<app-section [sectionClass]="'section-white'" [sectionHeight]="'40vh'">
  <app-our-blog/>
</app-section>
