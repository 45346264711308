import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Map, MapStyle, Marker, config } from '@maptiler/sdk';
@Component({
  selector: 'app-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() lng: number  = 0;
  @Input() lat: number  = 0;
  @Input() zoom: number  = 12;
  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;
  map: Map | undefined;

  ngOnInit(): void {
    config.apiKey = 'vyDmwnHbKT1fzVKtrIt9';
  }

  ngAfterViewInit() {
    const initialState = { lng: this.lng, lat: this.lat, zoom: this.zoom};
    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: MapStyle.STREETS,
      center: [initialState.lng, initialState.lat],
      zoom: initialState.zoom,
      attributionControl: false,
    });
    // new Marker({color: "#D1AF77", scale: initialState.zoom})
    //     .setLngLat([this.lng, this.lat])
    //     .addTo(this.map);
  }

  ngOnDestroy() {
    this.map?.remove();
  }
}
