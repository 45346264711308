import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Booking} from "../model/booking";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(private httpClient: HttpClient) {
  }

  public bookingForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);



  getBookingForm() {
    return this.bookingForm.value;
  }

  setBookingForm(value: boolean) {
    this.bookingForm.next(value);
  }

  sendBookingForm(form: Booking): Observable<Booking>  {
    return this.httpClient.post<any>(`${environment.apiUrl}/public/booking`, form);
  }

}
