import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DialogModule} from "primeng/dialog";
import {BookingService} from "../../../services/booking.service";

@Component({
  selector: 'app-modal',
  standalone: true,
    imports: [
        DialogModule
    ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  @Input() title: string = "";
  @Input() visible: boolean = false;
  @Input() withOutContent: boolean = false;
  @Input() width: string = "";
  @Output() onChanged = new EventEmitter<boolean>();
  constructor(public bookingService: BookingService) {}

  onHide() {
    this.bookingService.setBookingForm(false);
    this.onChanged.emit();
  }
}
