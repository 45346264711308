<div class="faq-container">
    <div class="col-12 col-md-6">
        <div class="container-questions">
            <div class="question" *ngFor="let question of questions" (click)="changeState(question)">
                <app-question [question]="question" />
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="faq-text-container">
            <h2 class="colorYellow cormorant"><app-text-element [inputText]="textElements[0]"/></h2>
            <h1 class="largeText cormorant"><app-text-element [inputText]="textElements[1]"/></h1>
            <p class="mb-3"><app-text-element [inputText]="textElements[2]"/></p>
            <p><app-text-element [inputText]="textElements[3]"/></p>
        </div>
    </div>
</div>
