<app-modal
        [title]="languageService.getStringFromTextElement(textElements[0])"
        [visible]="bookingService.getBookingForm()"
        (onChanged)="reset()"
>
    <div class="booking-form-container">
        <form class="booking-form">
            <div class="booking-item">
                <label for="firstName"><app-text-element [inputText]="textElements[1]"/></label>
                <input pInputText id="firstName" name="firstName" [(ngModel)]="booking.name"/>
                <p *ngIf="errorControl('firstName')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[15]"/>
                </p>
            </div>
            <div class="booking-item">
                <label for="lastName"><app-text-element [inputText]="textElements[2]"/></label>
                <input pInputText id="lastName" name="lastName" [(ngModel)]="booking.surname"/>
            </div>
            <div class="booking-item">
                <label for="date"><app-text-element [inputText]="textElements[5]"/></label>
                <p-calendar
                        [readonlyInput]="true"
                        appendTo="body"
                        [firstDayOfWeek]="1"
                        dateFormat="dd.mm.yy"
                        [style]="{width: '100%'}"
                        id="date"
                        name="date"
                        [(ngModel)]="booking.date"
                />
            </div>
            <div class="booking-item">
                <label for="email"><app-text-element [inputText]="textElements[3]"/></label>
                <input pInputText id="email" name="email" [(ngModel)]="booking.email"/>
                <p *ngIf="errorControl('email')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[15]"/>
                </p>
            </div>
            <div class="booking-item">
                <label for="phone"><app-text-element [inputText]="textElements[4]"/></label>
<!--                <input pInputText id="phone" name="phone" [(ngModel)]="booking.phone"/>-->
                <p-inputMask [(ngModel)]="booking.phone" id="phone" name="phone" mask="(999) 999-9999"></p-inputMask>
                <p *ngIf="errorControl('phone')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[15]"/>
                </p>
            </div>
            <div class="booking-item">
                <label for="passengers"><app-text-element [inputText]="textElements[7]"/></label>
                <!--                <input pInputText id="passengers" name="passengers" [(ngModel)]="booking.passengers"/>-->
                <p-dropdown [options]="selectOptionNumber"
                            [(ngModel)]="booking.personsCount"
                            id="passengers" name="passengers"
                >
                </p-dropdown>
            </div>
            <div class="booking-item">
                <label for="airport"><app-text-element [inputText]="textElements[9]"/></label>
                <p-autoComplete id="airport" name="airport"
                                [(ngModel)]="selectedAirport"
                                [suggestions]="suggestionsAirports"
                                (completeMethod)="searchSuggestions($event)"
                                (onSelect)="watchSelectedAirport()"
                                (onClear)="clearSelectedAirport()"
                                optionLabel="name"
                                dataKey="id"
                                appendTo="body"
                />
            </div>
            <div class="booking-item">
                <label for="service"><app-text-element [inputText]="textElements[18]"/></label>
                <p-dropdown [options]="airportServicesOptions"
                            [(ngModel)]="selectedService"
                            (onChange)="watchSelectedService()"
                            [disabled]="selectedAirport == null"
                            optionLabel="name"
                            id="service"
                            name="service"
                >
                </p-dropdown>
            </div>
            <div class="booking-item">
                <label for="type"><app-text-element [inputText]="textElements[6]"/></label>
                <p-dropdown [options]="serviceType"
                            [(ngModel)]="selectedServiceType"
                            (onChange)="watchSelectedServiceType()"
                            [disabled]="selectedAirport == null"
                            optionLabel="name"
                            id="type"
                            name="type"
                >
                    <ng-template pTemplate="selectedItem">
                        <app-text-element [inputText]="findOption(selectedServiceType?.type ?? null)"/>
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                        <app-text-element [inputText]="option.name"/>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="booking-item">
                <label for="flightCode"><app-text-element [inputText]="textElements[8]"/></label>
                <input pInputText id="flightCode" name="flightCode" [(ngModel)]="booking.flightNumber"/>
            </div>
            <div class="booking-item booking-item-wide">
                <label for="note"><app-text-element [inputText]="textElements[10]"/></label>
                <textarea rows="1" cols="30" pInputTextarea  id="note" name="note"
                          [autoResize]="true"
                          [(ngModel)]="booking.note"
                ></textarea>
            </div>
            <div class="booking-item booking-item-wide">
                <p *ngIf="errorControlFlag" class="colorGray"><app-text-element [inputText]="textElements[16]"/></p>
            </div>
            <div class="buttons-container">
                <app-button
                        [buttonTitle]="textElements[11]"
                        [buttonClass]="'button-style-1 button-size-m'"
                        [buttonJustifyContent]="'start'"
                        (click)="submit()"
                />
            </div>
        </form>
    </div>
</app-modal>
