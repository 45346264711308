import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {InformationCell} from "../../../model/InformationCell";
import {TextElementComponent} from "../text-element/text-element.component";

@Component({
  selector: 'app-cell-gallery',
  standalone: true,
  imports: [CommonModule, TextElementComponent],
  templateUrl: './cell-gallery.component.html',
  styleUrl: './cell-gallery.component.scss'
})
export class CellGalleryComponent {

  getNumber(index: number){
    return "0" + (index + 1).toString()
  }

  cells: InformationCell[] = [
    {
      title: [
        {
          languageId: 0,
          text: "Luxury and Comfort"
        },
        {
          languageId: 1,
          text: "Комфорт"
        },
      ],
      text: [
        {
          languageId: 0,
          text: "Thanks to our network of partners, we are in an ideal position to handle a wide range of tasks, providing support to our clients whenever and wherever they require our assistance."
        },
        {
          languageId: 1,
          text: "Наши клиенты — это люди, которые стремятся облегчить стресс, трудности и проблемы, связанные с авиаперелетами."
        },
      ]
    },
    {
      title: [
        {
          languageId: 0,
          text: "Absolutely Confidential"
        },
        {
          languageId: 1,
          text: "Абсолютная конфиденциальность"
        },
      ],
      text: [
        {
          languageId: 0,
          text: "We respect the importance of your privacy. We take every measure possible to safeguard and protect all of your personal information."
        },
        {
          languageId: 1,
          text: "Мы принимаем все возможные меры для защиты вашей личной информации."
        },
      ]
    },
    {
      title: [
        {
          languageId: 0,
          text: "Experienced Team"
        },
        {
          languageId: 1,
          text: "Опытная команда"
        },
      ],
      text: [
        {
          languageId: 0,
          text: "We believe that every client we work with deserves the very best VIP treatment, so our dedicated teams work tirelessly to deliver a service experience that’s in a class of its own."
        },
        {
          languageId: 1,
          text: "Наши сотрудники неустанно работают над тем, чтобы предоставить сервис, который не имеет себе равных."
        },
      ]
    },
    {
      title: [
        {
          languageId: 0,
          text: "All Around the Globe",
        },
        {
          languageId: 1,
          text: "Работаем с юр. лицами"
        },
      ],
      text: [
        {
          languageId: 0,
          text: "We take great pride in serving esteemed travelers in more than 500 airports across the globe.",
        },
        {
          languageId: 1,
          text: "Мы работаем с корпоративными клиентами в России и по всему миру."
        },
      ]
    },
    {
      title: [
        {
          languageId: 0,
          text: "Fast and Affordable",
        },
        {
          languageId: 1,
          text: "Оплата рублями"
        },
      ],
      text: [
        {
          languageId: 0,
          text: "Just call us or use the form, and you can see how it's fast.",
        },
        {
          languageId: 1,
          text: "Для заказа просто свяжитесь с нами, либо заполните форму и выберите любой из доступных способов оплаты."
        },
      ]
    },
    {
      title: [
        {
          languageId: 0,
          text: "Safety and Security",
        },
        {
          languageId: 1,
          text: "Говорим по русски"
        },
      ],
      text: [
        {
          languageId: 0,
          text: "Our clients are individuals who value safety specifically seek to alleviate the stress, difficulties, and challenges associated with air travel.",
        },
        {
          languageId: 1,
          text: "Мы неизменно оказываем поддержку нашим клиентам, когда и где бы они ни нуждались в нашей помощи."
        },
      ]
    },
  ];

}
