import {TextElement} from "./textElement";

export class Menu {
  title: TextElement[];
  link: string;

  constructor(value: Menu) {
    this.title = value.title || [];
    this.link = value.link || "";
  }
}

export const menu: Menu[] = [
  {
    title: [
      {
        languageId: 0,
        text: "Home",
      },
      {
        languageId: 1,
        text: "Главная",
      },
    ],
    link: ""
  },
  {
    title: [
      {
        languageId: 0,
        text: "Services",

      },
      {
        languageId: 1,
        text: "Услуги",
      },
    ],
    link: "/services"
  },
  {
    title: [
      {
        languageId: 0,
        text: "Airports",
      },
      {
        languageId: 1,
        text: "Аэропорты",
      }
    ],
    link: "airports"
  },
  {
    title: [
      {
        languageId: 0,
        text: "About Us",
      },
      {
        languageId: 1,
        text: "О нас",
      }
    ] ,
    link: "about"
  },
  {
    title: [
      {
        languageId: 0,
        text: "Contact Us",
      },
      {
        languageId: 1,
        text: "Контакты",
      }
    ],
    link: "contact"
  },
];
