import {Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextElement } from "../../../model/textElement";
import { LanguageService } from "../../../services/language.service";

@Component({
  selector: 'app-text-element',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-element.component.html',
  styleUrl: './text-element.component.scss'
})
export class TextElementComponent {
  @Input() inputText: TextElement[] = [];
  @Input() upperCase: boolean = false;

  constructor(private languageService: LanguageService) {}

  outputText(){
    if (!!this.inputText && this.inputText.length > 0) {
      const lang = this.inputText.find(value =>
        value.languageId == this.languageService.getLanguage().id)
      if (lang && lang.text.length > 0) {
          return lang.text || "";
      } else return this.inputText[0].text;
    } else return "";
  }
}
