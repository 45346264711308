import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";

@Component({
  selector: 'app-our-blog',
  standalone: true,
  imports: [CommonModule, TextElementComponent],
  templateUrl: './our-blog.component.html',
  styleUrl: './our-blog.component.scss'
})
export class OurBlogComponent {
  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "OUR BLOG",
      },
      {
        languageId: 1,
        text: "НАШ БЛОГ",
      },
    ],
    [
      {
        languageId: 0,
        text: "Latest Blog",
      },
      {
        languageId: 1,
        text: "Последние записи",
      },
    ],
    [
      {
        languageId: 0,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum, lacus non faucibus congue, lectus quam viverra nulla, quis egestas neque sapien ac magna.",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
  ];
}
