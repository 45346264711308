<footer>
    <div class="container">
        <div class="footer">
            <div class="col-5 col-md">
                <div class="logo">
                    MEET + GREET
                </div>
            </div>
            <div class="col-4 col-md-1">
                <app-switcher/>
            </div>
            <div class="col-3 d-none d-md-block">
                <app-social-media [vertical]="false" [buttonJustifyContent]="'flex-end'"/>
            </div>
        </div>
    </div>
</footer>
<app-booking-form/>
<app-modal-menu/>
