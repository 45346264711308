import {Component, OnInit} from '@angular/core';
import {LanguageService} from "../../../services/language.service";
import {LoginService} from "../../../services/login.service";
import {ButtonComponent} from "../../elements/button/button.component";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {NgForOf, NgIf} from "@angular/common";
import {Tab} from "../../../model/tab";
import {SectionComponent} from "../../elements/section/section.component";
import {AdminTableComponent} from "../../admin/admin-table/admin-table.component";
import {Types} from "../../../model/table";
import {Country} from "../../../model/country";
import {ProgressSpinnerModule} from "primeng/progressspinner";

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    ButtonComponent,
    TextElementComponent,
    NgForOf,
    SectionComponent,
    NgIf,
    AdminTableComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit{
  constructor(public languageService: LanguageService,
              private loginService: LoginService,){}


  mockType: Types = Types.country;

  activeTabId: number = 0;
  selectedTab: Tab = new Tab({
    id: 0,
    type: Types.country,
    header: [],
    label: [],
    url: ""
  });
  activate(id: number){
    this.activeTabId = id;
    this.selectedTab = this.tabs[id];
  }

  logout(){
    this.loginService.logout();
  }

  ngOnInit() {
    this.activate(this.activeTabId);
  }

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "logout",
      },
      {
        languageId: 1,
        text: "выйти",
      },
    ],
    [
      {
        languageId: 0,
        text: "Languages",
      },
      {
        languageId: 1,
        text: "Языки",
      },
    ],
    [
      {
        languageId: 0,
        text: "Country",
      },
      {
        languageId: 1,
        text: "Страны",
      },
    ],
    [
      {
        languageId: 0,
        text: "Cities",
      },
      {
        languageId: 1,
        text: "Города",
      },
    ],
    [
      {
        languageId: 0,
        text: "Airports",
      },
      {
        languageId: 1,
        text: "Аэропорты",
      },
    ],
    [
      {
        languageId: 0,
        text: "Services",
      },
      {
        languageId: 1,
        text: "Услуги",
      },
    ],
    [
      {
        languageId: 0,
        text: "Feedback",
      },
      {
        languageId: 1,
        text: "Обратная связь",
      },
    ],
    [
      {
        languageId: 0,
        text: "Booking",
      },
      {
        languageId: 1,
        text: "Бронирования",
      },
    ],

    [
      {
        languageId: 0,
        text: "Population",
      },
      {
        languageId: 1,
        text: "Население",
      },
    ],
    [
      {
        languageId: 0,
        text: "wikipedia",
      },
      {
        languageId: 1,
        text: "wikipedia",
      },
    ],




  ];

  tabs: Tab[] =[
    {
      id: 0,
      label: this.textElements[1],
      type: Types.language,
      url: "language",
      header: [
        {
          label: [
            {
              languageId: 0,
              text: "Language code 639-1 (unique key)",
            },
            {
              languageId: 1,
              text: "Код языка 639-1 (уникальный ключ)",
            },
          ],
          key: "code639_1",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Native name",
            },
            {
              languageId: 1,
              text: "Оригинальное название",
            },
          ],
          key: "nativeName",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "English Name",
            },
            {
              languageId: 1,
              text: "Английское название",
            },
          ],
          key: "englishName",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Language code code639_3",
            },
            {
              languageId: 1,
              text: "Код языка code639_3",
            },
          ],
          key: "code639_3",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Language code code639_2B",
            },
            {
              languageId: 1,
              text: "Код языка code639_2B",
            },
          ],
          key: "code639_2B",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Language code code639_2T",
            },
            {
              languageId: 1,
              text: "Код языка code639_2T",
            },
          ],
          key: "code639_2T",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
      ]
    },
    {
      id: 1,
      label: this.textElements[2],
      type: Types.country,
      url: "country",
      header: [
        {
          label: [
            {
              languageId: 0,
              text: "Сode",
            },
            {
              languageId: 1,
              text: "Код",
            },
          ],
          key: "code",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Name",
            },
            {
              languageId: 1,
              text: "Название",
            },
          ],
          key: "name",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Population",
            },
            {
              languageId: 1,
              text: "Население",
            },
          ],
          key: "population",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Continent",
            },
            {
              languageId: 1,
              text: "Континент",
            },
          ],
          key: "continent",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Code ISO-3",
            },
            {
              languageId: 1,
              text: "Код ISO-3",
            },
          ],
          key: "code3",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Currency",
            },
            {
              languageId: 1,
              text: "Валюта",
            },
          ],
          key: "currency",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label:[
            {
              languageId: 0,
              text: "Names",
            },
            {
              languageId: 1,
              text: "Названия",
            },
          ],
          key: "names",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
      ]
    },
    {
      id: 2,
      label: this.textElements[3],
      url: "city",
      type: Types.city,
      header: [
        {
          label: [
            {
              languageId: 0,
              text: "City code AirLabs",
            },
            {
              languageId: 1,
              text: "Код города AirLabs",
            },
          ],
          key: "slug",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "City code IATA",
            },
            {
              languageId: 1,
              text: "Код города IATA",
            },
          ],
          key: "city_code",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Name",
            },
            {
              languageId: 1,
              text: "Название",
            },
          ],
          key: "name",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "UN/LOCODE",
            },
            {
              languageId: 1,
              text: "UN/LOCODE",
            },
          ],
          key: "un_locode",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label:     [
            {
              languageId: 0,
              text: "Latitude",
            },
            {
              languageId: 1,
              text: "Широта",
            },
          ],
          key: "lat",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
                {
                  languageId: 0,
                  text: "Longitude",
                },
                {
                  languageId: 1,
                  text: "Долгота",
                },
          ],
          key: "lng",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Height above sea level",
            },
            {
              languageId: 1,
              text: "Высота над уровнем моря",
            },
          ],
          key: "alt",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Country code",
            },
            {
              languageId: 1,
              text: "Код страны",
            },
          ],
          key: "country_code",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label:[
            {
              languageId: 0,
              text: "timezone",
            },
            {
              languageId: 1,
              text: "Часовой пояс",
            },
          ],
          key: "timezone",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Wikipedia",
            },
            {
              languageId: 1,
              text: "Wikipedia",
            },
          ],
          key: "wikipedia",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Population",
            },
            {
              languageId: 1,
              text: "Население",
            },
          ],
          key: "population",
          table: true,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
                {
                  languageId: 0,
                  text: "Popularity",
                },
                {
                  languageId: 1,
                  text: "Популярность",
                },
          ],
          key: "popularity",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Distance",
            },
            {
              languageId: 1,
              text: "Расстояние",
            },
          ],
          key: "distance",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
        {
          label:[
            {
              languageId: 0,
              text: "Names",
            },
            {
              languageId: 1,
              text: "Названия",
            },
          ],
          key: "names",
          table: false,
          form: true,
          sort: '',
          edit: true,
        },
      ]
    },
    {
      id: 3,
      label: this.textElements[4],
      type: Types.airport,
      url: "airport",
      header: [
        {
          label: [
            {
              languageId: 0,
              text: "ID",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "id",
          form: false,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "City code",
            },
            {
              languageId: 1,
              text: "Код города",
            },
          ],
          key: "city_code",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport name",
            },
            {
              languageId: 1,
              text: "Название аэропорта",
            },
          ],
          key: "name",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport code Airlabs",
            },
            {
              languageId: 1,
              text: "Код аэропорта Airlabs",
            },
          ],
          key: "slug",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport code IATA",
            },
            {
              languageId: 1,
              text: "Код аэропорта IATA",
            },
          ],
          key: "iata_code",
          edit: true,
          form: true,
          table: true,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Name",
            },
            {
              languageId: 1,
              text: "Название",
            },
          ],
          key: "names",
          form: true,
          table: true,
          sort: "",
          sortKey: "name",
          edit: true
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport code ICAO",
            },
            {
              languageId: 1,
              text: "Код аэропорта ICAO",
            },
          ],
          key: "icao_code",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport code FAA",
            },
            {
              languageId: 1,
              text: "Код аэропорта FAA",
            },
          ],
          key: "faa_code",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "United Nations Location code",
            },
            {
              languageId: 1,
              text: "Код United Nations Location",
            },
          ],
          key: "un_locode",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Timezone",
            },
            {
              languageId: 1,
              text: "Часовой пояс",
            },
          ],
          key: "timezone",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Latitude",
            },
            {
              languageId: 1,
              text: "Широта",
            },
          ],
          key: "lat",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Longitude",
            },
            {
              languageId: 1,
              text: "Longitude",
            },
          ],
          key: "lng",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Height above sea level",
            },
            {
              languageId: 1,
              text: "Высота над уровнем моря",
            },
          ],
          key: "alt",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "City",
            },
            {
              languageId: 1,
              text: "Город",
            },
          ],
          key: "city",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Country code",
            },
            {
              languageId: 1,
              text: "Код страны",
            },
          ],
          key: "country_code",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "International departures",
            },
            {
              languageId: 1,
              text: "Международные отправления",
            },
          ],
          key: "departures_intl",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Domestic departures",
            },
            {
              languageId: 1,
              text: "Внутренние отправления",
            },
          ],
          key: "departures_dom",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "International connections",
            },
            {
              languageId: 1,
              text: "Международные связи",
            },
          ],
          key: "connections_intl",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Domestic connections",
            },
            {
              languageId: 1,
              text: "Внутренние связи",
            },
          ],
          key: "connections_dom",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "The largest airport in the capital region sign",
            },
            {
              languageId: 1,
              text: "Признак крупнейшего аэропорта столичного региона",
            },
          ],
          key: "is_major",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "International airport sign",
            },
            {
              languageId: 1,
              text: "Признак международного аэропорта",
            },
          ],
          key: "is_international",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Number of airport runways",
            },
            {
              languageId: 1,
              text: "Количество взлетно-посадочных полос аэропорта",
            },
          ],
          key: "runways",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Number of airport flights",
            },
            {
              languageId: 1,
              text: "Количество авиасообщений аэропорта",
            },
          ],
          key: "connections",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Number of departures from the airport",
            },
            {
              languageId: 1,
              text: "Количество вылетов из аэропорта",
            },
          ],
          key: "departures",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport website address",
            },
            {
              languageId: 1,
              text: "Адрес сайта аэропорта",
            },
          ],
          key: "website",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "twitter",
            },
            {
              languageId: 1,
              text: "Адрес Twitter",
            },
          ],
          key: "Twitter",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Facebook",
            },
            {
              languageId: 1,
              text: "Адрес Facebook",
            },
          ],
          key: "facebook",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Instagram",
            },
            {
              languageId: 1,
              text: "Адрес Instagram",
            },
          ],
          key: "instagram",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Linkedin",
            },
            {
              languageId: 1,
              text: "Адрес LinkedIn",
            },
          ],
          key: "linkedin",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Youtube",
            },
            {
              languageId: 1,
              text: "Адрес YouTube",
            },
          ],
          key: "youtube",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Phone",
            },
            {
              languageId: 1,
              text: "Телефон",
            },
          ],
          key: "phone",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Formatted airport phone",
            },
            {
              languageId: 1,
              text: "Отформатированный телефон аэропорта",
            },
          ],
          key: "phone_formatted",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Email",
            },
            {
              languageId: 1,
              text: "Адрес Email",
            },
          ],
          key: "email",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Postcode",
            },
            {
              languageId: 1,
              text: "Почтовый индекс",
            },
          ],
          key: "postal_code",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Schedule attribute",
            },
            {
              languageId: 1,
              text: "Признак расписания",
            },
          ],
          key: "with_schedules",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport type",
            },
            {
              languageId: 1,
              text: "Тип аэропорта",
            },
          ],
          key: "type",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport code FIR",
            },
            {
              languageId: 1,
              text: "Код аэропорта FIR",
            },
          ],
          key: "fir_code",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport name FIR",
            },
            {
              languageId: 1,
              text: "Название аэропорта FIR",
            },
          ],
          key: "fir_name",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport size",
            },
            {
              languageId: 1,
              text: "Размер аэропорта",
            },
          ],
          key: "size",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "Popularity",
            },
            {
              languageId: 1,
              text: "Популярность аэропорта",
            },
          ],
          key: "popularity",
          edit: true,
          form: true,
          table: false,
          sort: ""
        },
        {
          label: [
            {
              languageId: 0,
              text: "City",
            },
            {
              languageId: 1,
              text: "Город",
            },
          ],
          key: "cityObject",
          form: true,
          table: true,
          sort: "",
          obj: true,
          headers: [
            {
              label: [
                {
                  languageId: 0,
                  text: "slug",
                },
                {
                  languageId: 1,
                  text: "slug",
                },
              ],
              key: "slug",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "City code",
                },
                {
                  languageId: 1,
                  text: "Код города",
                },
              ],
              key: "city_code",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Name",
                },
                {
                  languageId: 1,
                  text: "Название",
                },
              ],
              key: "name",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Unlocode",
                },
                {
                  languageId: 1,
                  text: "Unlocode",
                },
              ],
              key: "un_locode",
              table: false,
              form: true,
              sort: '',
            },
            {
              label:     [
                {
                  languageId: 0,
                  text: "lat",
                },
                {
                  languageId: 1,
                  text: "lat",
                },
              ],
              key: "lat",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "lng",
                },
                {
                  languageId: 1,
                  text: "lng",
                },
              ],
              key: "lng",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "alt",
                },
                {
                  languageId: 1,
                  text: "alt",
                },
              ],
              key: "alt",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Country code",
                },
                {
                  languageId: 1,
                  text: "Код страны",
                },
              ],
              key: "country_code",
              table: false,
              form: true,
              sort: '',
            },
            {
              label:[
                {
                  languageId: 0,
                  text: "timezone",
                },
                {
                  languageId: 1,
                  text: "Часовой пояс",
                },
              ],
              key: "timezone",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Wikipedia",
                },
                {
                  languageId: 1,
                  text: "Wikipedia",
                },
              ],
              key: "wikipedia",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Population",
                },
                {
                  languageId: 1,
                  text: "Население",
                },
              ],
              key: "population",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Popularity",
                },
                {
                  languageId: 1,
                  text: "Популярность",
                },
              ],
              key: "popularity",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Distance",
                },
                {
                  languageId: 1,
                  text: "Расстояние",
                },
              ],
              key: "distance",
              table: false,
              form: true,
              sort: '',
            },
            {
              label:[
                {
                  languageId: 0,
                  text: "City name",
                },
                {
                  languageId: 1,
                  text: "Название города",
                },
              ],
              key: "names",
              table: true,
              form: true,
              sort: '',
              sortKey: 'city_name',
              namesField: true,
            },
          ]
        },
        {
          label: [
            {
              languageId: 0,
              text: "Country",
            },
            {
              languageId: 1,
              text: "Страна",
            },
          ],
          key: "countryObject",
          form: true,
          table: true,
          sort: "",
          obj: true,
          headers: [
            {
              label: [
                {
                  languageId: 0,
                  text: "Code",
                },
                {
                  languageId: 1,
                  text: "Код",
                },
              ],
              key: "code",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Name",
                },
                {
                  languageId: 1,
                  text: "Название",
                },
              ],
              key: "name",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Population",
                },
                {
                  languageId: 1,
                  text: "Население",
                },
              ],
              key: "population",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Continent",
                },
                {
                  languageId: 1,
                  text: "Континент",
                },
              ],
              key: "continent",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Code ISO-3",
                },
                {
                  languageId: 1,
                  text: "Код ISO-3",
                },
              ],
              key: "code3",
              table: false,
              form: true,
              sort: '',
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Currency",
                },
                {
                  languageId: 1,
                  text: "Валюта",
                },
              ],
              key: "currency",
              table: false,
              form: true,
              sort: '',
            },
            {
              label:[
                {
                  languageId: 0,
                  text: "Country name",
                },
                {
                  languageId: 1,
                  text: "Название страны",
                },
              ],
              key: "names",
              table: true,
              form: true,
              sort: '',
              sortKey: 'country_name',
              namesField: true,
            },
          ]
        },
      ]
    },
    {
      id: 4,
      label: this.textElements[6],
      url: "feedback",
      type: Types.feedback,
      header: [
        {
          label: [
            {
              languageId: 0,
              text: "Name",
            },
            {
              languageId: 1,
              text: "Название",
            },
          ],
          key: "name",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Dispatch time",
            },
            {
              languageId: 1,
              text: "Время отправки",
            },
          ],
          key: "createTime",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Phone",
            },
            {
              languageId: 1,
              text: "Телефон",
            },
          ],
          key: "phone",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "email",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "email",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "allowPhoneContact",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "allowPhoneContact",
          form: false,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "allowEmailContact",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "allowEmailContact",
          form: false,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "allowWhatsAppContact",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "allowWhatsAppContact",
          form: false,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "allowTelegramContact",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "allowTelegramContact",
          form: false,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "allowViberContact",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "allowViberContact",
          form: false,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "names",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "names",
          form: false,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Text",
            },
            {
              languageId: 1,
              text: "Сообщение",
            },
          ],
          key: "text",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
      ]
    },
    {
      id: 5,
      label: this.textElements[7],
      url: "booking",
      type: Types.booking,
      header: [
        {
          label: [
            {
              languageId: 0,
              text: "Name",
            },
            {
              languageId: 1,
              text: "Название",
            },
          ],
          key: "name",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Surname",
            },
            {
              languageId: 1,
              text: "Фамилия",
            },
          ],
          key: "surname",
          form: true,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Phone",
            },
            {
              languageId: 1,
              text: "Телефон",
            },
          ],
          key: "phone",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Email",
            },
            {
              languageId: 1,
              text: "",
            },
          ],
          key: "email",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Create time",
            },
            {
              languageId: 1,
              text: "Время создания",
            },
          ],
          key: "createTime",
          form: true,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Date",
            },
            {
              languageId: 1,
              text: "Дата",
            },
          ],
          key: "date",
          form: true,
          table: true,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Persons count",
            },
            {
              languageId: 1,
              text: "Колличество пассажиров",
            },
          ],
          key: "personsCount",
          form: true,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Flight number",
            },
            {
              languageId: 1,
              text: "Номер рейса",
            },
          ],
          key: "flightNumber",
          form: true,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Service type",
            },
            {
              languageId: 1,
              text: "Тип услуги",
            },
          ],
          key: "serviceType",
          form: true,
          table: false,
          sort: "",
          edit: true,
        },
        {
          label: [
            {
              languageId: 0,
              text: "Airport",
            },
            {
              languageId: 1,
              text: "Аэропорт",
            },
          ],
          key: "airport",
          form: true,
          table: true,
          obj: true,
          sort: "",
          edit: true,
          headers: [
            {
              label: [
                {
                  languageId: 0,
                  text: "Name",
                },
                {
                  languageId: 1,
                  text: "Название",
                },
              ],
              key: "names",
              form: true,
              table: false,
              sort: "",
              sortKey: "",
              edit: true,
              namesField: true,
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Airport name",
                },
                {
                  languageId: 1,
                  text: "Название аэропорта",
                },
              ],
              key: "name",
              edit: false,
              form: true,
              table: true,
              sort: "",
              namesField: true,
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "ID",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "id",
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "City code",
                },
                {
                  languageId: 1,
                  text: "Код города",
                },
              ],
              key: "city_code",
              edit: false,
              form: true,
              table: false,
              sort: ""
            },

            {
              label: [
                {
                  languageId: 0,
                  text: "slug",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "slug",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Code",
                },
                {
                  languageId: 1,
                  text: "Код",
                },
              ],
              key: "iata_code",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "icao_code",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "icao_code",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "faa_code",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "faa_code",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "un_locode",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "un_locode",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Timezone",
                },
                {
                  languageId: 1,
                  text: "Часовой пояс",
                },
              ],
              key: "timezone",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "lat",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "lat",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "lng",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "lng",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "alt",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "alt",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "City",
                },
                {
                  languageId: 1,
                  text: "Город",
                },
              ],
              key: "city",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Country code",
                },
                {
                  languageId: 1,
                  text: "Код страны",
                },
              ],
              key: "country_code",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "departures_intl",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "departures_intl",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "departures_dom",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "departures_dom",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "connections_intl",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "connections_intl",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "connections_dom",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "connections_dom",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "is_major",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "is_major",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "is_international",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "is_international",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "runways",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "runways",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "connections",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "connections",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "departures",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "departures",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "website",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "website",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "twitter",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "twitter",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "facebook",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "facebook",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "instagram",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "instagram",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "linkedin",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "linkedin",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "youtube",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "youtube",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "Phone",
                },
                {
                  languageId: 1,
                  text: "Телефон",
                },
              ],
              key: "phone",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "phone_formatted",
                },
                {
                  languageId: 1,
                  text: "Формат телефона",
                },
              ],
              key: "phone_formatted",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "email",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "email",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "postal_code",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "postal_code",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "with_schedules",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "with_schedules",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "type",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "type",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "fir_code",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "fir_code",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "fir_name",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "fir_name",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "size",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "size",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
            {
              label: [
                {
                  languageId: 0,
                  text: "popularity",
                },
                {
                  languageId: 1,
                  text: "",
                },
              ],
              key: "popularity",
              edit: true,
              form: true,
              table: false,
              sort: ""
            },
          ]
        },

      ],
    },
  ];
  protected readonly Country = Country;
}
