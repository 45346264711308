import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {IconComponent} from "../icon/icon.component";
import {IconsName} from "../../../model/icons";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../text-element/text-element.component";
import {Author} from "../../../model/author";

@Component({
  selector: 'app-quote',
  standalone: true,
  imports: [CommonModule, IconComponent, TextElementComponent],
  templateUrl: './quote.component.html',
  styleUrl: './quote.component.scss'
})
export class QuoteComponent {
  @Input() text: TextElement[] = [
    {
      languageId: 0,
      text: "Nam antimperdiet ex. Sed eget eros suscipit erat condimentum vehicula et egestas nisi. Phasellus pulvinar cursus sem, non placerat nulla elementum eu. Aenean fermentum sem sed gravida tincidunt. Morbi vitae lacus mi. Proin arcu augue, euismod sodales molestie ac, varius e erat, aliquam eget enim sed",
    },
    {
      languageId: 1,
      text: "",
    },
  ];
  @Input() author: Author = new Author({
    name: [
      {
        languageId: 0,
        text: "Colt Porter",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    position: [
      {
        languageId: 0,
        text: "Designation",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    imgUrl: "/assets/author.jpg",
  });
  protected readonly IconsName = IconsName;
}
