<div class="our-services-container">
  <div class="mh col-12 col-md-4">
    <div class="title-our-services-container">
      <div class="title-our-services-block colorGray">
        <h3 class="colorYellow subtitle"><app-text-element [inputText]="textElements[0]"/></h3>
        <h1 class="largeText cormorant"><app-text-element [inputText]="textElements[1]"/></h1>
        <p><app-text-element [inputText]="textElements[2]"/></p>
        <app-button
          [buttonTitle]="textElements[3]"
          [buttonClass]="'button-style-2 button-size-l'"
          [buttonJustifyContent]="'start'"
          (click)="bookingService.setBookingForm(true)"
        />
      </div>
    </div>
  </div>
  <div class="col-1 d-none d-md-block">

  </div>
  <div class="mh col-12 col-md-7">
      <div class="info-panel-container">
          <app-info-panel class="all-area"/>
      </div>
  </div>
</div>
