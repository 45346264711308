<div class="airport-services-container">
    <div class="airport-services-content">
        <div class="panel-content">
            <div class="panel-item">
<!--                <h4><app-text-element [inputText]="textElements[0]"/></h4>-->
                <app-service-switcher (outputService)="getService($event)"/>
            </div>
            <div class="panel-item">
<!--                <h4><app-text-element [inputText]="textElements[1]"/></h4>-->
                <app-lang-switcher [langCode]="this.lang" (outputLangCode)="getLang($event)"/>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <label for="information"><app-text-element [inputText]="textElements[2]"/></label>
                <input [(ngModel)]="form.information" name="information" id="information"
                       pInputText
                       type="text"
                >
            </div>
            <div class="field">
                <label for="informations"><app-text-element [inputText]="textElements[3]"/></label>
                <input [(ngModel)]="form.informations" name="informations" id="informations"
                       pInputText
                       type="text"
                >
            </div>
            <div class="field">
                <label for="serviceName"><app-text-element [inputText]="textElements[4]"/></label>
                <input [(ngModel)]="form.serviceName" name="serviceName" id="serviceName"
                       pInputText
                       type="text"
                >
            </div>
            <div class="field">
                <label for="serviceNames"><app-text-element [inputText]="textElements[5]"/></label>
                <input [(ngModel)]="form.names" name="serviceNames" id="serviceNames"
                       pInputText
                       type="text"
                >
            </div>
            <div class="field">
                <label for="description"><app-text-element [inputText]="textElements[6]"/></label>
                <input [(ngModel)]="form.description" name="description" id="description"
                       pInputText
                       type="text"
                >
            </div>
            <div class="field">
                <label for="description"><app-text-element [inputText]="textElements[7]"/></label>
                <input [(ngModel)]="form.descriptions" name="descriptions" id="descriptions"
                       pInputText
                       type="text"
                >
            </div>
            <div class="field">
                <label for="arrivalPriceFirst"><app-text-element [inputText]="textElements[8]"/></label>
                <input [(ngModel)]="form.arrivalPriceFirst" name="arrivalPriceFirst" id="arrivalPriceFirst"
                       pInputText
                       type="number"
                >
            </div>
            <div class="field">
                <label for="arrivalPriceSubsequent"><app-text-element [inputText]="textElements[9]"/></label>
                <input [(ngModel)]="form.arrivalPriceSubsequent" name="arrivalPriceSubsequent" id="arrivalPriceSubsequent"
                       pInputText
                       type="number"
                >
            </div>
            <div class="field">
                <label for="departurePriceFirst"><app-text-element [inputText]="textElements[10]"/></label>
                <input [(ngModel)]="form.departurePriceFirst" name="departurePriceFirst" id="departurePriceFirst"
                       pInputText
                       type="number"
                >
            </div>
            <div class="field">
                <label for="departurePriceSubsequent"><app-text-element [inputText]="textElements[11]"/></label>
                <input [(ngModel)]="form.departurePriceSubsequent" name="departurePriceSubsequent" id="departurePriceSubsequent"
                       pInputText
                       type="number"
                >
            </div>
            <div class="field">
                <label for="transitPriceFirst"><app-text-element [inputText]="textElements[12]"/></label>
                <input [(ngModel)]="form.transitPriceFirst" name="transitPriceFirst" id="transitPriceFirst"
                       pInputText
                       type="number"
                >
            </div>
            <div class="field">
                <label for="transitPriceSubsequent"><app-text-element [inputText]="textElements[13]"/></label>
                <input [(ngModel)]="form.transitPriceSubsequent" name="transitPriceSubsequent" id="transitPriceSubsequent"
                       pInputText
                       type="number"
                >
            </div>
        </div>
    </div>
</div>
