import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {BackgroundBlockComponent} from "../../elements/background-block/background-block.component";
import {InformationCell} from "../../../model/InformationCell";
import {ButtonComponent} from "../../elements/button/button.component";

@Component({
  selector: 'app-our-luxury-charter',
  standalone: true,
  imports: [CommonModule, TextElementComponent, BackgroundBlockComponent, ButtonComponent],
  templateUrl: './our-luxury-charter.component.html',
  styleUrl: './our-luxury-charter.component.scss'
})
export class OurLuxuryCharterComponent {
  gradient: string = "background-color: transparent; background-image: linear-gradient(180deg, #00000040 0%, #1A1A1AD9 100%);"
  prices: number[] = [1050, 840, 5400];
  urls: string[] = [
      "/assets/planeBackground3.jpg",
      "/assets/planeBackground4.jpg",
      "/assets/planeBackground5.jpg",
  ]

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "OUR LUXURY CHARTER",
      },
      {
        languageId: 1,
        text: "ПОПУЛЯРНЫЕ НАПРАВЛЕНИЯ",
      },
    ],
    [
      {
        languageId: 0,
        text: "We Provide Luxury Service for You",
      },
      {
        languageId: 1,
        text: "Мы предоставляем услуги для Вас по всему миру",
      },
    ],
    [
      {
        languageId: 0,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum, lacus non faucibus congue, lectus quam viverra nulla, quis egestas neque sapien ac magna.",
      },
      {
        languageId: 1,
        text: "Основная цель услуги встречи и сопровождения в аэропорту — предоставить путешественникам беспрепятственное и комфортное соблюдение всех формальностей, помогая им пройти пункты контроля и досмотра.",
      },
    ],
    [
      {
        languageId: 0,
        text: "Dubai International Airport (DXB)",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Al Majlis VIP Lounge",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Istanbul International Airport (IST)",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Full VIP",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "London - Heathrow Airport (LHR)",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Full private VVIP",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "BOOK NOW",
      },
      {
        languageId: 1,
        text: "Заказать",
      },
    ],
  ];

  servicesList: InformationCell[] = [
    {
      title: this.textElements[3],
      text: this.textElements[4],
      index: this.prices[0],
      url: this.urls[0]
    },
    {
      title: this.textElements[5],
      text: this.textElements[6],
      index: this.prices[1],
      url: this.urls[1]
    },
    {
      title: this.textElements[7],
      text: this.textElements[8],
      index: this.prices[2],
      url: this.urls[2]
    },
  ];
}
