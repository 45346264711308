import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MenuComponent} from "../menu/menu.component";
import {ButtonComponent} from "../elements/button/button.component";
import {SwitcherComponent} from "../elements/switcher/switcher.component";
import {TextElement} from "../../model/textElement";
import {BookingService} from "../../services/booking.service";
import {IconComponent} from "../elements/icon/icon.component";
import {IconsName} from "../../model/icons";
import {RouterLink} from "@angular/router";
import {MessageService} from "primeng/api";
import {MessagesModule} from "primeng/messages";
import {NotificationComponent} from "../elements/notification/notification.component";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MenuComponent, ButtonComponent, SwitcherComponent, IconComponent, RouterLink, MessagesModule, NotificationComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [MessageService]
})
export class HeaderComponent {
  constructor(public bookingService: BookingService,
              public messageService: MessageService) {}

  buttonTitle: TextElement[] = [
    {
      languageId: 0,
      text: "ENQUIRE NOW",
    },
    {
      languageId: 1,
      text: "ЗАКАЗАТЬ",
    },
  ];

  protected readonly IconsName = IconsName;
}
