import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Icons, IconsName} from "../../../model/icons";
import {IconComponent} from "../../elements/icon/icon.component";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {InformationCell} from "../../../model/InformationCell";
import {BackgroundBlockComponent} from "../../elements/background-block/background-block.component";

@Component({
  selector: 'app-info-panel',
  standalone: true,
  imports: [CommonModule, IconComponent, TextElementComponent, BackgroundBlockComponent],
  templateUrl: './info-panel.component.html',
  styleUrl: './info-panel.component.scss'
})
export class InfoPanelComponent {

  information: InformationCell[] = [
    {
      icon: IconsName.Plane,
      title: [
        {
          languageId: 0,
          text: "Meet & Greet",
        },
        {
          languageId: 1,
          text: "Meet & Greet",
        },
      ],
      text: [
        {
          languageId: 0,
          text: "Our airport service is designed to meet the unique challenges of modern international travel, offering you the utmost comfort and luxury for your future trips",
        },
        {
          languageId: 1,
          text: "Наше обслуживание в аэропорту предназначено для решения уникальных задач современных международных путешествий, предлагая вам максимальный комфорт и роскошь для ваших поездок.",
        },
      ],
    },
    {
      icon: IconsName.Gears,
      title: [
        {
          languageId: 0,
          text: "VIP Transfers",
        },
        {
          languageId: 1,
          text: "VIP Transfers",
        },

      ],
      text: [
        {
          languageId: 0,
          text: "Are you celebrity, popular influencer or a person who prefers comfort while traveling? Car transfer service is tailored to complement your personality and preferences.\n",
        },
        {
          languageId: 1,
          text: "Вы человек который ценит комфорт в путешествиях? Услуга трансфера на представительском автомобиле разработана с учетом вашей индивидуальности и предпочтений.",
        },
      ],
    },
    {
      icon: IconsName.TimeTruck,
      title: [
        {
          languageId: 0,
          text: "Fast track",
        },
        {
          languageId: 1,
          text: "",
        },
      ],
      text: [
        {
          languageId: 0,
          text: "Arrival and Departure stress-free documentation and speedy run through passport control, security check, and luggage processing.",
        },
        {
          languageId: 1,
          text: "Прилет и вылет без стресса, быстрое прохождение паспортного контроля, досмотра и оформление багажа.",
        },
      ],
    },
    {
      icon: IconsName.PersonPlus,
      title: [
        {
          languageId: 0,
          text: "VIP Terminal & Lounge",
        },
        {
          languageId: 1,
          text: "VIP Terminal & Lounge",
        },
      ],
      text: [
        {
          languageId: 0,
          text: "Clients who choose our private lounge access experience the ultimate luxury and indulgence. We strive to provide a premium VIP treatment, ensuring that our guests feel like royalty",
        },
        {
          languageId: 1,
          text: "Такой взыскательный путешественник как Вы заслуживает особо комфортных условий во время пребывания в аэропорту.",
        },
      ],
    },
  ];

  protected readonly Icons = Icons;
}
