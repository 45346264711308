import {Names} from "./names";

export class FeedbackForm {
    id?: number;
    name: string;
    createTime?: string;
    phone?: string;
    email?: string;
    text?: string;
    allowPhoneContact: boolean;
    allowEmailContact: boolean;
    allowWhatsAppContact: boolean;
    allowTelegramContact: boolean;
    allowViberContact: boolean;
    names?: Names;
    constructor(value?: FeedbackForm) {
        this.name = value?.name || "";
        this.createTime = value?.createTime || "";
        this.phone = value?.phone || "";
        this.email = value?.email || "";
        this.text = value?.text || "";
        this.allowPhoneContact = value?.allowPhoneContact || false;
        this.allowEmailContact = value?.allowEmailContact || false;
        this.allowWhatsAppContact = value?.allowWhatsAppContact || false;
        this.allowTelegramContact = value?.allowTelegramContact || false;
        this.allowViberContact = value?.allowViberContact || false;
    }
}
