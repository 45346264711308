<app-section [sectionClass]="'section-one-screen'">
    <div class="container-about">
        <h2><app-text-element [inputText]="textElements[0]"/></h2>
        <p>
            <app-text-element [inputText]="textElements[1]"/>
            <a class="colorYellow" href="http://meetplusgreet.com"><app-text-element [inputText]="textElements[2]"/></a>
            <app-text-element [inputText]="textElements[3]"/>
        </p>
        <p><app-text-element [inputText]="textElements[4]"/></p>
        <p><app-text-element [inputText]="textElements[5]"/></p>
        <p><app-text-element [inputText]="textElements[6]"/></p>
        <a class="colorYellow" href="assets/legal-data.pdf" target="_blank"><app-text-element [inputText]="textElements[7]"/></a>
    </div>
</app-section>
