import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconsName} from "../../../model/icons";
import {IconComponent} from "../icon/icon.component";
import {NgIf} from "@angular/common";
import {Breadcrumb} from "primeng/breadcrumb";

@Component({
  selector: 'app-sort',
  standalone: true,
  imports: [
    IconComponent,
    NgIf
  ],
  templateUrl: './sort.component.html',
  styleUrl: './sort.component.scss'
})
export class SortComponent {
  @Output() changeSort = new EventEmitter<string>();
  @Input() state: string;

  changeState(){
      switch (this.state){
        case '': {
          this.changeSort.emit('asc');
          break;
        }
        case 'asc': {
          this.changeSort.emit('desc');
          break
        }
        case 'desc': {
          this.changeSort.emit('');
          break
        }
      }
    }

    protected readonly IconsName = IconsName;
}
