<app-section [sectionClass]="'section-auto-2'">
    <div class="admin-container">
        <div class="tabs-container">
            <div *ngFor="let tab of tabs" (click)="activate(tab.id)" class="tab"
                 [class]="tab.id == activeTabId ? 'tab-active' : ''"
            >
                <h4><app-text-element [inputText]="tab.label"/></h4>
            </div>
            <div class="exit">
                <app-button
                        [buttonTitle]="textElements[0]"
                        [buttonClass]="'button-style-1 button-size-sm'"
                        [buttonJustifyContent]="'end'"
                        (click)="logout()"
                />
            </div>
        </div>
        <div class="content-container">
            <app-admin-table
                    [title]="selectedTab.label"
                    [header]="selectedTab.header ?? []"
                    [url]="selectedTab.url ?? ''"
                    [type]="selectedTab.type ?? mockType"
            />
        </div>
    </div>
</app-section>

