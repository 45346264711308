<div class="airport-m-and-g-container row mh">
  <div class="col-12 col-md-6">
    <div class="table-and-photo-container">
      <div class="col-12 col-md-4">
        <div class="table-component-container">
          <div class="table-air">
            <div class="table-item" *ngFor="let item of tableItems">
              <app-icon class="mb-2" [icon]="item.icon || IconsName.Default" [height]="40" [width]="40"/>
              <h4 class="colorWhite header-text"><app-text-element [inputText]="item.title || []"/></h4>
              <p><app-text-element [inputText]="item.text || []"/></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 mt-5">
        <div class="photo-container">
          <div class="photo">
            <img src="assets/pilots.jpg">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-1 d-none d-md-block">
    
  </div>
  <div class="col-12 col-md-5">
    <div class="airport-text-container">
      <div class="airport-text">
        <h4 class="colorYellow subtitle"><app-text-element [inputText]="textElements[0]"/></h4>
        <h1 class="largeText colorWhite"><app-text-element [inputText]="textElements[1]"/></h1>
        <p class="colorGray"><app-text-element [inputText]="textElements[2]"/></p>
        <app-button
          [buttonTitle]="textElements[3]"
          [buttonClass]="'button-style-1 button-size-l'"
          [buttonJustifyContent]="'start'"
        />
      </div>
    </div>
  </div>
</div>


