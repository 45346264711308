import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {GetInTouchField, TypeOfFiled, waysOfContactList} from "../../../model/contact";
import {LanguageService} from "../../../services/language.service";
import {ButtonComponent} from "../../elements/button/button.component";
import {CheckboxComponent} from "../../elements/checkbox/checkbox.component";
import {IconsName} from "../../../model/icons";
import {FormsModule} from "@angular/forms";
import {FeedbackForm} from "../../../model/feedbackForm";
import {FeedbackService} from "../../../services/feedback.service";
import {ModalComponent} from "../../elements/modal/modal.component";
import {Booking} from "../../../model/booking";
import {MyMessageService} from "../../../services/my-message.service";

@Component({
  selector: 'app-get-in-touch',
  standalone: true,
  imports: [CommonModule, TextElementComponent, ButtonComponent, CheckboxComponent, FormsModule, ModalComponent],
  templateUrl: './get-in-touch.component.html',
  styleUrl: './get-in-touch.component.scss'
})
export class GetInTouchComponent {
  constructor(protected languageService: LanguageService,
              public feedbackService: FeedbackService,
              public myMessageService: MyMessageService) {

  }

  errorControlFlag: boolean = false;

  requiredField: string[] = ["name", "email", "phone"];

  form: FeedbackForm = new FeedbackForm();

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "CONTACT US",
      },
      {
        languageId: 1,
        text: "Свяжитесь с нами",
      },
    ],
    [
      {
        languageId: 0,
        text: "Get In Touch",
      },
      {
        languageId: 1,
        text: "Обратная связь",
      },
    ],
    [
      {
        languageId: 0,
        text: "SEND MESSAGE",
      },
      {
        languageId: 1,
        text: "Отправить сообщение",
      },
    ],
    [
      {
        languageId: 0,
        text: "Ways of contact (check all if applicable):",
      },
      {
        languageId: 1,
        text: "Выберите предпочтительный способ связи:",
      },
    ],
    [
      {
        languageId: 0,
        text: "Thank you for your message. It has been sent. We will get in touch with you shortly",
      },
      {
        languageId: 1,
        text: "Спасибо за ваше сообщение. Оно было отправлено. Мы свяжемся с вами в ближайшее время",
      },
    ],
    [
      {
        languageId: 0,
        text: "The field is required",
      },
      {
        languageId: 1,
        text: "Обязательное поле",
      },
    ],
    [
      {
        languageId: 0,
        text: "One or more fields have an error. Please check and try again",
      },
      {
        languageId: 1,
        text: "В одном или нескольких полях есть ошибка. Пожалуйста, проверьте и попробуйте снова!",
      },
    ],
    [
      {
        languageId: 0,
        text: "Error, something went wrong",
      },
      {
        languageId: 1,
        text: "Ошибка, что-то пошло не так",
      },
    ]
  ];

  contactFormFields: GetInTouchField[] = [
    {
      type: TypeOfFiled.input,
      name: [
        {
          languageId: 0,
          text: "Name",
        },
        {
          languageId: 1,
          text: "Имя",
        },
      ],
      value: "",
    },
    {
      type: TypeOfFiled.input,
      name: [
        {
          languageId: 0,
          text: "Phone",
        },
        {
          languageId: 1,
          text: "Телефон",
        },
      ],
      value: "",
    },
    {
      type: TypeOfFiled.input,
      name: [
        {
          languageId: 0,
          text: "Email",
        },
        {
          languageId: 1,
          text: "",
        },
      ],
      value: "",
    },
    {
      type: TypeOfFiled.textarea,
      name: [
        {
          languageId: 0,
          text: "Message",
        },
        {
          languageId: 1,
          text: "Сообщение",
        },
      ],
      value: "",
    }
  ];

  errorControl(value: string) {
    const field = this.form[value as keyof FeedbackForm];
    return !field && this.errorControlFlag;
  }

  errorsControl() {
    let e: boolean = true;
    Object.keys(this.form).forEach(key => {
      const filled = !!this.form[key as keyof FeedbackForm];
      const required = !!this.requiredField.find(v => v == key);
      if (!filled && required){
        e = false;
      }
    });
    return e;
  }

  reset() {
    this.form = new FeedbackForm({
      name: "",
      createTime: "",
      phone: "",
      email: "",
      text: "",
      allowEmailContact: false,
      allowPhoneContact: false,
      allowTelegramContact: false,
      allowViberContact: false,
      allowWhatsAppContact: false
    });
  }

  sendFeedback() {
    if(this.errorsControl()) {
      this.feedbackService.sendFeedbackForm(this.form).subscribe({
        next: () => {
          this.errorControlFlag = false;
          this.myMessageService.addMessage(this.textElements[4], "success");
          this.reset();
        },
        error: (error) => {
          this.myMessageService.addMessage(this.textElements[7], "error")
          console.log(error);
          this.reset();
        }
      });
    } else {
      this.errorControlFlag = true;
    }
  }



  protected readonly TypeOfFiled = TypeOfFiled;
  protected readonly IconsName = IconsName;
  protected readonly waysOfContactList = waysOfContactList;
}
