import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {languagesNames, Names, SelectorNames} from "../../../model/names";
import {AdminService} from "../../../services/admin.service";
import {LanguageService} from "../../../services/language.service";
import {CountryFlagComponent} from "../country-flag/country-flag.component";

@Component({
  selector: 'app-names-item',
  standalone: true,
  imports: [
    CountryFlagComponent
  ],
  templateUrl: './names-item.component.html',
  styleUrl: './names-item.component.scss'
})
export class NamesItemComponent implements OnChanges{
  constructor(public languageService: LanguageService) {}
  @Input() inputNames: Names = new Names();
  item: SelectorNames = new SelectorNames();
  ngOnChanges() {
    const langList = this.languageService.getBrowserLocalList();
    let i: number = 0;
    while (i < langList.length){
      const nameObj = languagesNames.find(v => v.code == langList[i]);
      if (nameObj) {
        this.item = nameObj;
        this.item.value = this.languageService.getNameByTheLang(this.inputNames) ?? "";
        break;
      }
      i++;
    }
  }
}
