import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type} from '@angular/core';
import {TableModule} from "primeng/table";
import {NgForOf, NgIf} from "@angular/common";
import {TextElement} from "../../../model/textElement";
import {getNewObj, Header} from "../../../model/table";
import {TextElementComponent} from "../text-element/text-element.component";
import {IconComponent} from "../icon/icon.component";
import {IconsName} from "../../../model/icons";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputSwitchModule} from "primeng/inputswitch";
import {SortComponent} from "../sort/sort.component";
import {LanguageService} from "../../../services/language.service";
import {Names} from "../../../model/names";

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    TableModule,
    NgForOf,
    NgIf,
    TextElementComponent,
    IconComponent,
    DropdownModule,
    FormsModule,
    InputSwitchModule,
    SortComponent
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent implements OnChanges{
  constructor(public languageService: LanguageService) {}

  @Input() totalElements: number;
  @Input() totalPages: number;
  @Input() showIndex: boolean;
  @Input() content: any[];
  @Input() header: Header[];
  @Output() changePage = new EventEmitter<number>();
  @Output() changeSize = new EventEmitter<number>();
  @Output() clickRow = new EventEmitter<any>();
  @Output() changeSort = new EventEmitter<string>();
  active: number = 0;
  size: number = 10;
  sizes: number[] = [10, 25, 50];
  sort: string = "";

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Total elements: ",
      },
      {
        languageId: 1,
        text: "Всего записей: ",
      },
    ],
    [
      {
        languageId: 0,
        text: "Size: ",
      },
      {
        languageId: 1,
        text: "Строк на странице: ",
      },
    ],
  ];

  getTotalPagesAsArray() {
    let pages: string[] = [];
    const range: number = 18;
    for (let i = 0; i < this.totalPages; i++) {
      if ( (i == 0) ||
          (
              (
                  (i < this.active + range / 2 - 1 ) &&
                  (i > this.active - range / 2)
              ) ||
              (this.active < range / 2 && i < range) ||
              (this.active > this.totalPages - range / 2  && i > this.totalPages - range -1 )
          ) ||
          (i == this.totalPages - 1)
      ) {
        pages.push((i + 1).toString());
      } else if (
          (i == this.active - Math.round( range / 2)) ||
          (i == this.active + Math.round( range / 2)) ||
          ((i == this.active + range) && (this.active < Math.round(range / 2) - 1)) ||
          ((i == this.totalPages - this.active) && (this.active > this.totalPages - Math.round(range / 2) - 1))
      ) {
        pages.push("...");
      }
    }
    return pages;
  }

  changeActive(value: string){
    switch (value) {
      case "next": {
        if (this.active != this.totalPages - 1) {
          this.active = this.active + 1;
        }
        break;
      }
      case "prev": {
        if (this.active != 0) {
          this.active = this.active - 1;
        }
        break;
      }
      case "start": {
        break;
      }
      case "end": {
        break;
      }
      case "...": {
        break;
      }
      default: {
        const i = Number(value);
        if (i) {
          this.active = i - 1;
        }
        break;
      }
    }
    this.changePage.emit(this.active);
  }

  changeSizes(value: number){
    this.size = value;
    this.changeSize.emit(this.size);
    this.active = 0;
  }

  clickOnRow(row: any) {
    if (row) {
      this.clickRow.emit(row);
    }
  }

  getValue(key?: string, obj?: any) {
    if (key) {
      if (key.includes(".")) {
        const innerKey = key.split('.')[0];
        const correctedKey = Object.keys(obj).find((f: string) => f == innerKey) ?? "";
        const innerObj = obj[correctedKey] ?? new Names();
        if (innerObj && innerObj.hasOwnProperty("names")) {
          return this.languageService.getNameByTheLang(innerObj.names) ?? "";
        }
      } else if (key == "names" || key == "descriptions") {
        const correctedKey = Object.keys(obj).find((f: string) => f == key) ?? "";
        if (correctedKey) {
          return this.languageService.getNameByTheLang(obj[correctedKey]) ?? "";
        }
      } else {
        const correctedKey = Object.keys(obj).find((f: string) => f == key) ?? "";
        if (correctedKey) {
          return obj[correctedKey];
        }
      }
    }
  }

  getSort(value: string, key?: string) {
    if (key) {
      this.sort = key + "," + value;
      this.changeSort.emit(this.sort);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const isHeader =  Object.keys(changes).find((f: string) => f == "header");
    const isTotalElements =  Object.keys(changes).find((f: string) => f == "totalElements");
    if (isHeader || isTotalElements) {
      this.size = 10;
      this.active = 0;
      this.sort = "";
    }

  }

  protected readonly IconsName = IconsName;
  protected readonly Number = Number;
}
