import {TextElement} from "./textElement";
import {Header, Types} from "./table";

export class Tab {
  id: number;
  label: TextElement[];
  header?: Header[];
  type?: Types;
  url?: string;
  constructor(value: Tab) {
    this.id = value.id ?? 0;
    this.label = value.label || [];
    this.header = value.header || [];
    this.type = value.type || Types.country;
    this.url = value.url || "";
  }
}

