<div class="quote-container">
    <app-icon [icon]="IconsName.Quote" [width]="50" [height]="50"/>
    <p><app-text-element [inputText]="text"/></p>
    <div class="author-container">
        <img class="author-img" [srcset]="author.imgUrl">
        <div class="author-title">
            <h3 class="colorWhite"><app-text-element [inputText]="author.name || []"/></h3>
            <h4 class="position-author colorYellow"><app-text-element [inputText]="author.position || []"/></h4>
        </div>
    </div>
</div>
