<div class="contact-info-container">
    <div class="contact-info-item" *ngFor="let item of contacts">
        <h1 class="colorYellow cormorant"><app-text-element [inputText]="item.title || []"/></h1>
        <p class="colorGray"><app-text-element [inputText]="item.text || []"/></p>
        <a class="link" [href]="item.href">
            <app-icon [icon]="item.icon || IconsName.Default" [width]="20" [height]="20"/>
            <h4 class="colorGray cormorant"><app-text-element [inputText]="item.linkText || []"/></h4>
        </a>
    </div>
</div>
