import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BackgroundBlockComponent} from "../../elements/background-block/background-block.component";
import {OurLuxuryCharterComponent} from "../our-luxury-charter/our-luxury-charter.component";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {ButtonComponent} from "../../elements/button/button.component";
import {QuoteComponent} from "../../elements/quote/quote.component";
import {Author, Quote} from "../../../model/author";

@Component({
  selector: 'app-testimonials',
  standalone: true,
  imports: [CommonModule, BackgroundBlockComponent, OurLuxuryCharterComponent, TextElementComponent, ButtonComponent, QuoteComponent],
  templateUrl: './testimonials.component.html',
  styleUrl: './testimonials.component.scss'
})
export class TestimonialsComponent {
  backUrl: string = "/assets/testimonialsBack.jpg";

  testAuthor: Author = {
    name: [
      {
        languageId: 0,
        text: "Colt Porter",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    position: [
      {
        languageId: 0,
        text: "Designation",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    imgUrl: "/assets/author.jpg",
  };

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "TESTIMONIALS",
      },
      {
        languageId: 1,
        text: "ОТЗЫВЫ",
      },
    ],
    [
      {
        languageId: 0,
        text: "What Client Says About Flights",
      },
      {
        languageId: 1,
        text: "Что Клиенты говорят о нас?",
      },
    ],
    [
      {
        languageId: 0,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum, lacus non faucibus congue, lectus quam viverra nulla, quis egestas neque sapien ac magna.",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "VIEW TESTIMONIALS",
      },
      {
        languageId: 1,
        text: "Отзывы о нас",
      },
    ],
  ];

  quotes: Quote[] = [
    {
      text: this.textElements[2],
      author: this.testAuthor,
    },
    {
      text: this.textElements[2],
      author: this.testAuthor,
    },
    {
      text: this.textElements[2],
      author: this.testAuthor,
    },
  ];
}
