<app-section [sectionClass]="'section-one-screen-max-height'" [sectionContainer]="false">
    <app-background-block [url]="'assets/error-back.jpg'" [borderRadius]="0" [opacity]="0.6">
        <div class="container" style="height: 100%">
            <div class="error-container">
                <div class="col-11 d-md-11">
                    <div class="error-text-container">
                        <h1 class="number-text cormorant colorWhite">
                            <app-text-element [inputText]="textElements[0]"/>
                        </h1>
                        <h2 class="colorWhite"><app-text-element [inputText]="textElements[1]"/></h2>
                        <p class="colorWhite"><app-text-element [inputText]="textElements[2]"/></p>
                        <app-button
                                [buttonTitle]="textElements[3]"
                                [buttonClass]="'button-style-1 button-size-l'"
                                [buttonJustifyContent]="'start'"
                                [routerLink]="'/'"
                        />
                    </div>
                </div>
                <div class="col-1 d-none d-md-block">
                    <app-social-media [vertical]="true"/>
                </div>
            </div>
        </div>
    </app-background-block>
</app-section>
