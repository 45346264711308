import {TextElement} from "./textElement";

export class Question {
  question: TextElement[];
  answer: TextElement[];
  active: boolean;
  constructor(value: Question) {
    this.question = value.question || [];
    this.answer = value.answer || [];
    this.active = value.active || false;
  }

}

