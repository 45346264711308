<div class="statistics-container">
    <div class="statistics-back"></div>
    <div class="statistics-vail"></div>
    <div class="statistics-content">
        <div class="statistics-cell-container" *ngFor="let item of statisticsItem; index as i">
            <div class="statistics-cell">
                <app-icon class="mb-2" [icon]="item.icon || IconsName.Default"/>
                <h1 class="largeText colorWhite">{{ Math.round(runningNumberService.getNumbersById(i)) }}</h1>
                <h4 class="label-container colorWhite"><app-text-element [inputText]="item.text || []"/></h4>
                <div class="statistics-cell-line"></div>
            </div>
        </div>
    </div>
</div>



