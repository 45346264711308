<div *ngIf="modalMobileService.getModalMobile()" class="modal-menu-container">
    <app-icon class="modal-menu-cross"
        [icon]="IconsName.Cross"
        [width]="50"
        [height]="50"
        (click)="modalMobileService.setModalMobile(false)"
    />
    <div class="modal-menu">
        <div class="modal-menu-item" *ngFor="let item of menu">
            <a class="font-1-8 quicksand bold-600" (click)="routeToPage(item)">
                <app-text-element [inputText]="item.title"/>
            </a>
        </div>
        <div class="modal-menu-item modal-menu-item-switcher">
            <app-switcher />
        </div>
    </div>
</div>
