import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContactInfoComponent} from "../contact-info/contact-info.component";
import {GetInTouchComponent} from "../get-in-touch/get-in-touch.component";

@Component({
  selector: 'app-contact-info-block',
  standalone: true,
  imports: [CommonModule, ContactInfoComponent, GetInTouchComponent],
  templateUrl: './contact-info-block.component.html',
  styleUrl: './contact-info-block.component.scss'
})
export class ContactInfoBlockComponent {

}
