import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {Question} from "../../../model/question";
import {QuestionComponent} from "../../elements/question/question.component";

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [CommonModule, TextElementComponent, QuestionComponent],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {




  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "FREQUENTLY ASKED QUESTIONS",
      },
      {
        languageId: 1,
        text: "ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ",
      },
    ],
    [
      {
        languageId: 0,
        text: "You Have a Question?",
      },
      {
        languageId: 1,
        text: "У вас есть вопрос?",
      },
    ],
    [
      {
        languageId: 0,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum, lacus non faucibus congue, lectus quam viverra nulla, quis egestas neque sapien ac magna. ",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Phasellus turpis mauris, varius at ex et, tincidunt rutrum mauris. Cras quis ex ullamcorper, porttitor justo quis.",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "How to Morbi rhoncus ante sed neque dictum tristique?",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Vestibulum sed varius sem. Aliquam hendrerit leo urna, quis dignissim tortor facilisis vitae. Sed ut finibus nunc, ut euismod orci. Mauris vitae urna molestie, lacinia nisl at, posuere eros.",
      },
      {
        languageId: 1,
        text: "",
      },
    ],

    [
      {
        languageId: 0,
        text: "How to Maecenas tristique tortor in ligula fringilla?",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Vestibulum sed varius sem. Aliquam hendrerit leo urna, quis dignissim tortor facilisis vitae. Sed ut finibus nunc, ut euismod orci. Mauris vitae urna molestie, lacinia nisl at, posuere eros.",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "How to Fusce suscipit erat ac mollis ultricies?",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Vestibulum sed varius sem. Aliquam hendrerit leo urna, quis dignissim tortor facilisis vitae. Sed ut finibus nunc, ut euismod orci. Mauris vitae urna molestie, lacinia nisl at, posuere eros.",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
  ];

  questions: Question[] = [
    {
      question: this.textElements[4],
      answer: this.textElements[5],
      active: false,
    },
    {
      question: this.textElements[6],
      answer: this.textElements[7],
      active: false,
    },
    {
      question: this.textElements[8],
      answer: this.textElements[9],
      active: false,
    },
  ];

  changeState(question: Question) {
    const state: boolean = question.active;
    this.questions.forEach((v) => {
      v.active = false;
    });
    question.active = !state;
  }

}
