import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {languagesNames, Names, SelectorNames} from "../../../model/names";
import {FormsModule} from "@angular/forms";
import {IconsName} from "../../../model/icons";
import {CountryFlagComponent} from "../country-flag/country-flag.component";
import {IconComponent} from "../icon/icon.component";
import {NgIf} from "@angular/common";
import {TextElementComponent} from "../text-element/text-element.component";
import {LanguageService} from "../../../services/language.service";
import {TextElement} from "../../../model/textElement";

@Component({
  selector: 'app-lang-switcher',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    CountryFlagComponent,
    IconComponent,
    NgIf,
    TextElementComponent
  ],
  templateUrl: './lang-switcher.component.html',
  styleUrl: './lang-switcher.component.scss'
})
export class LangSwitcherComponent implements OnInit{

  constructor(public languageService: LanguageService) {}

  @Input() langCode: string;
  @Output() outputLangCode = new EventEmitter<string>();


  search: string = "";
  languages: SelectorNames[] = [];
  language: SelectorNames = new SelectorNames();


  ngOnInit() {
    this.clearSearch();
    this.select(this.langCode);
  }

  searchFoo(event?: KeyboardEvent){
    if (this.search.length > 0) {
      this.languages = this.languages.filter(v =>
          v.code.toLowerCase().includes(this.search.toLowerCase()) ||
          v.value.toLowerCase().includes(this.search.toLowerCase()) ||
          v.name[0].text.toLowerCase().includes(this.search.toLowerCase()) ||
          v.name[1].text.toLowerCase().includes(this.search.toLowerCase())
      );
    } else {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.languages = languagesNames.map(v => new SelectorNames(v));
    this.search = "";
  }

  select(code: string) {
    this.language = this.languages.find(v => v.code == code) ?? new SelectorNames();
    this.outputLangCode.emit(this.language.code);
  }


  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Search",
      },
      {
        languageId: 1,
        text: "Поиск",
      },
    ],
  ];

  protected readonly IconsName = IconsName;
}
