import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Question} from "../../../model/question";
import {IconComponent} from "../icon/icon.component";
import {Icons, IconsName} from "../../../model/icons";
import {TextElementComponent} from "../text-element/text-element.component";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-question',
  standalone: true,
  imports: [CommonModule, IconComponent, TextElementComponent],
  templateUrl: './question.component.html',
  animations: [
    trigger('openClose', [
      state('closed', style({
        maxHeight: '0',
      })),
      state('open', style({
        maxHeight: '250px',
      })),
      transition('open => closed', [
        animate('1.2s 0s'),
      ]),
      transition('closed => open', [
        animate('1.2s 0s'),
      ]),
    ]),
  ],
  styleUrl: './question.component.scss'
})
export class QuestionComponent {
  @Input() question: Question = new Question({answer: [], question: [], active: false});

  protected readonly IconsName = IconsName;
}
