import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {statistics} from "../model/statistics";

@Injectable({
  providedIn: 'root'
})
export class RunningNumberService {
  constructor() {}


  public isTriggered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public numbers: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(statistics);

  getIsTriggered() {
    return this.isTriggered.value;
  }

  setIsTriggered(value: boolean) {
    this.isTriggered.next(value);
  }

  getNumbers() {
    return this.numbers.value;
  }
  getNumbersById(index: number){
    return this.numbers.value[index];
  }

  setNumbers(value: number[]) {
    this.numbers.next(value);
  }

  getNextStepValues(steps: number){
    const added = statistics.map(value => value / steps);
    let newValues = this.getNumbers();
    newValues = newValues.map((n, index) =>  { return n + added[index] });
    this.setNumbers(newValues);
  }

}
