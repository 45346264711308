import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {InformationCell} from "../../../model/InformationCell";
import {IconsName} from "../../../model/icons";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {IconComponent} from "../../elements/icon/icon.component";
import {RunningNumberService} from "../../../services/running.number.service";

@Component({
  selector: 'app-statistics',
  standalone: true,
  imports: [CommonModule, TextElementComponent, IconComponent],
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.scss'
})
export class StatisticsComponent  {
  constructor(public runningNumberService: RunningNumberService) {};

  statisticsItem: InformationCell[] = [
    {
      icon: IconsName.Certificate,
      text: [
        {
          languageId: 0,
          text: "Professional experts",
        },
        {
          languageId: 1,
          text: "Профессиональные эксперты",
        },
      ],
    },
    {
      icon: IconsName.World,
      text: [
        {
          languageId: 0,
          text: "World Airports",
        },
        {
          languageId: 1,
          text: "Мировые аэропорты",
        },
      ],
    },
    {
      icon: IconsName.Plane,
      text: [
        {
          languageId: 0,
          text: "Happy customers",
        },
        {
          languageId: 1,
          text: "Счастливые клиенты",
        },
      ],
    },
    {
      icon: IconsName.Directions,
      text: [
        {
          languageId: 0,
          text: "Directions",
        },
        {
          languageId: 1,
          text: "Направления",
        },
      ],
    },
  ];

  protected readonly IconsName = IconsName;

  protected readonly Math = Math;
}
