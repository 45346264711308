import {TextElement} from "./textElement";
import {Country} from "./country";
import {City} from "./city";
import {LanguageDto} from "./language";
import {Airport, ServicesOfAirport} from "./airport";
import {FeedbackForm} from "./feedbackForm";
import {BookingDto} from "./booking";

export class Header {
  label: TextElement[];
  key?: string;
  table: boolean;
  form: boolean;
  sort: string;
  sortKey?: string;
  obj?: boolean;
  edit?: boolean;
  namesField?: boolean;
  headers?: Header[];
  constructor(value: Header) {
    this.label = value.label || [];
    this.key = value.key || "";
    this.table = value?.table || false;
    this.form = value?.form || true;
    this.sort = value?.sort || "";
    this.sortKey = value?.sortKey || "";
    this.obj = value?.obj || false;
    this.edit = value?.edit || false;
    this.namesField = value?.namesField || false;
    this.headers = value?.headers || [];
  }
}

export class TableContent {
  label: TextElement[];
  key: string;
  value: any;
  obj?: boolean;
  edit?: boolean;
  namesField?: boolean;
  constructor(value?: TableContent) {
    this.label = value?.label || [];
    this.key = value?.key || "";
    this.value = value?.value || null;
    this.obj = value?.obj || false;
    this.edit = value?.edit || false;
    this.namesField = value?.namesField || false;
  }
}


export interface Page<Type> {
  totalPages?: number;
  totalElements?: number;
  numberOfElements?: number;
  number?: number;
  size?: number;
  content?: Type[];
}

export const enum Types  {
  language,
  city,
  country,
  airport,
  services,
  feedback,
  booking
}

export function getNewObj (type: Types, data?: any) {
  switch(type) {
    case Types.language: {
      return new LanguageDto(data);
    }
    case Types.city: {
      return new City(data);
    }
    case Types.country: {
      return new Country(data);
    }
    case Types.airport: {
      return new Airport(data);
    }
    case Types.services: {
      return new ServicesOfAirport(data);
    }
    case Types.feedback: {
      return new FeedbackForm(data);
    }
    case Types.booking: {
      return new BookingDto(data);
    }
  }
}


