<div class="container-admin-country">
    <div class="title-container">
        <h1 class="colorYellow"><app-text-element [inputText]="title"/></h1>
        <div class="button-container">
            <div class="search-field-container">
                <input type="text"
                       class="search-field"
                       [placeholder]="languageService.getStringFromTextElement(textElements[7])"
                       [(ngModel)]="search" (keyup)="searchFoo($event)"
                />
                <app-icon
                        class="search-field-cross"
                        *ngIf="search.length > 2"
                        (click)="clearSearch()"
                        [icon]="IconsName.Cross"
                        [width]="30"
                        [height]="30"
                />
            </div>
            <app-button
                    [buttonTitle]="textElements[0]"
                    [buttonClass]="'button-style-1 button-size-sm'"
                    [buttonJustifyContent]="'start'"
                    (click)="newEntity()"
            />
        </div>
    </div>
<!--    <div class="loading" *ngIf="loading">-->
<!--        <p-progressSpinner strokeWidth="3" />-->
<!--    </div>-->
    <div class="content-container">
        <app-table [header]="shortHeader"
                   [content]="items"
                   [totalElements]="totalElements"
                   [totalPages]="totalPages"
                   (changePage)="getPage($event)"
                   (changeSize)="getSize($event)"
                   (clickRow)="clickRow($event)"
                   (changeSort)="getSort($event)"
        />
    </div>
</div>
<app-modal
        [title]="languageService.getStringFromTextElement(this.textElements[4])"
        [visible]="dialog"
        (onChanged)="hideDialog()"
>
    <div *ngIf="dialog" class="form-container">
        <form class="edit-form-container" [formGroup]="form">
            <div *ngIf="isFieldNames || isFieldDescriptions" class="edit-form-tabs">
                <h5 class="edit-form-tab"
                    [class]="{ 'edit-form-tab-active': editTabActive == 0 }"
                    (click)="editTabActive = 0"
                ><app-text-element [inputText]="textElements[8]"/>
                </h5>
                <h5 class="edit-form-tab"
                    *ngIf="isFieldNames"
                    [class]="{ 'edit-form-tab-active': editTabActive == 1 }"
                    (click)="editTabActive = 1"
                ><app-text-element [inputText]="textElements[9]"/>
                </h5>
                <h5 class="edit-form-tab"
                    *ngIf="isFieldDescriptions"
                    [class]="{ 'edit-form-tab-active': editTabActive == 2 }"
                    (click)="editTabActive = 2"
                ><app-text-element [inputText]="textElements[10]"/>
                </h5>
                <h5 class="edit-form-tab"
                    *ngIf="isServices"
                    [class]="{ 'edit-form-tab-active': editTabActive == 3 }"
                    (click)="editTabActive = 3"
                ><app-text-element [inputText]="textElements[11]"/>
                </h5>
            </div>
            <div class="edit-form" [hidden]="editTabActive != 0">
                <div class="edit-item" *ngFor="let field of fields" [class]="{ 'edit-label': field.obj }">
                    <div *ngIf="field.obj; else ElseBlock">
                        <h5><app-text-element [inputText]="field.label"/></h5>
                    </div>
                    <ng-template #ElseBlock>
                        <div *ngIf="field.namesField; else ElseElseBlock">
                            <label><app-text-element [inputText]="field.label"/></label>
                            <app-names-item [inputNames]="field.value"/>
                        </div>
                        <ng-template #ElseElseBlock>
                            <label><app-text-element [inputText]="field.label"/></label>
                            <input pInputText
                                   type="text"
                                   [formControlName]="field.key"
                                   [ngModel]="field.value"
                            />
                        </ng-template>
                    </ng-template>
                </div>
            </div>
            <app-selector-names class="edit-form-names"
                                [hidden]="editTabActive != 1"
                                [inputNames]="fieldNames"
                                [changes]="dialog"
                                (outputNames)="getFieldNames($event)"
            />
            <app-selector-names class="edit-form-names"
                                [hidden]="editTabActive != 2"
                                [inputNames]="fieldDescriptions"
                                [changes]="dialog"
                                (outputNames)="getFieldNames($event)"
            />
            <app-airport-services *ngIf="airportId > 0" [hidden]="editTabActive != 3" [airportId]="airportId" />
        </form>
        <div class="edit-buttons-container">
            <app-button
                    [buttonTitle]="textElements[2]"
                    [buttonClass]="'button-style-2 button-size-m'"
                    [buttonJustifyContent]="'start'"
                    (click)="submit()"
                    style="margin-right: 0.5rem"
            />
            <app-button
                    [buttonTitle]="textElements[3]"
                    [buttonClass]="'button-style-1 button-size-m'"
                    [buttonJustifyContent]="'start'"
                    (click)="hideDialog()"

            />
        </div>
    </div>
</app-modal>
