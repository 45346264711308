import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {BackgroundBlockComponent} from "../../elements/background-block/background-block.component";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {ButtonComponent} from "../../elements/button/button.component";

@Component({
  selector: 'app-requirements',
  standalone: true,
  imports: [CommonModule, BackgroundBlockComponent, TextElementComponent, ButtonComponent],
  templateUrl: './requirements.component.html',
  styleUrl: './requirements.component.scss'
})
export class RequirementsComponent {
  back: string = "/assets/planeBackground2.jpg";
  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Tell us your requirements and one of our charter experts will send you a quote.",
      },
      {
        languageId: 1,
        text: "Сообщите нам свои пожелания и мы сделаем все необходимое.",
      },
    ],
    [
      {
        languageId: 0,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum, lacus non faucibus congue, lectus quam viverra nulla, quis egestas neque sapien ac magna.",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "ENQUIRE NOW",
      },
      {
        languageId: 1,
        text: "УЗНАТЬ СЕЙЧАС",
      },
    ],
  ]
}
