import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {FeedbackForm} from "../model/feedbackForm";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private httpClient: HttpClient) {
  }

  sendFeedbackForm(form: FeedbackForm): Observable<FeedbackForm>  {
    return this.httpClient.post<any>(`${environment.apiUrl}/public/feedback`, form);
  }

}
