<div class="get-in-touch-container">
    <h4 class="colorYellow cormorant"><app-text-element [inputText]="textElements[0]"/></h4>
    <h1 class="largeText colorGray cormorant"><app-text-element [inputText]="textElements[1]"/></h1>
    <form class="get-in-touch">
        <div class="get-in-touch-item-container">
            <div class="get-in-touch-item" >
                <input [(ngModel)]="form.name" name="name"
                       [placeholder]="languageService.getStringFromTextElement(contactFormFields[0].name || [])">
                <p *ngIf="errorControl('name')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[5]"/>
                </p>
            </div>
            <div class="get-in-touch-item" >
                <input [(ngModel)]="form.phone" name="phone"
                       [placeholder]="languageService.getStringFromTextElement(contactFormFields[1].name || [])">
                <p *ngIf="errorControl('phone')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[5]"/>
                </p>
            </div>
            <div class="get-in-touch-item" >
                <input [(ngModel)]="form.email" name="email"
                       [placeholder]="languageService.getStringFromTextElement(contactFormFields[2].name || [])">
                <p *ngIf="errorControl('email')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[5]"/>
                </p>
            </div>
            <div class="get-in-touch-item textareaItem">
                <textarea [(ngModel)]="form.text" name="text"
                          [placeholder]="languageService.getStringFromTextElement(contactFormFields[3].name || [])"
                ></textarea>
            </div>
        </div>
        <h4 class="checkbox-container-title colorGray cormorant"><app-text-element [inputText]="textElements[3]"/></h4>
        <div class="checkbox-container">
            <app-checkbox
                    class="checkbox-get-in-touch"
                    [label]="waysOfContactList[0].label || []"
                    [icon]="waysOfContactList[0].icon || IconsName.Default"
                    [class]="'style-2'"
                    [(state)]="form.allowPhoneContact"
            />
            <app-checkbox
                    class="checkbox-get-in-touch"
                    [label]="waysOfContactList[1].label || []"
                    [icon]="waysOfContactList[1].icon || IconsName.Default"
                    [class]="'style-2'"
                    [(state)]="form.allowEmailContact"
            />
            <app-checkbox
                    class="checkbox-get-in-touch"
                    [label]="waysOfContactList[2].label || []"
                    [icon]="waysOfContactList[2].icon || IconsName.Default"
                    [class]="'style-2'"
                    [(state)]="form.allowWhatsAppContact"
            />
            <app-checkbox
                    class="checkbox-get-in-touch"
                    [label]="waysOfContactList[3].label || []"
                    [icon]="waysOfContactList[3].icon || IconsName.Default"
                    [class]="'style-2'"
                    [(state)]="form.allowTelegramContact"
            />
            <app-checkbox
                    class="checkbox-get-in-touch"
                    [label]="waysOfContactList[4].label || []"
                    [icon]="waysOfContactList[4].icon || IconsName.Default"
                    [class]="'style-2'"
                    [(state)]="form.allowViberContact"
            />
        </div>
    </form>
    <app-button
            [buttonTitle]="textElements[2]"
            [buttonClass]="'button-style-1 button-size-l'"
            [buttonJustifyContent]="'start'"
            (click)="sendFeedback()"
    />
    <p class="error-message" *ngIf="errorControlFlag"><app-text-element [inputText]="textElements[6]"/></p>
</div>
