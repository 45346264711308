import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AirportInformationDto, ServicesOfAirport} from "../model/airport";
import {environment} from "../../environments/environment";
import {Page} from "../model/table";

@Injectable({
  providedIn: 'root'
})
export class AirportService {
  constructor(private httpClient: HttpClient) {
  }

  getAirportServices(airportId: number): Observable<AirportInformationDto> {
    return this.httpClient.get<AirportInformationDto>(`${environment.apiUrl}/public/airport-services/${airportId}`);
  }

  getServices(): Observable<Page<ServicesOfAirport>> {
    return this.httpClient.get<Page<ServicesOfAirport>>(`${environment.apiUrl}/service`);
  }

  postAirportServices(item: AirportInformationDto): Observable<AirportInformationDto> {
    return this.httpClient.post<AirportInformationDto>(`${environment.apiUrl}/airport-services`, item);
  }

}

