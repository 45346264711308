<div class="requirements-container">
    <app-background-block class="all-area" [url]="back" [opacity]="0.8" [borderRadius]="0">
        <div class="requirements-text">
            <h1 class="colorWhite largeText"><app-text-element [inputText]="textElements[0]"/></h1>
            <p class="colorGray"><app-text-element [inputText]="textElements[1]"/></p>
            <app-button
                    [buttonTitle]="textElements[2]"
                    [buttonClass]="'button-style-1 button-size-l'"
                    [buttonJustifyContent]="'center'"
            />
        </div>
    </app-background-block>
</div>
