<div class="menu-container">
  <div class="menu-item " *ngFor="let item of menu; index as i">
    <a class="font-0-9 quicksand bold-600"
       [routerLink]="'/' + item.link"
       [routerLinkActiveOptions]="{ exact: true }"
       routerLinkActive="active-tab"
        ><app-text-element [inputText]="item.title"/>
    </a>
  </div>
</div>
<div class="menu-container-mobile" >
    <app-hamburger (click)="changeModalMobile()"/>
</div>
