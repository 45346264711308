<div class="lang-switcher-container">
    <p-dropdown [options]="languages"
                [(ngModel)]="language"
                [filter]="true"
                tooltipPosition="left"
                optionLabel="code"
                appendTo="body"
                id="code"
                name="code"
    >
        <ng-template pTemplate="filter" let-options>
            <div class="filter-container">
                <input type="text"
                       class="search-field"
                       name="newSearch"
                       [placeholder]="languageService.getStringFromTextElement(textElements[0])"
                       [(ngModel)]="search"
                       (keyup)="searchFoo($event)"
                />
                <app-icon [icon]="IconsName.Cross" [width]="24" [height]="24" (click)="clearSearch()"/>
            </div>
        </ng-template>
        <ng-template pTemplate="selectedItem">
            <div *ngIf="language.code" class="selected-container">
                <app-country-flag [countryCode]="language.codeCountry" [width]="30"/>
                <app-text-element [inputText]="language.name" style="margin-left: 0.6rem"/>
            </div>
        </ng-template>
        <ng-template let-lang pTemplate="item">
            <div class="item-container" (click)="select(lang.code)">
                <app-country-flag [countryCode]="lang.codeCountry" [width]="22"/>
                <app-text-element [inputText]="lang.name" style="margin-left: 0.6rem"/>
            </div>
        </ng-template>
    </p-dropdown>
</div>
