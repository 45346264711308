<div class="info-panel-container mh">
  <app-background-block class="all-area" [url]="'/assets/planeBackground2.jpg'" [opacity]="0.9">
    <div class="info-panel">
      <div class="info-panel-item" *ngFor="let info of information">
        <div class="info-item-container">
          <div class="info-item-text">
            <app-icon class="info-item-img" [icon]="info.icon || Icons[0]" [height]="60" [width]="60"/>
            <h5 class="header-text"><app-text-element [inputText]="info.title || []"/></h5>
            <p><app-text-element [inputText]="info.text || []"/></p>
          </div>
        </div>
      </div>
    </div>
  </app-background-block>
</div>

