import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Language } from "../model/language";
import { TextElement} from "../model/textElement";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private languages: Language[] = [
    {
      id: 0,
      code: "en",
      name: [
        {
          languageId: 0,
          text: "en"
        },
        {
          languageId: 1,
          text: "en"
        }
      ],
    },
    {
      id: 1,
      code: "ru",
      name: [
        {
          languageId: 0,
          text: "ru"
        },
        {
          languageId: 1,
          text: "рус"
        }
      ]
    },
    // {
    //   id: 2,
    //   code: "ja",
    //   name: [
    //     {
    //       languageId: 0,
    //       text: "ja"
    //     },
    //   ]
    // },
  ];

  private language: BehaviorSubject<Language> = new BehaviorSubject<Language>(this.languages[0]);
  private browserLocalList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);


  getLanguages() {
    return  this.languages.map((value) => new Language(value));
  }

  getLanguage() {
    return this.language.value;
  }

  setLanguage(value: Language, interfaceCite: boolean) {
    this.language.next(value);
    if (interfaceCite) {
      this.changeOrderLang(value.code || "");
    }
  }

  getBrowserLocalList() {
    return this.browserLocalList.value;
  }

  setBrowserLocalList(value: string[]) {
    this.browserLocalList.next(value);
  }

  getBrowserLocal() {
    const navigatorLanguages = navigator.languages
        .map(v => v.split('-')[0].toLocaleLowerCase()) || [];
    this.setBrowserLocalList(navigatorLanguages);
    let i: number = 0;
    while (i < navigatorLanguages.length) {
      const localLang = this.languages.find(v => v.name[0].text == navigatorLanguages[i]);
      if (localLang) {
        this.setLanguage(localLang, false);
        break;
      }
      i++;
    }
  }

  changeOrderLang(code: string) {
    let languages = this.getBrowserLocalList();
    const lang = languages.find(v => v == code);
    if (lang) {
      languages = languages.filter(v => v != lang);
      languages.unshift(lang);
      this.setBrowserLocalList(languages);
    } else {
      console.log("Lang code is not found");
    }
  }

  getStringFromTextElement(el: TextElement[]) {
    if (el.length > 0) {
      const lang = el.find(value =>
          value.languageId == this.getLanguage().id)
      if (lang && lang.text.length > 0) {
        return lang.text || "";
      } else return el[0].text;
    } else return "";
  }

  getNameByTheLang(list?: any) {
    if (list) {
      let languagesList = this.getBrowserLocalList();
      let i = 0;
      while (i < languagesList.length) {
        const langKey = languagesList[i];
        const key = Object.keys(list).find((key: string) => key == langKey);
        if (key) {
          return list[key];
        }
        i = i + 1;
      }
    }
  }
}
