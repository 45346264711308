import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {IconComponent} from "../icon/icon.component";
import {Icon, IconsName} from "../../../model/icons";
import {TextElementComponent} from "../text-element/text-element.component";

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [CommonModule, IconComponent, TextElementComponent],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {
  @Input() label: TextElement[]  = [];
  @Input() icon: Icon  = IconsName.Default;
  @Input() class: string  = "";
  @Input() state: boolean  = false;
  @Output() stateChange= new EventEmitter<boolean>(this.state);

  changeState(){
    this.state = !this.state;
    this.stateChange.emit(this.state);
  }
  protected readonly IconsName = IconsName;
}
