import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {languagesNames, Names, SelectorNames} from "../../../model/names";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {CountryFlagComponent} from "../country-flag/country-flag.component";
import {InputTextModule} from "primeng/inputtext";
import {TextElementComponent} from "../text-element/text-element.component";
import {LanguageService} from "../../../services/language.service";
import {TextElement} from "../../../model/textElement";
import {IconsName} from "../../../model/icons";
import {IconComponent} from "../icon/icon.component";
import {ButtonComponent} from "../button/button.component";
import {ModalComponent} from "../modal/modal.component";
import {DropdownFilterOptions, DropdownModule} from "primeng/dropdown";
import {NamesItemComponent} from "../names-item/names-item.component";

@Component({
  selector: 'app-selector-names',
  standalone: true,
  imports: [
    NgForOf,
    CountryFlagComponent,
    FormsModule,
    InputTextModule,
    TextElementComponent,
    IconComponent,
    NgIf,
    ReactiveFormsModule,
    ButtonComponent,
    ModalComponent,
    DropdownModule,
    NamesItemComponent
  ],
  templateUrl: './selector-names.component.html',
  styleUrl: './selector-names.component.scss'
})
export class SelectorNamesComponent implements OnChanges{
  @Input() inputNames: Names = new Names();
  @Input() changes: boolean;
  @Output() outputNames = new EventEmitter<Names>();

  constructor(public languageService: LanguageService) {}

  dialog: boolean = false;
  items: SelectorNames[] = [];
  itemsNew: SelectorNames[] = [];
  itemsFiltered: SelectorNames[] = [];
  itemsNewFiltered: SelectorNames[] = [];
  search: string = "";
  form:  FormGroup = new FormGroup({});
  newItem: SelectorNames = new SelectorNames();
  searchNew: string = "";
  errorFlag: boolean = false;

  openDialog() {
    this.itemsNew = languagesNames.filter(f => !this.items.find(i => i.code == f.code));
    this.itemsNewFiltered =  this.itemsNew;
    this.dialog = true;
  }

  hideDialog() {
    this.dialog = false;
    this.clearNewItem();
  }

  addItem() {
    this.errorFlag = false;
    if (!this.items.find(f => f.code == this.newItem.code)) {
      this.inputNames[this.newItem.code] = this.newItem.value;
      this.buildForm();
      this.clearSearch();
      this.hideDialog();
    } else {
      this.errorFlag = true;
    }
  }

  getFormControlsFields() {
    this.items = [];
    this.itemsFiltered = [];
    const formGroupFields: any = {};
    for (const key of Object.keys(this.inputNames)) {
      formGroupFields[key] = new FormControl("");
      const name = languagesNames.find(v => v.code == key);
      if (name) {
        this.items.push({
          code: name.code,
          value: this.inputNames[key],
          name: name.name,
          codeCountry: name.codeCountry,
        })
      }
    }
    return formGroupFields;
  }

  buildForm() {
    const formGroupFields = this.getFormControlsFields();
    this.form = new FormGroup(formGroupFields);
    this.itemsFiltered = this.items;
  }

  updForm(){
    this.outputNames.emit(this.form.value);
  }

  searchFoo(event?: KeyboardEvent){
    if (this.search.length > 0) {
      this.itemsFiltered = this.items.filter(v =>
        v.code.toLowerCase().includes(this.search.toLowerCase()) ||
        v.value.toLowerCase().includes(this.search.toLowerCase()) ||
        v.name[0].text.toLowerCase().includes(this.search.toLowerCase()) ||
        v.name[1].text.toLowerCase().includes(this.search.toLowerCase())
      );
    } else {
      this.itemsFiltered = this.items;
    }
  }

  searchFooNew(event?: KeyboardEvent) {
    if (this.searchNew.length > 0) {
      this.itemsNewFiltered = this.itemsNew.filter(v =>
          v.code.toLowerCase().includes(this.searchNew.toLowerCase()) ||
          v.value.toLowerCase().includes(this.searchNew.toLowerCase()) ||
          v.name[0].text.toLowerCase().includes(this.searchNew.toLowerCase()) ||
          v.name[1].text.toLowerCase().includes(this.searchNew.toLowerCase())
      );
    } else {
      this.itemsNewFiltered = this.itemsNew;
    }
  }
  clearNewItem() {
    this.searchNew = "";
    this.newItem = new SelectorNames();
    this.searchFooNew();
  }

  clearNewSearch() {
    this.searchNew = "";
    this.searchFooNew();
  }

  customFilterFunction(event: KeyboardEvent, options: DropdownFilterOptions) {
      // @ts-ignore
      options.filter(event);
  }

  clearSearch(){
    this.search = "";
    this.searchFoo();
  }


  ngOnChanges(changes: SimpleChanges) {
    this.buildForm();
    this.clearSearch();
  }

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Search",
      },
      {
        languageId: 1,
        text: "Поиск",
      },
    ],
    [
      {
        languageId: 0,
        text: "Add",
      },
      {
        languageId: 1,
        text: "Добавить",
      },
    ],
    [
      {
        languageId: 0,
        text: "New name",
      },
      {
        languageId: 1,
        text: "Новое название",
      },
    ],
    [
      {
        languageId: 0,
        text: "Create",
      },
      {
        languageId: 1,
        text: "Создать",
      },
    ],
    [
      {
        languageId: 0,
        text: "Cancel",
      },
      {
        languageId: 1,
        text: "Отменить",
      },
    ],
    [
      {
        languageId: 0,
        text: "Language",
      },
      {
        languageId: 1,
        text: "Язык",
      },
    ],
    [
      {
        languageId: 0,
        text: "Value",
      },
      {
        languageId: 1,
        text: "Значение",
      },
    ],
    [
      {
        languageId: 0,
        text: "Error. The name of this language already has been",
      },
      {
        languageId: 1,
        text: "Ошибка. Название на этом языке уже существует",
      },
    ],
  ]
  protected readonly IconsName = IconsName;
  protected readonly languagesNames = languagesNames;
}
