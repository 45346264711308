<div class="row mh">
    <div class="col-12 col-md-4">
        <div class="our-benefit-info">
            <h4 class="colorYellow subtitle"><app-text-element [inputText]="textElements[0]"/></h4>
            <h1 class="largeText"><app-text-element [inputText]="textElements[1]"/></h1>
            <p><app-text-element [inputText]="textElements[2]"/></p>
        </div>
    </div>
    <div class="col-12 col-md-8">
        <div class="our-benefit-cell">
            <app-cell-gallery/>
        </div>
    </div>
</div>

