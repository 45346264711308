<app-background-block class="background all-area" [url]="backUrl" [opacity]="0.7" [borderRadius]="0">
    <div class="testimonials-container">
        <div class="col-12 col-md-8 mh">
            <div class="testimonials-quote-container">
                <div *ngFor="let quote of quotes" class="testimonials-quote mb-2">
                    <app-quote [text]="quote.text" [author]="quote.author"/>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 mh">
            <div class="testimonials-text-block-container">
                <div class="testimonials-text-block">
                    <h1 class="colorYellow subtitle"><app-text-element [inputText]="textElements[0]"/></h1>
                    <h1 class="largeText colorWhite"><app-text-element [inputText]="textElements[1]"/></h1>
                    <p class="colorYellow"><app-text-element [inputText]="textElements[2]"/></p>
                    <app-button
                            [buttonTitle]="textElements[3]"
                            [buttonClass]="'button-style-1 button-size-l'"
                            [buttonJustifyContent]="'start'"
                    />
                </div>
            </div>
        </div>
    </div>
</app-background-block>
