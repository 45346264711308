import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-picture-plane',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './picture-plane.component.html',
  styleUrl: './picture-plane.component.scss'
})
export class PicturePlaneComponent {

}
