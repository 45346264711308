import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {Login} from "../model/login";
import {Router} from "@angular/router";
import {TokenStorageService} from "./token.storage.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
const headers = new HttpHeaders({
    'Content-Type': 'application/json'
});
@Injectable()
export class LoginService {
    private subject = new Subject<Login | undefined>();
    constructor(
        private httpClient: HttpClient,
        private tokenStorageService: TokenStorageService,
        private router: Router) {
    }

    login(login: string, password: string): Observable<Login> {
        return this.httpClient.post<Login>(environment.apiUrl + '/authentication/login',
            {login: login, password: password}, {headers});
    }



    get(): Observable<Login | undefined> {
        return this.subject.asObservable();
    }

    update(): void {
        this.subject.next(this.tokenStorageService?.getLoginInfo());
    }

    logout(): void {
        this.tokenStorageService.logout();
        this.update();
        setTimeout(() =>
            this.router.navigate(['/']).then(() => {
                window.location.reload();
            }), 500);
    }
}
