export class City {
    slug: string;
    city_code: string;
    un_locode: string;
    name: string;
    lat: number;
    lng: number;
    alt: number;
    country_code: string;
    timezone: string;
    wikipedia: string;
    population: number;
    popularity: number;
    distance: number;
    names: object;
    
    constructor(value?: City) {
        this.slug = value?.slug ?? "";
        this.city_code = value?.city_code ?? "";
        this.un_locode = value?.un_locode ?? "";
        this.name = value?.name ?? "";
        this.lat = value?.lat ?? 0;
        this.alt = value?.alt ?? 0;
        this.country_code = value?.country_code ?? "";
        this.timezone = value?.timezone ?? "";
        this.wikipedia = value?.wikipedia ?? "";
        this.population = value?.population ?? 0;
        this.popularity = value?.popularity ?? 0;
        this.distance = value?.distance ?? 0;
        this.names = value?.names ?? {};
    }
}
