import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Airport} from "../model/airport";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable()
export class SearchAirportsService {
  constructor(private httpClient: HttpClient) {
  }
  getAirportList(search: string): Observable<Airport[]>  {
    return this.httpClient.get<Airport[]>(`${environment.apiUrl}/public/airport/search/${search}`);
  }
  getAirport(code: string): Observable<Airport>  {
    return this.httpClient.get<Airport>(`${environment.apiUrl}/public/airport/${code}`);
  }
}
