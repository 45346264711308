import {TextElement} from "./textElement";

export class Names {
    [key: string]: string;
}


export class SelectorNames {
    code: string;
    codeCountry: string;
    name: TextElement[];
    value: string;
    constructor(value?: SelectorNames) {
        this.code = value?.code || "";
        this.codeCountry = value?.codeCountry || "";
        this.name =  value?.name || [];
        this.value =  value?.value || "";
    }
}

export const languagesNames: SelectorNames[] = [
    {
        code: "af",
        codeCountry: "za",
        name: [
            {
                languageId: 0,
                text: "afrikaans",
            },
            {
                languageId: 1,
                text: "африкаанс"
            },
        ], 
        value: ""
    },
    {
        code: "ak",
        codeCountry: "gh",
        name: [
            {
                languageId: 0,
                text: "akan",
            },
            {
                languageId: 1,
                text: "акан"
            },
        ], 
        value: ""
    },
    {
        code: "am",
        codeCountry: "et",
        name: [
            {
                languageId: 0,
                text: "amharic",
            },
            {
                languageId: 1,
                text: "амхарский"
            },
        ], 
        value: ""
    },
    {
        code: "an",
        codeCountry: "an",
        name: [
            {
                languageId: 0,
                text: "aragones",
            },
            {
                languageId: 1,
                text: "арагонский"
            },
        ], 
        value: ""
    },
    {
        code: "ar",
        codeCountry: "ae",
        name: [
            {
                languageId: 0,
                text: "arabic",
            },
            {
                languageId: 1,
                text: "арабский"
            },
        ], 
        value: ""
    },
    {
        code: "as",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "assamese",
            },
            {
                languageId: 1,
                text: "ассамский"
            },
        ], 
        value: ""
    },
    {
        code: "az",
        codeCountry: "az",
        name: [
            {
                languageId: 0,
                text: "azerbaijani",
            },
            {
                languageId: 1,
                text: "азербайджанский"
            },
        ], 
        value: ""
    },
    {
        code: "be",
        codeCountry: "by",
        name: [
            {
                languageId: 0,
                text: "belarusian",
            },
            {
                languageId: 1,
                text: "белорусский"
            },
        ], 
        value: ""
    },
    {
        code: "bg",
        codeCountry: "bg",
        name: [
            {
                languageId: 0,
                text: "bulgarian",
            },
            {
                languageId: 1,
                text: "болгарский"
            },
        ], 
        value: ""
    },
    {
        code: "bm",
        codeCountry: "bf",
        name: [
            {
                languageId: 0,
                text: "bambara",
            },
            {
                languageId: 1,
                text: "бамбара"
            },
        ], 
        value: ""
    },
    {
        code: "bn",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "bengali",
            },
            {
                languageId: 1,
                text: "бенгальский"
            },
        ], 
        value: ""
    },
    {
        code: "bo",
        codeCountry: "cn",
        name: [
            {
                languageId: 0,
                text: "tibetic",
            },
            {
                languageId: 1,
                text: "тибетский"
            },
        ], 
        value: ""
    },
    {
        code: "br",
        codeCountry: "fr",
        name: [
            {
                languageId: 0,
                text: "breton",
            },
            {
                languageId: 1,
                text: "бретонский"
            },
        ], 
        value: ""
    },
    {
        code: "bs",
        codeCountry: "ba",
        name: [
            {
                languageId: 0,
                text: "bosnian",
            },
            {
                languageId: 1,
                text: "боснийский"
            },
        ], 
        value: ""
    },
    {
        code: "ca",
        codeCountry: "es",
        name: [
            {
                languageId: 0,
                text: "catalan",
            },
            {
                languageId: 1,
                text: "каталанский"
            },
        ], 
        value: ""
    },
    {
        code: "ce",
        codeCountry: "ru",
        name: [
            {
                languageId: 0,
                text: "chechen",
            },
            {
                languageId: 1,
                text: "чеченский"
            },
        ], 
        value: ""
    },
    {
        code: "cs",
        codeCountry: "cz",
        name: [
            {
                languageId: 0,
                text: "czech",
            },
            {
                languageId: 1,
                text: "чешский"
            },
        ], 
        value: ""
    },
    {
        code: "cy",
        codeCountry: "gb",
        name: [
            {
                languageId: 0,
                text: "welsh",
            },
            {
                languageId: 1,
                text: "валлийский"
            },
        ], 
        value: ""
    },
    {
        code: "da",
        codeCountry: "dk",
        name: [
            {
                languageId: 0,
                text: "danish",
            },
            {
                languageId: 1,
                text: "датский"
            },
        ], 
        value: ""
    },
    {
        code: "de",
        codeCountry: "de",
        name: [
            {
                languageId: 0,
                text: "german",
            },
            {
                languageId: 1,
                text: "немецкий"
            },
        ], 
        value: ""
    },
    {
        code: "dz",
        codeCountry: "bt",
        name: [
            {
                languageId: 0,
                text: "dzongkha",
            },
            {
                languageId: 1,
                text: "дзонг-кэ"
            },
        ], 
        value: ""
    },
    {
        code: "ee",
        codeCountry: "bj",
        name: [
            {
                languageId: 0,
                text: "ewe",
            },
            {
                languageId: 1,
                text: "эве"
            },
        ], 
        value: ""
    },
    {
        code: "el",
        codeCountry: "gr",
        name: [
            {
                languageId: 0,
                text: "greek",
            },
            {
                languageId: 1,
                text: "греческий"
            },
        ], 
        value: ""
    },
    {
        code: "en",
        codeCountry: "gb",
        name: [
            {
                languageId: 0,
                text: "english",
            },
            {
                languageId: 1,
                text: "английский"
            },
        ], 
        value: ""
    },
    {
        code: "eo",
        codeCountry: "",
        name: [
            {
                languageId: 0,
                text: "esperanto",
            },
            {
                languageId: 1,
                text: "эсперанто"
            },
        ], 
        value: ""
    },
    {
        code: "es",
        codeCountry: "es",
        name: [
            {
                languageId: 0,
                text: "spanish",
            },
            {
                languageId: 1,
                text: "испанский"
            },
        ], 
        value: ""
    },
    {
        code: "et",
        codeCountry: "ee",
        name: [
            {
                languageId: 0,
                text: "estonian",
            },
            {
                languageId: 1,
                text: "эстонский"
            },
        ], 
        value: ""
    },
    {
        code: "eu",
        codeCountry: "es",
        name: [
            {
                languageId: 0,
                text: "basque",
            },
            {
                languageId: 1,
                text: "баскский"
            },
        ], 
        value: ""
    },
    {
        code: "fa",
        codeCountry: "af",
        name: [
            {
                languageId: 0,
                text: "persian",
            },
            {
                languageId: 1,
                text: "персидский"
            },
        ], 
        value: ""
    },
    {
        code: "ff",
        codeCountry: "gm",
        name: [
            {
                languageId: 0,
                text: "fula",
            },
            {
                languageId: 1,
                text: "фулах"
            },
        ], 
        value: ""
    },
    {
        code: "fi",
        codeCountry: "fi",
        name: [
            {
                languageId: 0,
                text: "finnish",
            },
            {
                languageId: 1,
                text: "финский"
            },
        ], 
        value: ""
    },
    {
        code: "fo",
        codeCountry: "dk",
        name: [
            {
                languageId: 0,
                text: "faroese",
            },
            {
                languageId: 1,
                text: "фарерский"
            },
        ], 
        value: ""
    },
    {
        code: "fr",
        codeCountry: "fr",
        name: [
            {
                languageId: 0,
                text: "french",
            },
            {
                languageId: 1,
                text: "французский"
            },
        ], 
        value: ""
    },
    {
        code: "fy",
        codeCountry: "nl",
        name: [
            {
                languageId: 0,
                text: "frisian",
            },
            {
                languageId: 1,
                text: "фризский"
            },
        ], 
        value: ""
    },
    {
        code: "ga",
        codeCountry: "ie",
        name: [
            {
                languageId: 0,
                text: "irish",
            },
            {
                languageId: 1,
                text: "ирландский"
            },
        ], 
        value: ""
    },
    {
        code: "gd",
        codeCountry: "gb",
        name: [
            {
                languageId: 0,
                text: "gaelic",
            },
            {
                languageId: 1,
                text: "гэльский"
            },
        ], 
        value: ""
    },
    {
        code: "gl",
        codeCountry: "es",
        name: [
            {
                languageId: 0,
                text: "galician",
            },
            {
                languageId: 1,
                text: "галисийский"
            },
        ], 
        value: ""
    },
    {
        code: "gu",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "gujarati",
            },
            {
                languageId: 1,
                text: "гуджарати"
            },
        ], 
        value: ""
    },
    {
        code: "ha",
        codeCountry: "ne",
        name: [
            {
                languageId: 0,
                text: "hausa",
            },
            {
                languageId: 1,
                text: "хауса"
            },
        ], 
        value: ""
    },
    {
        code: "he",
        codeCountry: "il",
        name: [
            {
                languageId: 0,
                text: "hebrew",
            },
            {
                languageId: 1,
                text: "иврит"
            },
        ], 
        value: ""
    },
    {
        code: "hi",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "hindi",
            },
            {
                languageId: 1,
                text: "хинди"
            },
        ], 
        value: ""
    },
    {
        code: "hr",
        codeCountry: "hr",
        name: [
            {
                languageId: 0,
                text: "croatian",
            },
            {
                languageId: 1,
                text: "хорватский"
            },
        ], 
        value: ""
    },
    {
        code: "hu",
        codeCountry: "hu",
        name: [
            {
                languageId: 0,
                text: "hungarian",
            },
            {
                languageId: 1,
                text: "венгерский"
            },
        ], 
        value: ""
    },
    {
        code: "hy",
        codeCountry: "am",
        name: [
            {
                languageId: 0,
                text: "armenian",
            },
            {
                languageId: 1,
                text: "армянский"
            },
        ], 
        value: ""
    },
    {
        code: "ia",
        codeCountry: "",
        name: [
            {
                languageId: 0,
                text: "interlingua",
            },
            {
                languageId: 1,
                text: "интерлингва"
            },
        ], 
        value: ""
    },
    {
        code: "id",
        codeCountry: "id",
        name: [
            {
                languageId: 0,
                text: "indonesian",
            },
            {
                languageId: 1,
                text: "индонезийский"
            },
        ], 
        value: ""
    },
    {
        code: "ig",
        codeCountry: "ng",
        name: [
            {
                languageId: 0,
                text: "igbo",
            },
            {
                languageId: 1,
                text: "игбо"
            },
        ], 
        value: ""
    },
    {
        code: "io",
        codeCountry: "",
        name: [
            {
                languageId: 0,
                text: "ido",
            },
            {
                languageId: 1,
                text: "идо"
            },
        ], 
        value: ""
    },
    {
        code: "is",
        codeCountry: "is",
        name: [
            {
                languageId: 0,
                text: "icelandic",
            },
            {
                languageId: 1,
                text: "исландский"
            },
        ], 
        value: ""
    },
    {
        code: "it",
        codeCountry: "it",
        name: [
            {
                languageId: 0,
                text: "italian",
            },
            {
                languageId: 1,
                text: "итальянский"
            },
        ], 
        value: ""
    },
    {
        code: "ja",
        codeCountry: "jp",
        name: [
            {
                languageId: 0,
                text: "japanese",
            },
            {
                languageId: 1,
                text: "японский"
            },
        ], 
        value: ""
    },
    {
        code: "jv",
        codeCountry: "id",
        name: [
            {
                languageId: 0,
                text: "javanese",
            },
            {
                languageId: 1,
                text: "яванский"
            },
        ], 
        value: ""
    },
    {
        code: "ka",
        codeCountry: "ge",
        name: [
            {
                languageId: 0,
                text: "georgian",
            },
            {
                languageId: 1,
                text: "грузинский"
            },
        ], 
        value: ""
    },
    {
        code: "ki",
        codeCountry: "ke",
        name: [
            {
                languageId: 0,
                text: "kikuyu",
            },
            {
                languageId: 1,
                text: "кикуйю"
            },
        ], 
        value: ""
    },
    {
        code: "kk",
        codeCountry: "kz",
        name: [
            {
                languageId: 0,
                text: "kazakh",
            },
            {
                languageId: 1,
                text: "казахский"
            },
        ], 
        value: ""
    },
    {
        code: "kl",
        codeCountry: "gl",
        name: [
            {
                languageId: 0,
                text: "greenlandic",
            },
            {
                languageId: 1,
                text: "гренландский"
            },
        ], 
        value: ""
    },
    {
        code: "km",
        codeCountry: "vn",
        name: [
            {
                languageId: 0,
                text: "khmer",
            },
            {
                languageId: 1,
                text: "кхмерский"
            },
        ], 
        value: ""
    },
    {
        code: "kn",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "kannada",
            },
            {
                languageId: 1,
                text: "каннада"
            },
        ], 
        value: ""
    },
    {
        code: "ko",
        codeCountry: "kr",
        name: [
            {
                languageId: 0,
                text: "korean",
            },
            {
                languageId: 1,
                text: "корейский"
            },
        ], 
        value: ""
    },
    {
        code: "ks",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "kashmiri",
            },
            {
                languageId: 1,
                text: "кашмирский"
            },
        ], 
        value: ""
    },
    {
        code: "ku",
        codeCountry: "iq",
        name: [
            {
                languageId: 0,
                text: "kurdish",
            },
            {
                languageId: 1,
                text: "курдский"
            },
        ], 
        value: ""
    },
    {
        code: "kw",
        codeCountry: "gb",
        name: [
            {
                languageId: 0,
                text: "cornish",
            },
            {
                languageId: 1,
                text: "корнский"
            },
        ], 
        value: ""
    },
    {
        code: "ky",
        codeCountry: "kg",
        name: [
            {
                languageId: 0,
                text: "kyrgyz",
            },
            {
                languageId: 1,
                text: "киргизский"
            },
        ], 
        value: ""
    },
    {
        code: "la",
        codeCountry: "",
        name: [
            {
                languageId: 0,
                text: "latin",
            },
            {
                languageId: 1,
                text: "латинский"
            },
        ], 
        value: ""
    },
    {
        code: "lb",
        codeCountry: "lu",
        name: [
            {
                languageId: 0,
                text: "luxembourgish",
            },
            {
                languageId: 1,
                text: "люксембургский"
            },
        ], 
        value: ""
    },
    {
        code: "lg",
        codeCountry: "ug",
        name: [
            {
                languageId: 0,
                text: "luganda",
            },
            {
                languageId: 1,
                text: "ганда"
            },
        ], 
        value: ""
    },
    {
        code: "li",
        codeCountry: "be",
        name: [
            {
                languageId: 0,
                text: "limburgish",
            },
            {
                languageId: 1,
                text: "лимбургский"
            },
        ], 
        value: ""
    },
    {
        code: "ln",
        codeCountry: "cg",
        name: [
            {
                languageId: 0,
                text: "lingala",
            },
            {
                languageId: 1,
                text: "лингала"
            },
        ], 
        value: ""
    },
    {
        code: "lo",
        codeCountry: "la",
        name: [
            {
                languageId: 0,
                text: "lao",
            },
            {
                languageId: 1,
                text: "лаосский"
            },
        ], 
        value: ""
    },
    {
        code: "lt",
        codeCountry: "lt",
        name: [
            {
                languageId: 0,
                text: "lithuanian",
            },
            {
                languageId: 1,
                text: "литовский"
            },
        ], 
        value: ""
    },
    {
        code: "lu",
        codeCountry: "cg",
        name: [
            {
                languageId: 0,
                text: "luba-katanga",
            },
            {
                languageId: 1,
                text: "луба-катанга"
            },
        ], 
        value: ""
    },
    {
        code: "lv",
        codeCountry: "lv",
        name: [
            {
                languageId: 0,
                text: "latvian",
            },
            {
                languageId: 1,
                text: "латышский"
            },
        ], 
        value: ""
    },
    {
        code: "mg",
        codeCountry: "mg",
        name: [
            {
                languageId: 0,
                text: "malagasy",
            },
            {
                languageId: 1,
                text: "малагасийский"
            },
        ], 
        value: ""
    },
    {
        code: "mk",
        codeCountry: "mk",
        name: [
            {
                languageId: 0,
                text: "macedonian",
            },
            {
                languageId: 1,
                text: "македонский"
            },
        ], 
        value: ""
    },
    {
        code: "ml",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "malayalam",
            },
            {
                languageId: 1,
                text: "малаялам"
            },
        ], 
        value: ""
    },
    {
        code: "mn",
        codeCountry: "mn",
        name: [
            {
                languageId: 0,
                text: "mongolian",
            },
            {
                languageId: 1,
                text: "монгольский"
            },
        ], 
        value: ""
    },
    {
        code: "mr",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "marathi",
            },
            {
                languageId: 1,
                text: "маратхи"
            },
        ], 
        value: ""
    },
    {
        code: "ms",
        codeCountry: "bn",
        name: [
            {
                languageId: 0,
                text: "malay",
            },
            {
                languageId: 1,
                text: "малайский"
            },
        ], 
        value: ""
    },
    {
        code: "mt",
        codeCountry: "mt",
        name: [
            {
                languageId: 0,
                text: "maltese",
            },
            {
                languageId: 1,
                text: "мальтийский"
            },
        ], 
        value: ""
    },
    {
        code: "my",
        codeCountry: "mm",
        name: [
            {
                languageId: 0,
                text: "burmese",
            },
            {
                languageId: 1,
                text: "бирманский"
            },
        ], 
        value: ""
    },
    {
        code: "na",
        codeCountry: "nr",
        name: [
            {
                languageId: 0,
                text: "nauruan",
            },
            {
                languageId: 1,
                text: "науруанский"
            },
        ], 
        value: ""
    },
    {
        code: "nb",
        codeCountry: "no",
        name: [
            {
                languageId: 0,
                text: "bokmal",
            },
            {
                languageId: 1,
                text: "букмол"
            },
        ], 
        value: ""
    },
    {
        code: "nd",
        codeCountry: "zw",
        name: [
            {
                languageId: 0,
                text: "ndebele",
            },
            {
                languageId: 1,
                text: "ндебеле"
            },
        ], 
        value: ""
    },
    {
        code: "ne",
        codeCountry: "np",
        name: [
            {
                languageId: 0,
                text: "nepali",
            },
            {
                languageId: 1,
                text: "непальский"
            },
        ], 
        value: ""
    },
    {
        code: "nl",
        codeCountry: "nl",
        name: [
            {
                languageId: 0,
                text: "dutch",
            },
            {
                languageId: 1,
                text: "нидерландский"
            },
        ], 
        value: ""
    },
    {
        code: "nn",
        codeCountry: "no",
        name: [
            {
                languageId: 0,
                text: "nynorsk",
            },
            {
                languageId: 1,
                text: "новонорвежский"
            },
        ], 
        value: ""
    },
    {
        code: "no",
        codeCountry: "no",
        name: [
            {
                languageId: 0,
                text: "norwegian",
            },
            {
                languageId: 1,
                text: "норвежский"
            },
        ], 
        value: ""
    },
    {
        code: "oc",
        codeCountry: "fr",
        name: [
            {
                languageId: 0,
                text: "occitan",
            },
            {
                languageId: 1,
                text: "окситанский"
            },
        ], 
        value: ""
    },
    {
        code: "om",
        codeCountry: "et",
        name: [
            {
                languageId: 0,
                text: "oromo",
            },
            {
                languageId: 1,
                text: "оромо"
            },
        ], 
        value: ""
    },
    {
        code: "or",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "orya",
            },
            {
                languageId: 1,
                text: "ория"
            },
        ], 
        value: ""
    },
    {
        code: "pa",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "punjabi",
            },
            {
                languageId: 1,
                text: "панджаби"
            },
        ], 
        value: ""
    },
    {
        code: "pl",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "pali",
            },
            {
                languageId: 1,
                text: "пали"
            },
        ], 
        value: ""
    },
    {
        code: "ps",
        codeCountry: "af",
        name: [
            {
                languageId: 0,
                text: "pashto",
            },
            {
                languageId: 1,
                text: "пушту"
            },
        ], 
        value: ""
    },
    {
        code: "pt",
        codeCountry: "pt",
        name: [
            {
                languageId: 0,
                text: "portuguese",
            },
            {
                languageId: 1,
                text: "португальский"
            },
        ], 
        value: ""
    },
    {
        code: "qu",
        codeCountry: "ar",
        name: [
            {
                languageId: 0,
                text: "quechuan",
            },
            {
                languageId: 1,
                text: "кечуа"
            },
        ], 
        value: ""
    },
    {
        code: "rm",
        codeCountry: "ch",
        name: [
            {
                languageId: 0,
                text: "romansh",
            },
            {
                languageId: 1,
                text: "романский"
            },
        ], 
        value: ""
    },
    {
        code: "rn",
        codeCountry: "cd",
        name: [
            {
                languageId: 0,
                text: "kirundi",
            },
            {
                languageId: 1,
                text: "рунди"
            },
        ], 
        value: ""
    },
    {
        code: "ro",
        codeCountry: "ro",
        name: [
            {
                languageId: 0,
                text: "romanian",
            },
            {
                languageId: 1,
                text: "румынский"
            },
        ], 
        value: ""
    },
    {
        code: "ru",
        codeCountry: "ru",
        name: [
            {
                languageId: 0,
                text: "russian",
            },
            {
                languageId: 1,
                text: "русский"
            },
        ], 
        value: ""
    },
    {
        code: "sa",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "sanskrit",
            },
            {
                languageId: 1,
                text: "санскрит"
            },
        ], 
        value: ""
    },
    {
        code: "sd",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "sindhi",
            },
            {
                languageId: 1,
                text: "синдхи"
            },
        ], 
        value: ""
    },
    {
        code: "se",
        codeCountry: "no",
        name: [
            {
                languageId: 0,
                text: "north sami",
            },
            {
                languageId: 1,
                text: "северосаамский"
            },
        ], 
        value: ""
    },
    {
        code: "sg",
        codeCountry: "cf",
        name: [
            {
                languageId: 0,
                text: "sango",
            },
            {
                languageId: 1,
                text: "санго"
            },
        ], 
        value: ""
    },
    {
        code: "si",
        codeCountry: "lk",
        name: [
            {
                languageId: 0,
                text: "singhalese",
            },
            {
                languageId: 1,
                text: "сингальский"
            },
        ], 
        value: ""
    },
    {
        code: "sk",
        codeCountry: "sk",
        name: [
            {
                languageId: 0,
                text: "slovak",
            },
            {
                languageId: 1,
                text: "словацкий"
            },
        ], 
        value: ""
    },
    {
        code: "sl",
        codeCountry: "si",
        name: [
            {
                languageId: 0,
                text: "slovene",
            },
            {
                languageId: 1,
                text: "словенский"
            },
        ], 
        value: ""
    },
    {
        code: "sn",
        codeCountry: "zw",
        name: [
            {
                languageId: 0,
                text: "shona",
            },
            {
                languageId: 1,
                text: "шона"
            },
        ], 
        value: ""
    },
    {
        code: "so",
        codeCountry: "so",
        name: [
            {
                languageId: 0,
                text: "somali",
            },
            {
                languageId: 1,
                text: "cомалийский"
            },
        ], 
        value: ""
    },
    {
        code: "sq",
        codeCountry: "al",
        name: [
            {
                languageId: 0,
                text: "albanian",
            },
            {
                languageId: 1,
                text: "албанский"
            },
        ], 
        value: ""
    },
    {
        code: "sr",
        codeCountry: "rs",
        name: [
            {
                languageId: 0,
                text: "serbian",
            },
            {
                languageId: 1,
                text: "сербский"
            },
        ], 
        value: ""
    },
    {
        code: "sv",
        codeCountry: "se",
        name: [
            {
                languageId: 0,
                text: "swedish",
            },
            {
                languageId: 1,
                text: "шведский"
            },
        ], 
        value: ""
    },
    {
        code: "sw",
        codeCountry: "ke",
        name: [
            {
                languageId: 0,
                text: "swahili",
            },
            {
                languageId: 1,
                text: "суахили"
            },
        ], 
        value: ""
    },
    {
        code: "ta",
        codeCountry: "lk",
        name: [
            {
                languageId: 0,
                text: "tamil",
            },
            {
                languageId: 1,
                text: "тамильский"
            },
        ], 
        value: ""
    },
    {
        code: "te",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "telugu",
            },
            {
                languageId: 1,
                text: "телугу"
            },
        ], 
        value: ""
    },
    {
        code: "tg",
        codeCountry: "tj",
        name: [
            {
                languageId: 0,
                text: "tajik",
            },
            {
                languageId: 1,
                text: "таджикский"
            },
        ], 
        value: ""
    },
    {
        code: "th",
        codeCountry: "th",
        name: [
            {
                languageId: 0,
                text: "thai",
            },
            {
                languageId: 1,
                text: "тайский"
            },
        ], 
        value: ""
    },
    {
        code: "ti",
        codeCountry: "er",
        name: [
            {
                languageId: 0,
                text: "tigrinya",
            },
            {
                languageId: 1,
                text: "тигринья"
            },
        ], 
        value: ""
    },
    {
        code: "tk",
        codeCountry: "tm",
        name: [
            {
                languageId: 0,
                text: "turkmen",
            },
            {
                languageId: 1,
                text: "туркменский"
            },
        ], 
        value: ""
    },
    {
        code: "tl",
        codeCountry: "ph",
        name: [
            {
                languageId: 0,
                text: "tagalog",
            },
            {
                languageId: 1,
                text: "тагальский"
            },
        ], 
        value: ""
    },
    {
        code: "to",
        codeCountry: "to",
        name: [
            {
                languageId: 0,
                text: "tongan",
            },
            {
                languageId: 1,
                text: "тонганский"
            },
        ], 
        value: ""
    },
    {
        code: "tr",
        codeCountry: "tr",
        name: [
            {
                languageId: 0,
                text: "turkish",
            },
            {
                languageId: 1,
                text: "турецкий"
            },
        ], 
        value: ""
    },
    {
        code: "tt",
        codeCountry: "ru",
        name: [
            {
                languageId: 0,
                text: "tatar",
            },
            {
                languageId: 1,
                text: "татарский"
            },
        ], 
        value: ""
    },
    {
        code: "ug",
        codeCountry: "kz",
        name: [
            {
                languageId: 0,
                text: "uyghur",
            },
            {
                languageId: 1,
                text: "уйгурский"
            },
        ], 
        value: ""
    },
    {
        code: "uk",
        codeCountry: "ua",
        name: [
            {
                languageId: 0,
                text: "ukrainian",
            },
            {
                languageId: 1,
                text: "украинский"
            },
        ], 
        value: ""
    },
    {
        code: "ur",
        codeCountry: "in",
        name: [
            {
                languageId: 0,
                text: "urdu",
            },
            {
                languageId: 1,
                text: "урду"
            },
        ], 
        value: ""
    },
    {
        code: "uz",
        codeCountry: "uz",
        name: [
            {
                languageId: 0,
                text: "uzbek",
            },
            {
                languageId: 1,
                text: "узбекский"
            },
        ], 
        value: ""
    },
    {
        code: "vi",
        codeCountry: "vn",
        name: [
            {
                languageId: 0,
                text: "vietnamese",
            },
            {
                languageId: 1,
                text: "вьетнамский"
            },
        ], 
        value: ""
    },
    {
        code: "vo",
        codeCountry: "",
        name: [
            {
                languageId: 0,
                text: "volapuk",
            },
            {
                languageId: 1,
                text: "волапюк"
            },
        ], 
        value: ""
    },
    {
        code: "wo",
        codeCountry: "gm",
        name: [
            {
                languageId: 0,
                text: "wolof",
            },
            {
                languageId: 1,
                text: "волоф"
            },
        ], 
        value: ""
    },
    {
        code: "yi",
        codeCountry: "us",
        name: [
            {
                languageId: 0,
                text: "yiddish",
            },
            {
                languageId: 1,
                text: "идиш"
            },
        ], 
        value: ""
    },
    {
        code: "yo",
        codeCountry: "bj",
        name: [
            {
                languageId: 0,
                text: "yoruba",
            },
            {
                languageId: 1,
                text: "йоруба"
            },
        ], 
        value: ""
    },
    {
        code: "zh",
        codeCountry: "cn",
        name: [
            {
                languageId: 0,
                text: "chinese",
            },
            {
                languageId: 1,
                text: "китайский"
            },
        ], 
        value: ""
    },
    {
        code: "zu",
        codeCountry: "za",
        name: [
            {
                languageId: 0,
                text: "zulu",
            },
            {
                languageId: 1,
                text: "зулу"
            },
        ], 
        value: ""
    },
];
