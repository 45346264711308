import { ApplicationConfig } from '@angular/core';
import {provideRouter, RouterLinkActive} from '@angular/router';
import { routes } from './app.routes';
import { LanguageService } from "./services/language.service";
import { Language } from "./model/language";
import { SearchAirportsService } from "./services/search.airports.service";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { CountryService } from "./services/country.service";
import {IMAGE_CONFIG} from "@angular/common";
import {LoginService} from "./services/login.service";
import {authInterceptor} from "./interceptors/auth.interceptor";


export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    LanguageService,
    Language,
    SearchAirportsService,
    CountryService,
    LoginService,
    RouterLinkActive,
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      }
    },
  ],

};
