import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SocialMediaComponent} from "../../elements/social-media/social-media.component";
import {PicturePlaneComponent} from "./picture-plane/picture-plane.component";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {TextElement} from "../../../model/textElement";
import {ButtonComponent} from "../../elements/button/button.component";
import {BookingService} from "../../../services/booking.service";

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [CommonModule, SocialMediaComponent, PicturePlaneComponent, TextElementComponent, ButtonComponent],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent {

  constructor(public bookingService: BookingService ) {}


  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Welcome "
      },
      {
        languageId: 1,
        text: " "
      },
    ],
    [
      {
        languageId: 0,
        text: "to"
      },
      {
        languageId: 1,
        text: "Добро пожаловать"
      },
    ],
    [
      {
        languageId: 0,
        text: "Worldwide"
      },
      {
        languageId: 1,
        text: ""
      },
    ],
    [
      {
        languageId: 0,
        text: "Service for VIP"
      },
      {
        languageId: 1,
        text: "VIP Сервис"
      },
    ],
    [
      {
        languageId: 0,
        text: "in Airports"
      },
      {
        languageId: 1,
        text: "в Аэропортах мира"
      },
    ],
    [
      {
        languageId: 0,
        text: "We firmly believe that each and every client deserves exceptional best treatment, which is why our Experienced team go above and beyond."
      },
      {
        languageId: 1,
        text: "Мы твердо верим, что каждый клиент заслуживает исключительно лучшего отношения, поэтому наша опытная команда делает все возможное."
      },
    ],
    [
      {
        languageId: 0,
        text: "BOOK NOW"
      },
      {
        languageId: 1,
        text: "ЗАБРОНИРУЙТЕ СЕЙЧАС"
      },
    ],
    [
      {
        languageId: 0,
        text: ""
      },
      {
        languageId: 1,
        text: ""
      },
    ],
  ];
}
