<div class="charter-container">
    <div class="charter-text">
        <h5 class="colorYellow subtitle bold-600"><app-text-element [inputText]="textElements[0]"/></h5>
        <h1 class="largeText"><app-text-element [inputText]="textElements[1]"/></h1>
        <p class="colorGrayLight"><app-text-element [inputText]="textElements[2]"/></p>
    </div>

    <div class="charter-gallery mb-5">
        <div class="charter-cell" *ngFor="let service of servicesList">
            <app-background-block [url]="service.url || ''" [gradient]="gradient">
                <div class="charter-cell-text-container">
                    <div class="charter-cell-text">
                        <h4 class="colorWhite header-text"><app-text-element [inputText]="service.title || []"/></h4>
                        <h5 class="colorYellow"><app-text-element [inputText]="service.text || []"/></h5>
                        <div class="price-line">
                            <p>First passenger</p>
                            <p>Price: ${{service.index}}/ hr</p>
                        </div>
                    </div>
                    <div class="button-container">
                        <app-button
                                [buttonTitle]="textElements[9]"
                                [buttonClass]="'button-style-1 button-size-m'"
                                [buttonJustifyContent]="'start'"
                        />
                    </div>
                </div>
            </app-background-block>
        </div>
    </div>
</div>
