import {TextElement} from "./textElement";
import {Icon, IconsName} from "./icons";
import {SocialMedia} from "./socialMedia";

export class Contact {
  title?: TextElement[];
  text?: TextElement[];
  href?: string;
  linkText?: TextElement[];
  icon?: Icon;
};

export  enum TypeOfFiled {
  input,
  textarea,
}

export class GetInTouchField {
  name?: TextElement[];
  type?: TypeOfFiled;
  key?: string;
  value: any;
}

export class WayOfContact {
  id?: number;
  label?: TextElement[];
  icon?: Icon;
  state?: boolean;
}

export const waysOfContactList: WayOfContact[] = [
  {
    id: 0,
    label: [
      {
        languageId: 0,
        text: "Phone",
      },
      {
        languageId: 1,
        text: "Телефон",
      },
    ],
    icon: IconsName.Phone,
  },
  {
    id: 1,
    label: [
      {
        languageId: 0,
        text: "E-Mail",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    icon: IconsName.Envelope,
  },
  {
    id: 2,
    label: [
      {
        languageId: 0,
        text: "WhatsApp",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    icon: IconsName.Whatsapp,
  },
  {
    id: 3,
    label: [
      {
        languageId: 0,
        text: "Telegram",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    icon: IconsName.Telegram,
  },
  {
    id: 4,
    label: [
      {
        languageId: 0,
        text: "Viber",
      },
      {
        languageId: 0,
        text: "",
      },
    ],
    icon: IconsName.Viber,
  },
];
