import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {BackgroundBlockComponent} from "../background-block/background-block.component";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../text-element/text-element.component";
import {IconComponent} from "../icon/icon.component";
import {IconsName} from "../../../model/icons";
import {ButtonComponent} from "../button/button.component";
import {BookingService} from "../../../services/booking.service";

@Component({
  selector: 'app-offer',
  standalone: true,
  imports: [CommonModule, BackgroundBlockComponent, TextElementComponent, IconComponent, ButtonComponent],
  templateUrl: './offer.component.html',
  styleUrl: './offer.component.scss'
})
export class OfferComponent {
  @Input() url: string  = "";
  @Input() title: TextElement[]  = [];
  @Input() text: TextElement[]  = [];
  @Input() until: TextElement[]  = [];
  @Input() list: TextElement[][]  = [];
  @Input() button: TextElement[]  = [];
  constructor(public bookingService: BookingService ) {}

  protected readonly IconsName = IconsName;
}
