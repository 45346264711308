<div class="offer-container">
    <app-background-block class="all-area" [url]="url" [opacity]="0.7">
        <div class="offer-text-container">
            <h3 class="colorWhite header-text"><app-text-element [inputText]="title"/></h3>
            <h6 class="colorWhite"><app-text-element [inputText]="text"/></h6>
            <h5 class="colorWhite"><app-text-element [inputText]="until"/></h5>
            <div class="offer-list">
                <div *ngFor="let item of list" class="offer-list-item">
                    <app-icon [icon]="IconsName.CheckboxCircle" [width]="30" [height]="30"/>
                    <h6 class="colorWhite"><app-text-element [inputText]="item"/></h6>
                </div>
            </div>
            <div class="offer-button-container">
                <app-button
                        [buttonTitle]="button"
                        [buttonClass]="'button-style-2 button-size-l'"
                        [buttonJustifyContent]="'center'"
                        (click)="bookingService.setBookingForm(true)"

                />
            </div>
        </div>
    </app-background-block>
</div>


