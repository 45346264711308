<div class="selector-names-container">
    <div class="header-container">
        <div class="search-field-container">
            <input type="text"
                   class="search-field"
                   [placeholder]="languageService.getStringFromTextElement(textElements[0])"
                   [(ngModel)]="search"
                   (keyup)="searchFoo($event)"
            />
            <app-icon
                    class="search-field-cross"
                    *ngIf="search.length > 2"
                    (click)="clearSearch()"
                    [icon]="IconsName.Cross"
                    [width]="30"
                    [height]="30"
            />
        </div>
        <app-button
                class="add-button"
                [buttonTitle]="textElements[1]"
                [buttonClass]="'button-style-2 button-size-sm'"
                [buttonJustifyContent]="'start'"
                (click)="openDialog()"
        />
    </div>
    <form [formGroup]="form" class="selector-names-grid">
        <table class="names-table">
            <tbody class="table__body">
            <tr *ngFor="let item of itemsFiltered">
                <th><app-country-flag [countryCode]="item.codeCountry"/></th>
                <th><h4>{{ item.code }}</h4></th>
                <th style="max-width: 20%" ><h4><app-text-element [inputText]="item.name"/></h4></th>
                <th style="max-width: max-content;">
                    <input pInputText
                           type="text"
                           [ngModel]="item.value"
                           [formControlName]="item.code"
                           [name]="item.code"
                           (keyup)="updForm()"
                    />
                </th>
            </tr>
            </tbody>
        </table>
    </form>
</div>
<app-modal
        [title]="languageService.getStringFromTextElement(textElements[2])"
        [visible]="dialog"
        (onChanged)="hideDialog()"
        [width]="'40vw'"
>
    <form class="new-item-container">
        <div class="inputs-container">
            <div class="input-item">
                <label><app-text-element [inputText]="textElements[5]"/></label>
                <p-dropdown [options]="itemsNewFiltered"
                            [(ngModel)]="newItem"
                            [filter]="true"
                            optionLabel="code"
                            appendTo="body"
                            id="code"
                            name="code"
                >
                    <ng-template pTemplate="filter" let-options>
                        <div class="filter-container">
                            <input type="text"
                                   class="search-field"
                                   name="newSearch"
                                   [placeholder]="languageService.getStringFromTextElement(textElements[0])"
                                   [(ngModel)]="searchNew"
                                   (keyup)="searchFooNew($event)"
                            />
                            <app-icon [icon]="IconsName.Cross" [width]="24" [height]="24" (click)="clearNewSearch()"/>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="newItem.code" class="selected-container">
                            <app-country-flag [countryCode]="newItem.codeCountry" [width]="24"/>
                            <app-text-element [inputText]="newItem.name" style="margin-left: 0.6rem"/>
                            <app-icon [icon]="IconsName.Cross" [width]="24" [height]="24" (click)="clearNewItem()"/>
                        </div>
                    </ng-template>
                    <ng-template let-lang pTemplate="item">
                        <div class="item-container">
                            <app-country-flag [countryCode]="lang.codeCountry" [width]="20"/>
                            <app-text-element [inputText]="lang.name" style="margin-left: 0.6rem"/>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="input-item">
                <label><app-text-element [inputText]="textElements[6]"/></label>
                <input pInputText
                       type="text"
                       [(ngModel)]="newItem.value"
                       name="value"
                />
            </div>
        </div>
        <div class="buttons-container">
            <app-button
                    class="add-button"
                    [buttonTitle]="textElements[3]"
                    [buttonClass]="'button-style-2 button-size-sm'"
                    [buttonJustifyContent]="'start'"
                    (click)="addItem()"
            />
            <app-button
                    class="add-button"
                    [buttonTitle]="textElements[4]"
                    [buttonClass]="'button-style-1 button-size-sm'"
                    [buttonJustifyContent]="'start'"
                    (click)="hideDialog()"
            />
        </div>
        <div *ngIf="errorFlag" class="error-container">
           <h6 class="colorRed"><app-text-element [inputText]="textElements[7]"/></h6>
        </div>
    </form>
</app-modal>
