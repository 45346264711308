import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {CellGalleryComponent} from "../../elements/cell-gallery/cell-gallery.component";

@Component({
  selector: 'app-our-benifit',
  standalone: true,
  imports: [CommonModule, TextElementComponent, CellGalleryComponent],
  templateUrl: './our-benefit.component.html',
  styleUrl: './our-benefit.component.scss'
})
export class OurBenefitComponent {

  textElements: TextElement[][] = [
      [
        {
          languageId: 0,
          text: "OUR BENEFIT",
        },
        {
          languageId: 1,
          text: "НАШИ ПРЕИМУЩЕСТВА",
        },
      ],
    [
      {
        languageId: 0,
        text: "Learn More About Our Luxury Services",
      },
      {
        languageId: 1,
        text: "Узнайте больше о нашем сервисе",
      },
    ],
    [
      {
        languageId: 0,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum, lacus non faucibus congue, lectus quam viverra nulla, quis egestas neque sapien ac magna.",
      },
      {
        languageId: 1,
        text: "\n" +
            "Мы предлагаем широкий спектр услуг, отвечающих потребностям самых разных клиентов. С нами работают бизнесмены, руководители, частные лица и группы, в том числе VIP-персоны. Кроме того, мы предоставляем специализированную помощь путешественникам с особыми потребностями, например, пожилым людям, несовершеннолетним без сопровождения, лицам с ограниченными возможностями и тем, кто может столкнуться с языковым барьером.",
      },
    ],
  ]
}
