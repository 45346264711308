<div class="social-media-container"
     [style]="'justify-content: ' + buttonJustifyContent"
>
  <div *ngIf="vertical" class="social-media-line"></div>
    <div class="social-media-item-container" [class]="{'social-media-item-container-vertical': vertical }">
        <div class="social-media-item" *ngFor="let item of socialMediaList">
            <a [href]="item.link">
                <app-icon [icon]="item.icon || Icons[0]" [height]="20" [width]="20"/>
            </a>
        </div>
    </div>
  <div *ngIf="vertical" class="social-media-line"></div>
</div>

