import {Component, Injectable, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Language } from "../../../model/language";
import { LanguageService } from "../../../services/language.service";
import { TextElementComponent } from "../text-element/text-element.component";
@Component({
  selector: 'app-switcher',
  standalone: true,
  imports: [CommonModule, TextElementComponent],
  templateUrl: './switcher.component.html',
  styleUrl: './switcher.component.scss'
})
export class SwitcherComponent implements OnInit {
  languages: Language[] = [];
  constructor(public languageService: LanguageService) {}
  isActiveLanguage(lang: Language){
    return this.languageService.getLanguage().id == lang.id;
  }

  select(value: Language){
    this.languageService.setLanguage(value, true);
  }

  ngOnInit(){
    this.languages = this.languageService.getLanguages();
  }
}
