import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Login} from "./login";
import {LoginService} from "../services/login.service";
import {TokenStorageService} from "../services/token.storage.service";


@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    login: Login| undefined;

    constructor(private router: Router, private loginService: LoginService, private tokenStorageService: TokenStorageService) {
        this.loginService.get().subscribe(login => {
            this.login = login;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.login) {
            this.login = this.tokenStorageService.getLoginInfo();
        }
        if (this.login) {
            return true
        } else {
            this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}}).then();
            return false;
        }
    }
}
