<div class="welcome-container">
  <div class="mh col-1 d-none d-md-block">
      <div class="welcome-social-media-container">
          <app-social-media [vertical]="true"/>
      </div>
  </div>
  <div class="mh col-6 d-none d-md-block">
    <app-picture-plane/>
  </div>
  <div class="mh col-sm-12 col-md-5">
    <div class="title-welcome-container">
      <div class="title-welcome-block">
        <h3 class="colorYellow quicksand bold-400 font-1-8 italic">
          <app-text-element [inputText]="textElements[0]"/>
          <span class="colorWhite quicksand bold-400 font-1-8 italic">
          <app-text-element [inputText]="textElements[1]"/>
        </span>
        </h3>
        <h1 class="cormorant font-5-2 colorWhite line-height-1 bold-600">
          <app-text-element [inputText]="textElements[2]"/>
          <span class="cormorant colorYellow"><app-text-element [inputText]="textElements[3]"/></span>
          <app-text-element [inputText]="textElements[4]"/>
        </h1>
        <p><app-text-element [inputText]="textElements[5]"/></p>
        <app-button
          [buttonTitle]="textElements[6]"
          [buttonClass]="'button-style-1 button-size-l'"
          [buttonJustifyContent]="'start'"
          (click)="bookingService.setBookingForm(true)"
        />
      </div>
    </div>
  </div>
</div>
