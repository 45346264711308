import {Component, Injectable, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {TextElement} from "../../../model/textElement";
import {getNewObj, Header, TableContent, Types} from "../../../model/table";
import {ButtonComponent} from "../../elements/button/button.component";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {TableComponent} from "../../elements/table/table.component";
import {AdminService} from "../../../services/admin.service";
import {NgForOf, NgIf} from "@angular/common";
import {ModalComponent} from "../../elements/modal/modal.component";
import {LanguageService} from "../../../services/language.service";
import {InputTextModule} from "primeng/inputtext";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {menu} from "../../../model/menu";
import {IconsName} from "../../../model/icons";
import {IconComponent} from "../../elements/icon/icon.component";
import {SelectorNamesComponent} from "../../elements/selector-names/selector-names.component";
import {Names} from '../../../model/names';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {NamesItemComponent} from "../../elements/names-item/names-item.component";
import {MyMessageService} from "../../../services/my-message.service";
import {AirportServicesComponent} from "../airport-services/airport-services.component";
import {Airport} from "../../../model/airport";


@Injectable()
@Component({
  selector: 'app-admin-table',
  standalone: true,
  imports: [
    ButtonComponent,
    TextElementComponent,
    TableComponent,
    ModalComponent,
    InputTextModule,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    NgForOf,
    IconComponent,
    SelectorNamesComponent,
    ProgressSpinnerModule,
    NamesItemComponent,
    AirportServicesComponent,
  ],
  templateUrl: './admin-table.component.html',
  styleUrl: './admin-table.component.scss',
})
export class AdminTableComponent implements OnChanges {
  constructor(
      private adminService: AdminService,
      public languageService: LanguageService,
      public myMessageService: MyMessageService) {
  }
  @ViewChild(AirportServicesComponent) services: AirportServicesComponent;
  @Input() header: Header[];
  @Input() title: TextElement[];
  @Input() type: Types;
  @Input() url: string;

  items: any[] = [];
  totalElements: number = 0;
  totalPages: number = 0;
  size: number = 10;
  page: number = 0;
  sort: string = "";
  shortHeader: Header[] = [];
  search: string = "";
  dialog: boolean = false;
  selectedObj: any;
  form:  FormGroup = new FormGroup({});
  fields: TableContent[] = [];
  fieldNames: Names = new Names();
  fieldNamesEmit: Names = new Names();
  fieldDescriptions: Names = new Names();
  fieldDescriptionsEmit: Names = new Names();
  isFieldNames: boolean = false;
  isFieldDescriptions: boolean = false;
  isServices: boolean = false;
  airportId: number = 0;
  editTabActive: number = 0;
  loading: boolean = false;
  requests: {time: number, loading: boolean, data:
        {items: any[], totalElements: number, totalPages: number, size: number}}[] = [];

  getLabelFromHeader(key: string, headers: Header[]){
    return headers.find(value => value.key == key)?.label ?? [];
  }

  async getData() {
    this.loading = true;
    const time = new Date().getTime();
    const newData = {
      time: time,
      loading: true,
    }
    this.adminService.getContent<any>(this.url, this.page, this.size, this.search, this.sort).subscribe({
      next: (loadedPage) => {
        const newData = {
          time: time,
          loading: false,
          data: {
            items: loadedPage.content ?? [],
            totalElements: loadedPage.totalElements ?? 0,
            totalPages: loadedPage.totalPages ?? 0,
            size: loadedPage.size ?? 0,
          }
        };
        this.requests.push(newData);
      },
      error: (error) => {
        console.log(error);
      },
      complete:() => {
        this.setData();
      },
    });
  }


  setData(){
    const latestRequest = this.requests.find(r =>
        r.time == Math.max(...this.requests.map(v => v.time)));
      if (latestRequest?.loading == false) {
        this.items = latestRequest.data.items ?? [];
        this.totalElements = latestRequest.data.totalElements ?? 0;
        this.totalPages = latestRequest.data.totalPages ?? 0;
        this.size = latestRequest.data.size ?? 0;
        this.loading = false;
      }
  }

  getPage(value: number){
      this.page = value ?? 0;
      this.getData().then(r => r);
  }

  getSize(value: number) {
    this.size = value ?? 10;
    this.getData().then(r => r);
  }
  getSort(value: string) {
    this.reset();
    this.shortHeader.forEach(v => {
      v.sort = "";
    });
    const elements = value.split(",");
    const field = this.shortHeader.find(m => m.key == elements[0]);
    if (field) {
      field.sort = elements[1];
      if (field.sortKey) {
        elements[0] = field.sortKey;
      } else {
        const removedShit = elements[0].replace(/[^A-Za-z0-9]/g, "");
        if (removedShit != elements[0]) {
          elements[0] = elements[0].toLowerCase().replace(/[^a-z0-9](.)/g, (_, c) => c.toUpperCase());
        }
      }
      const correctedValue = elements.join(",");
      this.sort = elements[1] ? correctedValue : "";
      this.getData().then(r => r);
    } else {
      console.log('There is no field');
    }
  }

  reset() {
    this.totalElements = 0;
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.sort = "";
    this.search = "";
    this.editTabActive = 0;
    this.isFieldNames = false;
    this.isFieldDescriptions = false;
    this.requests = [];
  }

  openDialog(){
    this.dialog = true;
  }


  hideDialog() {
    this.dialog = false;
    this.getData().then(r => r);
  }

  getAirportId() {
    if (Types.airport) {
      const airport: Airport = new Airport(this.selectedObj);
      this.airportId = airport.id ?? 0;
    }
  }

  getFormControlsFields() {
    const formGroupFields: any = {};
    for (const key of Object.keys(getNewObj(this.type))) {
      this.isServices = this.type == Types.airport;
      const header = this.header.find(value => value.key == key);
      const disable = header?.edit ? !header.edit : true;
      formGroupFields[key] = new FormControl({value: '', disabled: disable});
      if (header?.form) {
        if (key == "names") {
          this.fieldNames = this.selectedObj[key] ?? null;
          this.isFieldNames = true;
        } else if (key == "descriptions") {
          this.fieldDescriptions = this.selectedObj[key] ?? null;
          this.isFieldDescriptions = true;
        } else if (header?.obj && header?.headers) {
          this.fields.push(new TableContent({
            key: key,
            label: this.getLabelFromHeader(key, this.header),
            value: null,
            obj: true,
            edit: header.edit,
          }));
          for (const innerKey of Object.keys(this.selectedObj[key])) {
            const innerHeader = header?.headers.find(value =>
                (value.key == innerKey) || (value.key == key + "." + innerKey));
            const newKey = key + "_" + innerKey;
            formGroupFields[newKey] = new FormControl({value: '', disabled: true});
            if (innerHeader?.form) {
              const correctedInnerKey = innerHeader.namesField ?  key + "." + innerKey : innerKey;
              this.fields.push(new TableContent({
                key: newKey,
                label: this.getLabelFromHeader(correctedInnerKey, header?.headers ?? []),
                value: this.selectedObj[key][innerKey] ?? null,
                edit: false,
                namesField: innerHeader.namesField,
              }));
            }
          }
        } else {
          this.fields.push(new TableContent({
            key: key,
            label: this.getLabelFromHeader(key, this.header),
            value: this.selectedObj[key] ?? null,
            edit: false,
          }));
        }
      }
    }
    this.fieldNamesEmit = this.fieldNames;
    this.fieldDescriptionsEmit = this.fieldDescriptions;
    return formGroupFields;
  }

  buildForm(value: any) {
    this.fields = [];
    this.selectedObj = getNewObj(this.type, value);
    const formGroupFields = this.getFormControlsFields();
    this.form = new FormGroup(formGroupFields);
  }

  clickRow(value: any){
    if (value) {
      this.buildForm(value);
      this.getAirportId();
    }
    this.openDialog();
  }

  newEntity() {
    const newObj: any = getNewObj(this.type);
    this.buildForm(newObj);
    this.openDialog();
  }

  getCorrectedObj() {
    const data: any = getNewObj(this.type, this.selectedObj);
    this.header.filter(f => f.edit).forEach(h => {
      if (data.hasOwnProperty("names") && h.key == "names") {
        data.names = this.fieldNamesEmit;
      } else if (data.hasOwnProperty("descriptions") && h.key == "descriptions") {
        data.descriptions = this.fieldDescriptionsEmit;
      } else {
        const formKey = Object.keys(this.form.value).find(k => k == h.key)
        const dataKey = Object.keys(data).find(k => k == h.key)
        if (formKey && dataKey) {
          data[dataKey] = this.form.value[formKey];
        }
      }
    });
    return data;
  }
  submit(){
    if (this.airportId > 0) {
      this.services.saveServices();
    }
    this.adminService.saveItem(this.url, this.getCorrectedObj()).subscribe({
      next: () => {
        this.myMessageService.addMessage(this.textElements[5], "success");
        this.hideDialog();
        this.reset();
      },
      error: (error) => {
        this.myMessageService.addMessage(this.textElements[6], "error");
        console.log(error);
        this.hideDialog();
      }
    });
  };

  getShortHeader(){
    let header = this.header.filter(v => v.table) ?? [];
    header.forEach((h) => {
      if (h.obj && h.headers) {
        h.headers.forEach((innerH) => {
          if (innerH.table) {
            innerH.key = h.key + "." + innerH.key;
            header.push(innerH);
          }
        })
      }
    })
    this.shortHeader = header.filter(v => !v.obj);
  }


  searchFoo(event?: KeyboardEvent){
    if (this.search.length > 2) {
      this.getData().then(r => r);
    } else {
      this.requests = [];
      this.getData().then(r => r);
    }
  }

  clearSearch(){
    this.search = "";
    this.requests = [];
    this.getData().then(r => r);
  }

  getFieldNames(names: Names) {
    this.fieldNamesEmit = names;
  }

  getFieldDescriptions(names: Names) {
    this.fieldDescriptionsEmit = names;
  }

  ngOnChanges(changes: SimpleChanges) {
    const isHeader =  Object.keys(changes).find((f: string) => f == "header");
    if (isHeader) {
      this.reset();
      this.getShortHeader();
      this.getData().then(r => r);
    }
  }


  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "ADD",
      },
      {
        languageId: 1,
        text: "Добавить",
      },
    ],
    [
      {
        languageId: 0,
        text: "№",
      },
      {
        languageId: 1,
        text: "№",
      },
    ],
    [
      {
        languageId: 0,
        text: "Save",
      },
      {
        languageId: 1,
        text: "Сохранить",
      },
    ],
    [
      {
        languageId: 0,
        text: "Cancel",
      },
      {
        languageId: 1,
        text: "Отменить",
      },
    ],
    [
      {
        languageId: 0,
        text: "Edit",
      },
      {
        languageId: 1,
        text: "Редактирование",
      },
    ],
    [
      {
        languageId: 0,
        text: "This entry has been successfully saved",
      },
      {
        languageId: 1,
        text: "Запись успешно сохранена",
      },
    ],
    [
      {
        languageId: 0,
        text: "Error, something went wrong",
      },
      {
        languageId: 1,
        text: "Ошибка, что-то пошло не так",
      },
    ],
    [
      {
        languageId: 0,
        text: "Search",
      },
      {
        languageId: 1,
        text: "Поиск",
      },
    ],
    [
      {
        languageId: 0,
        text: "Main",
      },
      {
        languageId: 1,
        text: "Основное",
      },
    ],
    [
      {
        languageId: 0,
        text: "Names",
      },
      {
        languageId: 1,
        text: "Названия на других языках",
      },
    ],
    [
      {
        languageId: 0,
        text: "Descriptions",
      },
      {
        languageId: 1,
        text: "Описания",
      },
    ],
    [
      {
        languageId: 0,
        text: "Services",
      },
      {
        languageId: 1,
        text: "Услуги",
      },
    ],
  ];


  protected readonly menu = menu;
  protected readonly IconsName = IconsName;
}
