<div class="table-component-container">
    <div class="table-container">
        <table>
            <colgroup>
                <col *ngFor="let header of header"/>
            </colgroup>
            <thead>
            <tr>
                <th *ngFor="let header of header">
                    <div class="header-block" >
                        <app-text-element [inputText]=" header.label"/>
                        <app-sort [state]="header.sort" (changeSort)="getSort($event, header.key)"/>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody class="table__body">
            <tr *ngFor="let row of content; index as i" class="table-row" (click)="clickOnRow(row)">
                <th *ngFor="let header of header">
                    <div *ngIf="header.key == 'index'; else value">
                        {{ i + 1 }}
                    </div>
                    <ng-template #value>
                        {{ getValue(header.key, row) }}
                    </ng-template>
                </th>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="our-pagination-container">
        <div class="our-pagination-buttons">
            <div class="total">
                <div class="total-title"><app-text-element [inputText]="textElements[0]"/></div>
                <div class="total-elements">{{ totalElements }}</div>
                <div class="total-title"><app-text-element [inputText]="textElements[1]"/></div>
                <div class="total-sizes" *ngFor="let value of sizes">
                    <div class="total-size"
                         [class]="{ 'total-size-active': value == size }"
                         (click)="changeSizes(value)"
                    >
                        {{ value }}
                    </div>
                </div>
            </div>
        </div>
        <div class="our-pagination">
            <div class="our-pagination-item" (click)="changeActive('prev')">
                <app-icon style="transform: rotate(-90deg);" [icon]="IconsName.Arrow" [height]="20" [width]="20"/>
            </div>
            <div *ngFor="let number of getTotalPagesAsArray()" >
                <div (click)="changeActive(number)"
                     class="our-pagination-item"
                     [class]="{ 'our-pagination-item-active': active == Number(number) - 1 }">
                    {{ number }}
                </div>
            </div>
            <div class="our-pagination-item" (click)="changeActive('next')">
                <app-icon style="transform: rotate(90deg);" [icon]="IconsName.Arrow" [height]="20" [width]="20"/>
            </div>
        </div>
    </div>
</div>
