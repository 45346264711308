import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AirportService} from "../../../services/airport.service";
import {LanguageService} from "../../../services/language.service";
import {ServicesOfAirport} from "../../../model/airport";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {TextElement} from "../../../model/textElement";
import {IconsName} from "../../../model/icons";
import {IconComponent} from "../icon/icon.component";
import {TextElementComponent} from "../text-element/text-element.component";
import {NgIf} from "@angular/common";
import {SelectorNames} from "../../../model/names";

@Component({
  selector: 'app-service-switcher',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    IconComponent,
    TextElementComponent,
    NgIf
  ],
  templateUrl: './service-switcher.component.html',
  styleUrl: './service-switcher.component.scss'
})
export class ServiceSwitcherComponent implements OnInit {

  @Output() outputService = new EventEmitter<ServicesOfAirport>();

  constructor(public airportService: AirportService,
              public languageService: LanguageService){}

  service: ServicesOfAirport = new ServicesOfAirport();
  services: ServicesOfAirport[] = [];
  filteredService: ServicesOfAirport[] = [];
  search: string = "";

  ngOnInit() {
    this.airportService.getServices().subscribe({
      next: (loadedPage) => {
        this.services = loadedPage.content ?? [];
        this.select(this.services[0]);
        this.clearSearch();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }


  searchFoo(event?: KeyboardEvent){
    if (this.search.length > 0) {
      this.filteredService = this.services.filter(v =>
          v.name.toLowerCase().includes(this.search.toLowerCase()) ||
          this.languageService.getNameByTheLang(v.names).toLowerCase().includes(this.search.toLowerCase())
      );
    } else {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.search = "";
    this.filteredService = this.services.map(v => new ServicesOfAirport(v));
  }

  select(value: ServicesOfAirport){
    this.service = value ?? new ServicesOfAirport();
    this.outputService.emit(this.service);
  }

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Search",
      },
      {
        languageId: 1,
        text: "Поиск",
      },
    ],
  ];

  protected readonly IconsName = IconsName;
}
