import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {ButtonComponent} from "../../elements/button/button.component";
import {InfoPanelComponent} from "../info-panel/info-panel.component";
import {BookingService} from "../../../services/booking.service";

@Component({
  selector: 'app-our-services',
  standalone: true,
  imports: [CommonModule, TextElementComponent, ButtonComponent, InfoPanelComponent],
  templateUrl: './our-services.component.html',
  styleUrl: './our-services.component.scss'
})
export class OurServicesComponent {
  constructor(public bookingService: BookingService ) {}

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "OUR SERVICES",
      },
      {
        languageId: 1,
        text: "НАШИ УСЛУГИ",
      },
    ],
    [
      {
        languageId: 0,
        text: "We Have Best Luxury Services For VIP",
      },
      {
        languageId: 1,
        text: "Предоставляем полный перечень услуг для VIP",
      },
    ],
    [
      {
        languageId: 0,
        text: "Our range of Meet & Greet Services provide you with speedy clearance through arrivals or departures to ensure you have a simpler, quicker and less stressful airport experience.",
      },
      {
        languageId: 1,
        text: "Услуга Meet & Greet обеспечивает быстрое оформление прилетающих и вылетающих пассажиров, чтобы сэкономить Ваше время и сделать прибывание аэропорту  менее напряженным.",
      },
    ],
    [
      {
        languageId: 0,
        text: "BOOK NOW",
      },
      {
        languageId: 1,
        text: "ЗАБРОНИРУЙТЕ СЕЙЧАС",
      },
    ],
  ]
}
