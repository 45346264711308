import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {ButtonComponent} from "../../elements/button/button.component";
import {InformationCell} from "../../../model/InformationCell";
import {Icons, IconsName} from "../../../model/icons";
import {IconComponent} from "../../elements/icon/icon.component";
import {BookingService} from "../../../services/booking.service";

@Component({
  selector: 'app-airport-meet-and-greet',
  standalone: true,
  imports: [CommonModule, TextElementComponent, ButtonComponent, IconComponent],
  templateUrl: './airport-meet-and-greet.component.html',
  styleUrl: './airport-meet-and-greet.component.scss'
})
export class AirportMeetAndGreetComponent {
  constructor(public bookingService: BookingService ) {}

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "AIRPORT MEET & GREET",
      },
      {
        languageId: 1,
        text: "AIRPORT MEET & GREET",
      },
    ],
    [
      {
        languageId: 0,
        text: "Luxury Arrivals and Departures, Tailored To You",
      },
      {
        languageId: 1,
        text: "Комфортные прилеты и вылеты, удобно для Вас",
      },
    ],
    [
      {
        languageId: 0,
        text: "If your priority is to travel in exceptional comfort at affordable prices, our airport meet and greet services are specifically tailored for you. Not only will you have a dedicated agent to guide you through arrival, transit, or departure procedures, but you will also enjoy a range of perks when you choose our meet and assist services.",
      },
      {
        languageId: 1,
        text: "Если вашим приоритетом является путешествие с исключительным комфортом по доступным ценам, наши услуги встречи в аэропорту созданы специально для Вас. У Вас не только будет специальный агент, который проведет вас через процедуры прибытия, транзита или отбытия, но вы также получите ряд привилегий, выбрав наши услуги по встрече и сопровождению.",
      },
    ],
    [
      {
        languageId: 0,
        text: "View About",
      },
      {
        languageId: 1,
        text: "Смотреть",
      },
    ],
    [
      {
        languageId: 0,
        text: "World’s solutions",
      },
      {
        languageId: 1,
        text: "Международные решения",
      },
    ],
    [
      {
        languageId: 0,
        text: "we have catering travelers in over 500 airports worldwide.",
      },
      {
        languageId: 1,
        text: "Мы работаем в более чем 500 аэропортах по всему миру.",
      },
    ],
    [
      {
        languageId: 0,
        text: "Special for every traveler",
      },
      {
        languageId: 1,
        text: "Специально для каждого путешественника",
      },
    ],
    [
      {
        languageId: 0,
        text: "Private lounge, executive car transfer, airport fast track.",
      },
      {
        languageId: 1,
        text: "VIP Lounge, трансфер, ускоренное обслуживание в аэропорту.",
      },
    ],
    [
      {
        languageId: 0,
        text: "Stress free",
      },
      {
        languageId: 1,
        text: "Без стресса",
      },
    ],
    [
      {
        languageId: 0,
        text: "Speedy run through passport control, security check, luggage processing.",
      },
      {
        languageId: 1,
        text: "Быстрое прохождение паспортного контроля, досмотра и оформление багажа.",
      },
    ],
  ];

  tableItems: InformationCell[] = [
    {
      icon: IconsName.CheckboxCircle,
      title: this.textElements[4],
      text: this.textElements[5],
    },
    {
      icon: IconsName.CheckboxCircle,
      title: this.textElements[6],
      text: this.textElements[7],
    },
    {
      icon: IconsName.CheckboxCircle,
      title: this.textElements[8],
      text: this.textElements[9],
    },
  ];

  protected readonly IconsName = IconsName;
}

