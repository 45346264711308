import {Component, Input, OnInit} from '@angular/core';
import {languagesCountryMap} from "../../../model/languagesCountryMap";

@Component({
  selector: 'app-country-flag',
  standalone: true,
  imports: [],
  templateUrl: './country-flag.component.html',
  styleUrl: './country-flag.component.scss'
})
export class CountryFlagComponent implements OnInit{
  @Input() countryCode: string  = "";
  @Input() width: number  = 44;
  @Input() forLanguage: boolean  = false;
  height(width: number) {
    return width * 0.6666666667;
  }
  ngOnInit() {
    if (this.forLanguage) {
      this.countryCode = languagesCountryMap.get(this.countryCode) ?? this.countryCode;
    }
  }
}
