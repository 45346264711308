import {Names} from "./names";
import {City} from "./city";
import {Country} from "./country";
import {Airport, ServicesOfAirport} from "./airport";

export enum TypeOfSService {
    Arrival = "Arrival",
    Departure = "Departure",
    Transit = "Transit",
}

export function TypeOfSServiceToTextElement(item: TypeOfSService) {
    switch (item) {
        case TypeOfSService.Arrival:
            return [
                {
                    languageId: 0,
                    text: "Arrival",
                },
                {
                    languageId: 1,
                    text: "Прибытие",
                },
            ];
        case TypeOfSService.Departure:
            return [
                {
                    languageId: 0,
                    text: "Departure",
                },
                {
                    languageId: 1,
                    text: "Отправление",
                },
            ];
        case TypeOfSService.Transit:
            return [
                {
                    languageId: 0,
                    text: "Transit",
                },
                {
                    languageId: 1,
                    text: "Транзит",
                },
            ];
    }
}

export class Booking {
    name: string;
    surname: string;
    phone: string;
    email: string;
    date: string;
    serviceId: number | null;
    serviceType: TypeOfSService | null;
    personsCount: number | null;
    flightNumber: string;
    airportId: number | null;
    note: string
    constructor(value: Booking) {
        this.name = value.name || "";
        this.surname = value.surname || "";
        this.phone = value.phone || "";
        this.email = value.email || "";
        this.date = value.date || "";
        this.serviceId = value.serviceId || null;
        this.serviceType = value.serviceType || null;
        this.personsCount = value.personsCount || null;
        this.flightNumber = value.flightNumber || "";
        this.airportId = value.airportId || null;
        this.note = value.note || "";
    }
}

export class BookingDto {
    id?:               number;
    name: string;
    surname: string;
    createTime: string;
    phone: string;
    email: string;
    date: string;
    personsCount: number;
    flightNumber: string;
    note: string;
    serviceType: TypeOfSService;
    service: ServicesOfAirport;
    airport: Airport;
    names:            Names;
    constructor(value?: BookingDto) {
        this.name = value?.name || "";
        this.surname = value?.surname || "";
        this.createTime = value?.createTime || "";
        this.phone = value?.phone || "";
        this.email = value?.email || "";
        this.date = value?.date || "";
        this.personsCount = value?.personsCount || 0;
        this.flightNumber = value?.flightNumber || "";
        this.note = value?.note || "";
        this.serviceType = value?.serviceType || TypeOfSService.Arrival;
        this.service =  value?.service ?? new ServicesOfAirport();
        this.airport =  value?.airport ?? new Airport();
        this.names =  value?.names ?? new Names();

    }
}

