import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {TextElement} from "../model/textElement";

@Injectable({
    providedIn: 'root'
})
export class MyMessageService {
    constructor() {}
    public state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public type: BehaviorSubject<string> = new BehaviorSubject<string>("success");
    public message: BehaviorSubject<TextElement[]> = new BehaviorSubject<TextElement[]>([]);

    getState() {
        return this.state.value;
    }
    setState(value: boolean) {
        this.state.next(value);
    }

    getMessage() {
        return this.message.value;
    }
    setMessage(value: TextElement[]) {
        this.message.next(value);
    }

    getType() {
        return this.type.value;
    }
    setType(value: string) {
        this.type.next(value);
    }

    clear(){
        this.setState(false);
        this.setType("success");
        this.setMessage([]);
    }

    addMessage(value: TextElement[], type?: string, time?: number) {
        this.clear();
        this.setMessage(value);
        if (type) {
            this.setType(type);
        }
        this.setState(true);
        setTimeout(() => {
            this.clear();
        }, time ?? 3000);
    }

}
