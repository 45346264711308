import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {menu, Menu} from "../../model/menu";
import {TextElementComponent} from "../elements/text-element/text-element.component";
import { RouterLink, RouterLinkActive} from "@angular/router";
import {IconComponent} from "../elements/icon/icon.component";
import {IconsName} from "../../model/icons";
import {HamburgerComponent} from "../elements/hamburger/hamburger.component";
import {ModalMobileService} from "../../services/modal-mobile.service";

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, TextElementComponent, RouterLink, IconComponent, HamburgerComponent, RouterLinkActive],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  constructor(public modalMobileService: ModalMobileService,) {}
  changeModalMobile() {
    this.modalMobileService.setModalMobile(!this.modalMobileService.getModalMobile());
  }


  menu: Menu[] = menu;
  protected readonly IconsName = IconsName;
}
