<div class="checkbox-container"
     (click)="changeState()"
     [class]="class"
>
    <div class="checkbox">
        <app-icon *ngIf="state" [icon]="IconsName.Check" [width]="20" [height]="20"/>
    </div>
    <div *ngIf="icon" class="checkbox-text">
        <app-icon class="icon-field" [icon]="icon" [width]="20" [height]="20"/>
    </div>
    <div class="checkbox-text">
        <app-text-element [inputText]="label"/>
    </div>
</div>
