import {TypeOfSService} from "./booking";
import {TextElement} from "./textElement";
import {City} from "./city";
import {Country} from "./country";
import {Names} from "./names";

export class Airport {
    id:               number;
    slug:             string;
    name:             string;
    iata_code:        string;
    icao_code:        string;
    faa_code:         string;
    un_locode:        string;
    timezone:         string;
    lat:              number;
    lng:              number;
    alt:              number;
    city_code:        string;
    city:             string;
    country_code:     string;
    departures_intl:  number;
    departures_dom:   number;
    connections_intl: number;
    connections_dom:  number;
    is_major:         number;
    is_international: number;
    runways:          number;
    connections:      number;
    departures:       number;
    website:          string;
    twitter:          string;
    facebook:         string;
    instagram:        string;
    linkedin:         string;
    youtube:          string;
    phone:            string;
    phone_formatted:  string;
    email:            string;
    postal_code:      string;
    with_schedules:   number;
    type:             string;
    fir_code:         string;
    fir_name:         string;
    size:             string;
    popularity:       number;
    names?:            Names;
    cityObject:       City;
    countryObject:    Country;
    constructor(value?: Airport) {
        this.id = value?.id || 0;
        this.name = value?.name || "";
        this.city_code = value?.city_code || "";
        this.slug = value?.slug || "";
        this.iata_code = value?.iata_code || "";
        this.icao_code = value?.icao_code || "";
        this.faa_code = value?.faa_code || "";
        this.un_locode = value?.un_locode || "";
        this.timezone = value?.timezone || "";
        this.lat = value?.lat || 0;
        this.lng = value?.lng || 0;
        this.alt = value?.alt || 0;
        this.city = value?.city || "";
        this.country_code = value?.country_code || "";
        this.departures_intl = value?.departures_intl || 0;
        this.departures_dom = value?.departures_dom || 0;
        this.connections_intl = value?.connections_intl || 0;
        this.connections_dom = value?.connections_dom || 0;
        this.is_major = value?.is_major || 0;
        this.is_international = value?.is_international || 0;
        this.runways = value?.runways || 0;
        this.connections = value?.connections || 0;
        this.departures = value?.departures || 0;
        this.website = value?.website || "";
        this.twitter = value?.twitter || "";
        this.facebook = value?.facebook || "";
        this.instagram = value?.instagram || "";
        this.linkedin = value?.linkedin || "";
        this.youtube = value?.youtube || "";
        this.phone = value?.phone || "";
        this.phone_formatted = value?.phone_formatted || "";
        this.email = value?.email || "";
        this.postal_code = value?.postal_code || "";
        this.email = value?.email || "";
        this.with_schedules = value?.with_schedules || 0;
        this.type = value?.type || "";
        this.fir_code = value?.fir_code || "";
        this.fir_name = value?.fir_name || "";
        this.size = value?.size || "";
        this.popularity = value?.popularity || 0;
        this.names = value?.names || new Names();
        this.cityObject = value?.cityObject || new City();
        this.countryObject = value?.countryObject || new Country();
    }
}


export class AirportServicesTier {
    type: TypeOfSService;
    costForOne: number;
    costForSubsequent: number;
    description: { title: TextElement[], text: TextElement[] }[];
    constructor(value?: AirportServicesTier) {
        this.type = value?.type || TypeOfSService.Arrival;
        this.costForOne = value?.costForOne || 0;
        this.costForSubsequent = value?.costForSubsequent || 0;
    }
}
export class AirportServices {
    title?: Names;
    tiers?: AirportServicesTier[];
    termsAndConditions?: TextElement[][];
    open?: boolean;

    constructor(value?: AirportServices) {
        this.title = value?.title;
        this.tiers = value?.tiers || [];
        this.termsAndConditions = value?.termsAndConditions || [];
        this.open = value?.open || false;
    }
}

export class AirportServicesDto {
    airportId?: number;
    arrivalPriceFirst: number;
    arrivalPriceSubsequent: number;
    departurePriceFirst: number;
    departurePriceSubsequent: number;
    service: ServicesOfAirport | null;
    transitPriceFirst: number;
    transitPriceSubsequent: number;
    constructor(value?: AirportServicesDto) {
        if (value?.airportId) this.airportId = value.airportId;
        this.arrivalPriceFirst = value?.arrivalPriceFirst || 0;
        this.arrivalPriceSubsequent = value?.arrivalPriceSubsequent || 0;
        this.departurePriceFirst = value?.departurePriceFirst || 0;
        this.departurePriceSubsequent = value?.departurePriceSubsequent || 0;
        this.service = value?.service || new ServicesOfAirport();
        this.transitPriceFirst = value?.transitPriceFirst || 0;
        this.transitPriceSubsequent = value?.transitPriceSubsequent || 0;
    }
}

export class AirportInformationDto {
    airportId?: number;
    services: AirportServicesDto[];
    information: string;
    informations: Names;
    constructor(value?: AirportInformationDto) {
        this.services = value?.services || [];
        this.information = value?.information || "";
        this.informations = value?.informations || new Names();
    }
}

export class ServicesOfAirport {
    id?: number;
    name: string;
    description: string;
    names: Names;
    descriptions: Names;
    constructor(value?: ServicesOfAirport) {
        if (value?.id) this.id = value.id;
        this.name = value?.name || "";
        this.description = value?.description || "";
        this.names = value?.names || new Names();
        this.descriptions = value?.descriptions || new Names();
    }
}
