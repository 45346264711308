import {Component, Input, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, OnChanges, OnInit} from '@angular/core';
import {LanguageService} from "../../../services/language.service";
import {AirportService} from "../../../services/airport.service";
import {TextElement} from "../../../model/textElement";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {AirportInformationDto, AirportServicesDto, ServicesOfAirport} from "../../../model/airport";
import {SelectorNamesComponent} from "../../elements/selector-names/selector-names.component";
import {Names} from "../../../model/names";
import {NgIf} from "@angular/common";
import {LangSwitcherComponent} from "../../elements/lang-switcher/lang-switcher.component";
import {ServiceSwitcherComponent} from "../../elements/service-switcher/service-switcher.component";
import {MyMessageService} from "../../../services/my-message.service";

@Component({
  selector: 'app-airport-services',
  standalone: true,
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    TextElementComponent,
    FormsModule,
    SelectorNamesComponent,
    NgIf,
    LangSwitcherComponent,
    ServiceSwitcherComponent
  ],
  templateUrl: './airport-services.component.html',
  styleUrl: './airport-services.component.scss'
})
export class AirportServicesComponent implements OnInit{
  private formDiffer: KeyValueDiffer<string, any>;

  constructor(public airportService: AirportService,
              public languageService: LanguageService,
              private differs: KeyValueDiffers,
              private myMessageService: MyMessageService){}

  @Input() airportId: number;

  lang: string = "";
  selectedService: ServicesOfAirport = new ServicesOfAirport();
  initFlag: boolean = false;

  form: { information: string,
          informations: string,
          serviceName: string,
          names: string,
          description: string,
          descriptions: string,
          arrivalPriceFirst: number,
          arrivalPriceSubsequent: number,
          departurePriceFirst: number,
          departurePriceSubsequent: number,
          transitPriceFirst: number,
          transitPriceSubsequent: number,
  } = {   information: "",
          informations: "",
          serviceName: "",
          names: "",
          description: "",
          descriptions: "",
          arrivalPriceFirst: 0,
          arrivalPriceSubsequent: 0,
          departurePriceFirst: 0,
          departurePriceSubsequent: 0,
          transitPriceFirst: 0,
          transitPriceSubsequent: 0,
  };

  airportInformation: AirportInformationDto = new AirportInformationDto({
    services: [],
    information: "",
    informations: new Names(),
    airportId: undefined,
  });

  ngOnInit() {
    if (this.airportId) {
      this.formDiffer = this.differs.find(this.form).create();
      this.lang = this.languageService.getLanguage().code ?? "en";
      this.airportService.getAirportServices(this.airportId).subscribe(services => {
        if (services) {
          this.airportInformation = services;
        } else {
          const newServices = new AirportInformationDto();
          newServices.airportId = this.airportId;
          this.airportInformation = newServices;
        }
      });
      this.initFlag = true;
    }
  }


  getLang(code: string){
    this.lang = code;
    this.setForm();
  }

  getService(value: ServicesOfAirport) {
    this.selectedService = value;
    this.setForm();
  }

  setForm() {
    this.form.information = this.airportInformation.information;
    this.form.informations = this.getStringFromName(this.lang, this.airportInformation.informations);
    const service: ServicesOfAirport = this.airportInformation.services.find(v =>
        v.service?.id == this.selectedService.id)?.service ?? new ServicesOfAirport();
    this.form.serviceName = service.name ?? "";
    this.form.names = this.getStringFromName(this.lang, service.names);
    this.form.description = service.description;
    this.form.descriptions = this.getStringFromName(this.lang, service.descriptions);
    this.form.arrivalPriceFirst = this.getValueByService("arrivalPriceFirst");
    this.form.arrivalPriceSubsequent = this.getValueByService("arrivalPriceSubsequent");
    this.form.departurePriceFirst = this.getValueByService("departurePriceFirst");
    this.form.departurePriceSubsequent = this.getValueByService("departurePriceSubsequent");
    this.form.transitPriceFirst = this.getValueByService("transitPriceFirst");
    this.form.transitPriceSubsequent = this.getValueByService("transitPriceSubsequent");
  }

  setData(key: string, value: any) {
    switch (key) {
      case "information" :
      case "serviceName" :
      case "description" : {
        this.airportInformation[key] = value;
       break;
      }
      case "informations" : {
        this.airportInformation[key][this.lang] = value;
        break;
      }
      case "names" :
      case "descriptions" : {
        const service = this.airportInformation.services.find(v => v.service?.id == this.selectedService.id);
        if (service) {
          const innerService = service.service;
          if (innerService) {
            innerService[key][this.lang] = value;
          } else {
            const newService: ServicesOfAirport = new ServicesOfAirport();
            newService[key][this.lang] = value;
            service.service = newService;
          }
        } else {
          const newService: AirportServicesDto = new AirportServicesDto();
          newService.airportId = this.airportId;
          newService.service = this.selectedService;
          newService.service[key][this.lang] = value;
          this.airportInformation.services.push(newService);
        }
        break;
      }
      case "arrivalPriceFirst":
      case "arrivalPriceSubsequent":
      case "departurePriceFirst":
      case "departurePriceSubsequent":
      case "transitPriceFirst":
      case "transitPriceSubsequent": {
        const service = this.airportInformation.services.find(v => v.service?.id == this.selectedService.id);
        if (service) {
          service[key] = value;
        } else {
          const newService: AirportServicesDto = new AirportServicesDto();
          newService.airportId = this.airportId;
          newService.service = this.selectedService;
          newService.service[key] = value;
          this.airportInformation.services.push(newService);
        }
        break;
      }
    }
  }

  getStringFromName(lang: string, names?: Names) {
    if (names) {
      const key = Object.keys(names).find((v: string) => v === lang);
      if (key) {
        return names[key];
      } return ""
    } return ""
  }

  getValueByService(key: string) {
    const service = this.airportInformation.services.find(v => v.service?.id == this.selectedService.id);
    if (service) {
      return service[key];
    }
  }

  formChanged(changes: KeyValueChanges<string, any>) {
      changes.forEachChangedItem((record) => {
        this.setData(record.key,  record.currentValue);
      });
  }

  ngDoCheck(): void {
    const changes =  this.formDiffer?.diff(this.form);
    if (changes) {
      this.formChanged(changes);
    }
  }


  saveServices() {
     if (this.initFlag && this.airportId > 0) {
      this.airportService.postAirportServices(this.airportInformation).subscribe({
        next: () => {
          this.myMessageService.addMessage(this.textElements[5], "success");
        },
        error: (error) => {
          this.myMessageService.addMessage(this.textElements[6], "error");
        }
      });
   }
  }


  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Service",
      },
      {
        languageId: 1,
        text: "Сервис",
      },
    ],
    [
      {
        languageId: 0,
        text: "Language",
      },
      {
        languageId: 1,
        text: "Язык",
      },
    ],
    [
      {
        languageId: 0,
        text: "Information(default)",
      },
      {
        languageId: 1,
        text: "Информация(по умолчанию)",
      },
    ],
    [
      {
        languageId: 0,
        text: "Information(in languages)",
      },
      {
        languageId: 1,
        text: "Информация(на языках)",
      },
    ],
    [
      {
        languageId: 0,
        text: "Service name(default)",
      },
      {
        languageId: 1,
        text: "Название услуги(по умолчанию)",
      },
    ],
    [
      {
        languageId: 0,
        text: "Service name(in languages)",
      },
      {
        languageId: 1,
        text: "Название услуги(на языках)",
      },
    ],
    [
      {
        languageId: 0,
        text: "Service description(default)",
      },
      {
        languageId: 1,
        text: "Описание услуги(по умолчанию)",
      },
    ],
    [
      {
        languageId: 0,
        text: "Service description(in languages)",
      },
      {
        languageId: 1,
        text: "Описание услуги(на языках)",
      },
    ],
    [
      {
        languageId: 0,
        text: "Arrival, 1st passenger",
      },
      {
        languageId: 1,
        text: "Прибытие, 1-й пассажир",
      },
    ],
    [
      {
        languageId: 0,
        text: "Arrival, each subsequent passenger",
      },
      {
        languageId: 1,
        text: "Прибытие, каждый последующий пассажир",
      },
    ],
    [
      {
        languageId: 0,
        text: "Departure 1st passenger",
      },
      {
        languageId: 1,
        text: "Отправление, 1-й пассажир",
      },
    ],
    [
      {
        languageId: 0,
        text: "Departure Each subsequent passenger",
      },
      {
        languageId: 1,
        text: "Отправление, каждый последующий пассажир",
      },
    ],
    [
      {
        languageId: 0,
        text: "Transit 1st passenger",
      },
      {
        languageId: 1,
        text: "Транзит, 1-й пассажир",
      },
    ],
    [
      {
        languageId: 0,
        text: "Transit Each subsequent passenger",
      },
      {
        languageId: 1,
        text: "Транзит, каждый последующий пассажир",
      },
    ],
    [
      {
        languageId: 0,
        text: "This entry has been successfully saved",
      },
      {
        languageId: 1,
        text: "Запись успешно сохранена",
      },
    ],
    [
      {
        languageId: 0,
        text: "Error, something went wrong",
      },
      {
        languageId: 1,
        text: "Ошибка, что-то пошло не так",
      },
    ],
  ];
}
