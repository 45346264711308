import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from "../../elements/button/button.component";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {OfferComponent} from "../../elements/offer/offer.component";
import {Offer} from "../../../model/offer";

@Component({
  selector: 'app-our-packages',
  standalone: true,
    imports: [CommonModule, ButtonComponent, TextElementComponent, OfferComponent],
  templateUrl: './our-packages.component.html',
  styleUrl: './our-packages.component.scss'
})
export class OurPackagesComponent {
  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "OUR PACKAGES",
      },
      {
        languageId: 1,
        text: "ПРОМО",
      },
    ],
    [
      {
        languageId: 0,
        text: "We Have Best Luxury Packages For You",
      },
      {
        languageId: 1,
        text: "Специальные предложения",
      },
    ],
    [
      {
        languageId: 0,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum, lacus non faucibus congue, lectus quam viverra nulla.",
      },
      {
        languageId: 1,
        text: "Наша компания с удовольствием дарит Вам скидку на бронирования для групп. ",
      },
    ],
    [
      {
        languageId: 0,
        text: "VIEW PACKAGES",
      },
      {
        languageId: 1,
        text: "СМОТРЕТЬ",
      },
    ],
    [
      {
        languageId: 0,
        text: "Marhaba Gold Service Dubai",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Book now (arrival and departure) for family with discount 10%",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Offer valid until 30/11/2023"
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Personal assistant"
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Fast Track"
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Marhaba Lounge"
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "CIP Service Male, Maldives"
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Meeting at the aircraft"
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "VIP lounge or VIP Terminal"
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "BOOK NOW"
      },
      {
        languageId: 1,
        text: "ЗАБРОНИРУЙТЕ СЕЙЧАС",
      },
    ],
  ];

  offers: Offer[] = [
    {
      title: this.textElements[4],
      text: this.textElements[5],
      until: this.textElements[6],
      list: [this.textElements[7], this.textElements[8], this.textElements[9]],
      back: "/assets/offer1.jpg",
      button: this.textElements[13],
    },
    {
      title: this.textElements[9],
      text: this.textElements[5],
      until: this.textElements[6],
      list: [this.textElements[10], this.textElements[11], this.textElements[12]],
      back: "/assets/offer2.jpg",
      button: this.textElements[13],
    },
  ]
}
