<header>
    <div class="header">
        <div class="container">
            <div class="header-content">
                <div class="col-8 col-md-4">
                    <div class="logo" routerLink="/">
                        <app-icon [icon]="IconsName.Logo" [width]="30" [height]="30"/>
                        MEET PLUS GREET
                    </div>
                </div>
                <div class="col">
                    <app-menu/>
                </div>
                <div class="col-1 d-none d-md-block">
                    <app-switcher/>
                </div>
                <div class="col-2 d-none d-md-block">
                    <div class="justify-content-end">
                        <app-button
                                [buttonTitle]="buttonTitle"
                                [buttonClass]="'button-style-1 button-size-l'"
                                [buttonJustifyContent]="'flex-end'"
                                (click)="bookingService.setBookingForm(true)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-notification/>
</header>

