import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Country} from "../model/country";
import {City} from "../model/city";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private httpClient: HttpClient) {
  }

  getCountriesList(): Observable<Country[]>  {
    return this.httpClient.get<Country[]>(`${environment.apiUrl}/public/country`);
  }
  getCitiesList(): Observable<City[]>  {
    return this.httpClient.get<City[]>(`${environment.apiUrl}/public/city`);
  }
}
