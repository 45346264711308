import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";

@Component({
  selector: 'app-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss'
})
export class SectionComponent {
  @Input() sectionClass: string = "";
  @Input() sectionHeight: string = "";
  @Input() sectionContainer: boolean = true;

}
