<div class="our-packages-container">
    <div class="col-12 col-md-5">
        <div class="our-packages-text-container">
            <h1 class="colorYellow subtitle"><app-text-element [inputText]="textElements[0]"/></h1>
            <h1 class="largeText"><app-text-element [inputText]="textElements[1]"/></h1>
            <p><app-text-element [inputText]="textElements[2]"/></p>
            <app-button
                    [buttonTitle]="textElements[3]"
                    [buttonClass]="'button-style-2 button-size-l'"
                    [buttonJustifyContent]="'start'"
            />
        </div>
    </div>
    <div class="col-12 col-md-7">
        <div class="our-packages-offer-container">
            <div *ngFor="let offer of offers" class="our-packages-offer">
                <app-offer
                        class="all-area"
                        [url]="offer.back"
                        [title]="offer.title"
                        [text]="offer.text"
                        [until]="offer.until"
                        [list]="offer.list"
                        [button]="offer.button"
                />
            </div>
        </div>
    </div>
</div>
