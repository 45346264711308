import {Component, Input, OnInit,} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SvgComponent} from "../svg/svg.component";
import {Icon, Icons} from "../../../model/icons";

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent{
  @Input() width: number  = 100;
  @Input() height: number = 100;
  @Input() strokeWidth: number = 0;
  @Input() color: string = '';
  @Input() icon: Icon = Icons[0];
}

