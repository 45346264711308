import {TextElement} from "./textElement";

export class Author {
  name?: TextElement[];
  position?: TextElement[];
  imgUrl?: string;
  constructor(value: Author) {
    this.name = value.name || [];
    this.position = value.position || [];
    this.imgUrl = value.imgUrl || "";
  }
}

export class Quote {
  text: TextElement[];
  author: Author;

  constructor(value: Quote) {
  this.text = value.text || [];
  this.author = value.author;
  }
}
