import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {LanguageService} from "../../../services/language.service";
import {SearchAirportsService} from "../../../services/search.airports.service";
import {Airport} from "../../../model/airport";
import {FormsModule} from "@angular/forms";
import {IconComponent} from "../../elements/icon/icon.component";
import {IconsName} from "../../../model/icons";
import { RouterLink} from "@angular/router";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {CountryFlagComponent} from "../../elements/country-flag/country-flag.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";


@Component({
  selector: 'app-airports-search',
  standalone: true,
  imports: [CommonModule, FormsModule, IconComponent, RouterLink, TextElementComponent, CountryFlagComponent, ProgressSpinnerModule],
  templateUrl: './airports-search.component.html',
  styleUrl: './airports-search.component.scss'
})
export class AirportsSearchComponent{
  constructor(
      protected languageService: LanguageService,
      protected searchAirportsService: SearchAirportsService) {

  }
  notFoundFlag: boolean = false;
  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "Enter city or airport",
      },
      {
        languageId: 1,
        text: "Введите название города или аэропорта",
      },
    ],
    [
      {
        languageId: 0,
        text: "Where are VIP service available?",
      },
      {
        languageId: 1,
        text: "VIP Сервис в Аэропортах мира",
      },
    ],
    [
      {
        languageId: 0,
        text: "We provide the services at more than 600 airports across the world",
      },
      {
        languageId: 1,
        text: "Мы предоставляли услуги в более чем 600 аэропортах мира",
      },
    ],
    [
      {
        languageId: 0,
        text: "NOTHING FOUND",
      },
      {
        languageId: 1,
        text: "Ничего не найдено",
      },
    ],
  ];
  airports: Airport[] = [];
  search: string = "";
  loading: boolean = false;
  requests: {time: number, airports: Airport[]}[] = [];


  searchFoo(event?: KeyboardEvent){
    if (this.search.length > 2) {
      this.getList().then(r => r);
    } else {
      this.notFoundFlag = false;
      this.airports = [];
      this.requests = [];
      this.loading = false;
    }
  }
  async getList(){
    this.loading = true;
    const time = new Date().getTime();
    this.searchAirportsService.getAirportList(this.search).subscribe(airports => {
      setTimeout(() => {
        if (airports.length > 0) {
          this.notFoundFlag = false;
          this.requests.push({time, airports});
          this.loading = false;
        } else {
          this.requests.push({time, airports});
          this.notFoundFlag = true;
          this.loading = false;
        }
        this.airports = this.requests.find(r =>
            r.time == Math.max(...this.requests.map(v => v.time)))?.airports ?? [];
      }, 0);
    });
  }

  openAirport(id: number){

  }

  clearList() {
    this.notFoundFlag = false;
    this.airports = [];
    this.search = "";
  }


  protected readonly IconsName = IconsName;
}
