import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn} from '@angular/common/http';
import { inject } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import {TokenStorageService} from "../services/token.storage.service";

const TOKEN_HEADER_KEY = 'Authorization';


export const authInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const tokenStorageService = inject(TokenStorageService);
  const token = tokenStorageService.getToken();
  if (token) {
    const cloned = req.clone(
        { headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) }
    );
    return next(cloned);
  } else {
    return next(req);
  }
};




