export const languagesCountryMap = new Map<string, string>([
    ["ak", "gh"],
    ["ce", "ru"],
    ["cs", "cz"],
    ["da", "dk"],
    ["el", "gr"],
    ["en", "uk"],
    ["eu", "es"],
    ["fa", "ir"],
    ["fa", "ir"],
    ["fa", "ir"],
    ["ff", "ng"],
    ["fy", "nl"],
    ["ha", "ng"],
    ["he", "il"],
    ["hi", "in"],
    ["hy", "am"],
    ["ig", "ng"],
    ["ja", "jp"],
    ["jv", "id"],
    ["ka", "ge"],
    ["kk", "kz"],
    ["kl", "gl"],
    ["ko", "kr"],
    ["ks", "pk"],
    ["ku", "tr"],
    ["lg", "ug"],
    ["ln", "cg"],
    ["lo", "la"],
    ["nb", "no"],
    ["nd", "zw"],
    ["nn", "no"],
    ["oc", "fr"],
    ["or", "in"],
    ["ps", "af"],
    ["qu", "bo"],
    ["rm", "it"],
    ["rm", "it"],
    ["rm", "it"],
    ["rn", "bi"],
    ["si", "lk"],
    ["sq", "al"],
    ["sw", "tz"],
    ["ta", "in"],
    ["te", "in"],
    ["ti", "et"],
    ["uk", "ua"],
    ["ur", "pk"],
    ["wo", "sl"],
    ["yi", "il"],
    ["yo", "ng"],
    ["zh", "cn"],
    ["zu", "za"],
]);
