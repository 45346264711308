import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../text-element/text-element.component";

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, TextElementComponent],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() buttonTitle: TextElement[] = [];
  @Input() buttonClass: string = "";
  @Input() buttonJustifyContent: string = "center";
}
