export class Login {
  login: string;
  password: string;
  token: string | undefined;
  constructor(value: Login) {
    this.login = value.login || "";
    this.password = value.password || "";
    this.token = value.token || "";
  }
}

