import {Icon, Icons, IconsName} from "./icons";

export class SocialMedia {
  id?: number;
  link?: string;
  icon?: Icon;
  name?: string;
}


export const socialMediaList: SocialMedia[] = [
  {
    id: 0,
    icon: IconsName.Facebook,
    link: "#",
    name: "facebook",
  },
  {
    id: 1,
    icon: IconsName.Instagram,
    link: "#",
    name: "instagram",
  },
  {
    id: 2,
    icon: IconsName.Telegram,
    link: "#",
    name: "telegram",
  },
  {
    id: 3,
    icon: IconsName.Whatsapp,
    link: "#",
    name: "Whatsapp",
  },
  {
    id: 4,
    icon: IconsName.Viber,
    link: "#",
    name: "Viber",
  },
];
export const socialMediaName =  {
  Default:  socialMediaList[0],
  Facebook: socialMediaList[0],
  Instagram: socialMediaList[1],
  Telegram: socialMediaList[2],
  Whatsapp: socialMediaList[3],
}


