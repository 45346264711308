import { Component } from '@angular/core';
import {SectionComponent} from "../../elements/section/section.component";
import {BackgroundBlockComponent} from "../../elements/background-block/background-block.component";
import {SocialMediaComponent} from "../../elements/social-media/social-media.component";
import {TextElement} from "../../../model/textElement";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {ButtonComponent} from "../../elements/button/button.component";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [
    SectionComponent,
    BackgroundBlockComponent,
    SocialMediaComponent,
    TextElementComponent,
    ButtonComponent,
    RouterLink
  ],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent {

  textElements: TextElement[][] = [
    [
      {
        languageId: 0,
        text: "404",
      },
      {
        languageId: 1,
        text: "404",
      },
    ],
    [
      {
        languageId: 0,
        text: "We Could't Find The Page You Are Looking For",
      },
      {
        languageId: 1,
        text: "Мы не смогли найти страницу, которую вы ищете",
      },
    ],
    [
      {
        languageId: 0,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
      },
      {
        languageId: 1,
        text: "",
      },
    ],
    [
      {
        languageId: 0,
        text: "Back to home",
      },
      {
        languageId: 1,
        text: "Вернуться домой",
      },
    ],
  ];
}
