import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Contact} from "../../../model/contact";
import {IconsName} from "../../../model/icons";
import {TextElementComponent} from "../../elements/text-element/text-element.component";
import {IconComponent} from "../../elements/icon/icon.component";

@Component({
  selector: 'app-contact-info',
  standalone: true,
  imports: [CommonModule, TextElementComponent, IconComponent],
  templateUrl: './contact-info.component.html',
  styleUrl: './contact-info.component.scss'
})
export class ContactInfoComponent {

  contacts: Contact[] = [
    {
      title: [
        {
          languageId: 0,
          text: "Phone Number",
        },
        {
          languageId: 1,
          text: "Телефон",
        },
      ],
      text: [
        {
          languageId: 0,
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        },
        {
          languageId: 1,
          text: "",
        },
      ],
      icon: IconsName.Phone,
      linkText: [
        {
          languageId: 0,
          text: "123-234-1234",
        },
        {
          languageId: 1,
          text: "",
        },
      ],
      href: '#'
    },
    {
      title: [
        {
          languageId: 0,
          text: "Email Address",
        },
        {
          languageId: 1,
          text: "Email адрес",
        },
      ],
      text: [
        {
          languageId: 0,
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        },
        {
          languageId: 1,
          text: "",
        },
      ],
      icon: IconsName.Envelope,
      linkText: [
        {
          languageId: 0,
          text: "hello@awesomesite.com",
        },
        {
          languageId: 1,
          text: "",
        },
      ],
      href: '#'
    },
    // {
    //   title: [
    //     {
    //       languageId: 0,
    //       text: "Address",
    //     },
    //     {
    //       languageId: 1,
    //       text: "Адрес",
    //     },
    //   ],
    //   text: [
    //     {
    //       languageId: 0,
    //       text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    //     },
    //     {
    //       languageId: 1,
    //       text: "",
    //     },
    //   ],
    //   icon: IconsName.Location,
    //   linkText: [
    //     {
    //       languageId: 0,
    //       text: "99 Roving St., Big City, PKU 23456",
    //     },
    //     {
    //       languageId: 1,
    //       text: "",
    //     },
    //   ],
    //   href: '#'
    // },
  ]

  protected readonly IconsName = IconsName;
}
