import {Injectable, Type} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../model/table";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    constructor(private httpClient: HttpClient) {
    }

    getContent<Type>(url: string, page: number = 0, size: number = 10, search: string = "", sort: string = ''): Observable<Page<Type>> {
        return this.httpClient.get<Page<Type>>(
            `${environment.apiUrl}/${url}?page=${page}&size=${size}&search=${search}&sort=${sort}`);
    }

    saveItem(url: string, item: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/${url}`, item);
    }

}
