import {Injectable} from "@angular/core";
import {TextElement} from "./textElement";
import {Names} from "./names";

@Injectable()
export class Language {
  id: number;
  code?: string;
  name: TextElement[];

  constructor(value: Language) {
    this.id = value.id || 0;
    this.code = value.code || "";
    this.name = value.name || [];
  }
}


export class LanguageDto {
  code639_1: string;
  code639_3: string;
  code639_2B: string;
  code639_2T: string;
  nativeName: string;
  englishName: string;
  names: Names;

  constructor(value: LanguageDto) {
    this.code639_1 = value?.code639_1 || "";
    this.code639_3 = value?.code639_3 || "";
    this.code639_2B = value?.code639_2B || "";
    this.code639_2T = value?.code639_2T || "";
    this.nativeName = value?.nativeName || "";
    this.englishName = value?.englishName || "";
  }
}

export class LanguageEdit {
  code: string;
  codeCountry: string;
  name: TextElement[];
}


