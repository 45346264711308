<div class="login-container">
    <div class="login-window">
        <form class="login-form">
            <div class="login-form-item">
                <h2 class="colorGray"><app-text-element [inputText]="textElements[0]"/></h2>
            </div>
            <div class="login-form-item">
                <input pInputText
                       id="login"
                       name="login"
                       [(ngModel)]="form.login"
                       [placeholder]="languageService.getStringFromTextElement(textElements[1])"
                       pAutoFocus
                       [autofocus]="true"
                />
                <p *ngIf="errorControl('login')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[4]"/>
                </p>
            </div>
            <div class="login-form-item">
                <p-password id="password"
                            name="password"
                            [(ngModel)]="form.password"
                            [placeholder]="languageService.getStringFromTextElement(textElements[2])"
                            [feedback]="false"
                />
                <p *ngIf="errorControl('password')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[4]"/>
                </p>
            </div>
            <div class="login-form-item">
                <p *ngIf="false"><app-text-element [inputText]="textElements[5]"/></p>
                <p *ngIf="false"><app-text-element [inputText]="textElements[6]"/></p>
            </div>
            <div class="login-form-item login-form-item-button">
                <app-button
                        [buttonTitle]="textElements[3]"
                        [buttonClass]="'button-style-1 button-size-l'"
                        [buttonJustifyContent]="'end'"
                        (click)="submit()"
                />
                <p *ngIf="errorControl('email')" class="p-error mb-0">
                    <app-text-element [inputText]="textElements[15]"/>
                </p>
            </div>
        </form>
    </div>
</div>
