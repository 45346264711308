<div class="lang-switcher-container">
    <p-dropdown [options]="filteredService"
                [(ngModel)]="service"
                [filter]="true"
                tooltipPosition="left"
                optionLabel="code"
                appendTo="body"
                id="code"
                name="code"
    >
        <ng-template pTemplate="filter" let-options>
            <div class="filter-container">
                <input type="text"
                       class="search-field"
                       name="newSearch"
                       [placeholder]="languageService.getStringFromTextElement(textElements[0])"
                       [(ngModel)]="search"
                       (keyup)="searchFoo($event)"
                />
                <app-icon [icon]="IconsName.Cross" [width]="24" [height]="24" (click)="clearSearch()"/>
            </div>
        </ng-template>
        <ng-template pTemplate="selectedItem">
            <div *ngIf="service" class="selected-container">
                {{ service.name }}
            </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
            <div class="item-container" (click)="select(item)">
                {{ item.name }}
            </div>
        </ng-template>
    </p-dropdown>
</div>
