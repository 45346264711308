import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextElement} from "../../../model/textElement";
import {SocialMedia, socialMediaList} from "../../../model/socialMedia";
import {IconComponent} from "../icon/icon.component";
import {Icons, IconsName} from "../../../model/icons";

@Component({
  selector: 'app-social-media',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './social-media.component.html',
  styleUrl: './social-media.component.scss'
})
export class SocialMediaComponent {
  @Input() vertical: boolean = true;
  @Input() buttonJustifyContent: string = "";

  socialMediaList: SocialMedia[] = socialMediaList;
  protected readonly Icons = Icons;
}
