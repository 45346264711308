import {Names} from "./names";

export class Country {
    code: string;
    code3: string;
    name: string;
    population: number | null;
    continent: string;
    currency: string;
    names: Names;
    constructor(value?: Country) {
        this.code = value?.code ?? "";
        this.code3 = value?.code3 ?? "";
        this.name = value?.name ?? "";
        this.population = value?.population ?? 0;
        this.continent = value?.continent ?? "";
        this.currency = value?.currency ?? "";
        this.names =  value?.names ?? new Names();
    }
}
